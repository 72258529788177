import { FC } from 'react';
import styles from './ChatButton.module.css';
import { Button } from '@mui/material';
import typoStyles from '../../../../Styles/component/Typography.module.css';

interface ChatButtonProps {
  label: string,
  handleClick: any,
  disable:boolean
}

const ChatButton: FC<ChatButtonProps> = ({ label, handleClick, disable }) => (
  <div style={{ bottom: 0, position: "absolute", width: "100%" }}>
    <Button disabled={disable} fullWidth className={styles.chatContinueDiv} onClick={handleClick}>
      <span className={typoStyles.typoRobotoMediumprimary21833}>{label}</span>
    </Button>
  </div>
);

export default ChatButton;
