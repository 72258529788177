import Pdf from '../../Assests/Icons/PDF.svg';
import Audio from '../../Assests/Icons/Audio icon (1).svg';
import Docx from '../../Assests/Icons/DOCX icon (1).svg';
import Doc from '../../Assests/Icons/Doc icon (1).svg';
import Csv from '../../Assests/Icons/CSV Icon (1).svg';

import image from '../../Assests/Icons/Icons svg/image.png';
import video from '../../Assests/Icons/Icons svg/video.png';
import ImageFile from '../../Assests/Icons/Icons svg/Group 17161.svg';
import VideoFile from '../../Assests/Icons/Icons svg/Group 17163.svg';
import DocFile from '../../Assests/Icons/Icons svg/Group 17165.svg';
import AudioIcon from '../../Assests/Icons/Audio icon.svg';

import unreadImageFile from '../../Assests/Icons/Icons svg/Group 17784.svg';
import unreadVideoFile from '../../Assests/Icons/Icons svg/Group 17783.svg';
import unreadDocFile from '../../Assests/Icons/Icons svg/Group 17782.svg';
import unreadAudioIcon from '../../Assests/Icons/Icons svg/unreadAudio icon.svg';

import { fileSizeErrMsg, fileTypeErrMsg, singleFileErrMsg } from './constant';

export const generateVideoThumbnail = (file: File) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);
    video.onloadeddata = () => {
      const ctx: any = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      canvas.toBlob((blob) => {
        if (blob) {
          const fileName = file?.['name'].split('.');
          const thumbnailFile = new File([blob], `thumb_${fileName[0]}.jpeg`);
          resolve(thumbnailFile);
        }
      }, 'image/jpeg');
    };
  });
};

export const generateImageThumbnail = (file: File, flag: any) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const context: any = canvas.getContext('2d');
      const mult = flag ? 0.5 : 0.2;
      canvas.width = img.width * mult;
      canvas.height = img.height * mult;
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (blob) {
          const fileName = file?.['name'].split('.');
          const thumbnailFile = new File([blob], `thumb_${fileName[0]}.jpeg`);
          resolve(thumbnailFile);
        }
      }, 'image/jpeg');
    }
  });
};
export const getBase64 = (file: any) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export const allowedFileFormat = [
  "application/pdf",
  "video/mp4",
  "audio/mp3",
  "audio/aac",
  "audio/x-m4a",
  "audio/mpeg",
  "text/csv",
  // "video/mov",
  // "video/quicktime",
  "image/jpeg",
  "image/jpg",
  "application/csv",
  // "application/msword",
  "image/png",
  // "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "audio/vnd.dlna.adts"
];
export const attachMentErrmsg = (key: number | null) => {
  if (key === 2) {
    return singleFileErrMsg;
  } else if (key === 3) {
    return fileTypeErrMsg;
  } else {
    return fileSizeErrMsg;
  }
}
export const images = [
  'jpg',
  'jpeg',
  'png',
  'JPG',
  'mp4',
]

export const audioFile = [
  'mp3',
  'm4a',
  'aac',
  'mpeg'
]
export const renderImg = (type: any) => {

  switch (type) {
    case 'jpg':
      return image;
    case 'jpeg':
      return image;
    case 'JPG':
      return image;
    case 'png':
      return image;
    case 'pdf':
      return Pdf;
    case 'm4a':
      return Audio;
    case 'mp3':
      return Audio;
    case 'aac':
      return Audio;
    case 'x-m4a':
      return Audio;
    case 'mpeg':
      return Audio;
    case 'vnd.dlna.adts':
      return Audio;
    case 'mp4':
      return video;
    case 'docx':
      return Docx;
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      return Docx;
    case 'doc':
      return Doc;
    case 'csv':
      return Csv;
    default:
      break;
  }
}
export const renderimage = (type: any) => {
  switch (type) {
    case 'jpg':
      return ImageFile;
    case 'jpeg':
      return ImageFile;
    case 'JPG':
      return ImageFile;
    case 'png':
      return ImageFile;
    case 'pdf':
      return DocFile;
    case 'm4a':
      return AudioIcon;
    case 'mp3':
      return AudioIcon;
    case 'aac':
      return AudioIcon;
    case 'mp4':
      return VideoFile;
    case 'docx':
      return DocFile;
    case 'doc':
      return DocFile;
    case 'csv':
      return DocFile;
    default:
      break;
  }
}

export const renderunreadimage = (type: any) => {
  switch (type) {
    case 'jpg':
      return unreadImageFile;
    case 'jpeg':
      return unreadImageFile;
    case 'JPG':
      return unreadImageFile;
    case 'png':
      return unreadImageFile;
    case 'pdf':
      return unreadDocFile;
    case 'm4a':
      return unreadAudioIcon;
    case 'mp3':
      return unreadAudioIcon;
    case 'aac':
      return unreadAudioIcon;
    case 'mp4':
      return unreadVideoFile;
    case 'docx':
      return unreadDocFile;
    case 'doc':
      return unreadDocFile;
    case 'csv':
      return unreadDocFile;
    default:
      break;
  }
}


export const typeFileCheck = (type: any) => {
  switch (type) {
    case 'jpg':
      return 'Image';
    case 'jpeg':
      return 'Image';
    case 'JPG':
      return 'Image';
    case 'png':
      return 'Image';
    case 'pdf':
      return 'File';
    case 'm4a':
      return 'Audio';
    case 'aac':
      return 'Audio';
    case 'mp3':
      return 'Audio';
    case 'mp4':
      return 'Video';
    case 'docx':
      return 'File';
    case 'doc':
      return 'File';
    case 'csv':
      return 'File';
    default:
      break;
  }
}
export const fileNametag = (name: string, type: string) => {
  const Charleng = (type === 'm4a' || type === 'mp3' || type === 'mp4' || type === 'aac') ? 20 : 25;
  const nameFlag = name.split('.')[0]?.length > Charleng;
  return nameFlag ? `${name.slice(0, Charleng)}...${name.split('.')[name.split('.').length - 1]}` : `${name}`
}
export const fileNameSectag = (name: string, type: string) => {
  const Charleng = (type === 'm4a' || type === 'mp3' || type === 'mp4' || type === 'aac') ? 20 : 23;
  const nameFlag = name.split('.')[0]?.length > Charleng;
  return nameFlag ? `${name.slice(0, Charleng)}...${name.split('.')[name.split('.').length - 1]}` : `${name}`
}
export const typeCheck = (type: string) => {
  return audioFile.includes(type) ? true : false;
}
export const typeimageCheck = (type: string) => {
  return images.includes(type) ? true : false;
}

export const imagetypes = [
  'jpg',
  'jpeg',
  'png',
  'JPG',

]

export const imagetypeCheck = (type: string) => {
  return imagetypes.includes(type) ? true : false;
}

export const attachments = [
  'jpg',
  'jpeg',
  'png',
  'JPG',
  'mp4',
  'mp3',
  'm4a',
  'aac',
  'mpeg',
  'pdf',
  'csv',
  'docx',
  'doc',
]


export const attachmentTypes = (type: string) => {
  return attachments.includes(type) ? true : false;
}