import React, { useState } from 'react'
import TATMeterAuditor from './TATMeterAuditor'
import { IconButton, Switch } from '@mui/material'
import TATMeterProvider from './TATMeterProvider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'

function TATMeterList() {
    const navigate = useNavigate()
    const [auditorSwitch, setAuditorSwitch] = useState(true)
    return (
        <div style={{ margin: '5px 5px 20px 5px' }}>
            <div style={{ paddingLeft: 5 }}>
                <IconButton
                    sx={{ marginRight: 5 }}
                    aria-label="back a page"
                    onClick={() => navigate(-1)}
                >
                    <ArrowBackIcon />
                </IconButton>
                Auditors{' '}
                <Switch
                    checked={!auditorSwitch}
                    onChange={() => setAuditorSwitch(!auditorSwitch)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />{' '}
                Providers
            </div>
            <div style={{ paddingLeft: 0, display: 'flex', gap: 5 }}>
                {auditorSwitch ? <TATMeterAuditor /> : <TATMeterProvider />}
            </div>
        </div>
    )
}

export default TATMeterList
