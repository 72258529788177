export const paramsObj: any = {
    pageSize: 10,
    pageNumber: 1,
}
export const pageParams = {
    count: 10,
    currentPage: 1,
    totalPages: 1,
    totalRecords: 11,
    rowsPerPage: [10, 20, 30],
}
export const taskListPageParams = {
    count: 10,
    currentPage: 0,
    totalRecords: 10,
}
export const paginationParams = {
    count: 30,
    currentPage: 1,
    totalPages: 1,
    totalRecords: 11,
    rowsPerPage: [30, 45, 50],
}
export const taskParamsObj: any = {
    pageSize: 5,
    pageNumber: 1,
}
export const paginationList = {
    count: 10,
    currentPage: 1,
}
