import { FC } from 'react'
import styles from './TaskdrawerHeader.module.css'
import typoStyles from '../../Styles/component/Typography.module.css'
import { Button, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import { ReactComponent as Slideback } from '../../Assests/Icons/Slide back.svg'

const CustomIconButton = styled(Button)({
    height: 26,
    minWidth: 26,
    '&:hover': {
        fill: '#427BE0',
    },
})
interface TaskdrawerHeaderProps {
    onclick: any
    title?: string
}

const TaskdrawerHeader: FC<TaskdrawerHeaderProps> = ({ onclick, title }) => (
    <div className={styles.TaskHeader}>
        <div className={styles.columnStyle}>
            <span className={typoStyles.typoRobotoMediumprimary1214}>
                {'Job'}
            </span>
            {title && (
                <span className={typoStyles.typoRobotoRegoular14}>{title}</span>
            )}
        </div>
        <div>
            <Tooltip title="Close Window">
                <CustomIconButton
                    size="small"
                    onClick={() => {
                        onclick()
                    }}
                >
                    <Slideback />
                </CustomIconButton>
            </Tooltip>
        </div>
    </div>
)

export default TaskdrawerHeader
