import moment from 'moment';
import API from '../api/index';

export const getUserdata = (data: any) => API.handleApiRequest(() => API.post('/message/getDetails', {taskId: data}));
export const getUsersdata = (data: any) => API.handleApiRequest(() => API.post('/message/getTaskUsers', { taskId: data }));
export const getChat = (data: any) => API.handleApiRequest(() => API.post(`/message/getMessage`, data));
export const addMsg = (data: any) => API.handleApiRequest(() => API.formPost(`/message`, data));
export const getlastChat = (data: any) => API.handleApiRequest(() => API.post(`/message/all`, data));
export const getSubjectdata = () => API.handleApiRequest(() => API.get('/subject?type=1'));
export const addSubjectdata = (data: any) => API.handleApiRequest(() => API.post('/subject',data));
export const removeSubjectdata = (data: any) => API.handleApiRequest(() => API.put('/subject',data));
export const uploadFile = async (body: any, setProgress: any, signal: any) => {
    return API.formPostAttachment(`/message`, body, setProgress, signal).then(res => {
        return res;
    }).catch(err => {
        return err;
    });
}
export const timerChstGet = (dateTime: any) => {
    if (moment().format('YYYY-DD-MM') === moment(dateTime).format('YYYY-DD-MM')) {
        return 'Today';
    } else if (moment().subtract(1, 'day').format('YYYY-DD-MM') === moment(dateTime).format('YYYY-DD-MM')) {
        return 'Yesterday';
    } else if (moment(dateTime).week() === moment().week()) {
        return moment(dateTime).format('dddd');
    } else {
        return moment(dateTime).format("LL")
    }
}
export const timerGet = (dateTime: any) => {
    if (moment().format('YYYY-DD-MM') === moment(dateTime).format('YYYY-DD-MM')) {
        return moment(dateTime).format('LT');
    } else if (moment().subtract(1, 'day').format('YYYY-DD-MM') === moment(dateTime).format('YYYY-DD-MM')) {
        return 'Yesterday';
    } else if (moment(dateTime).week() === moment().week()) {
        return moment(dateTime).format('dddd');
    } else {
        return moment(dateTime).format('L');
    }
}