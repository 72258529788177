import { FC } from 'react';
import styles from './ViewUserTag.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';
import { Grid } from '@mui/material';
import UserComponent from '../../Pages/UserComponent/UserComponent';

interface ViewUserTagProps {
  name: string,
  value: any,
  userId: string
}

const ViewUserTag: FC<ViewUserTagProps> = ({ name, value, userId }) => {
  if (!value) {
      return null;
  }
  return (<>
      <Grid item xs={4} >
          <div className={styles.labField}>
              <span className={typoStyles.typoRobotoRegoular14}>{name}</span>
          </div>
      </Grid>
      <Grid item xs={8} >
          <div className={styles.valField}>
              <UserComponent detail={true} auditorName={value} auditorId={userId} />
          </div>
      </Grid>
  </>
  )
}

export default ViewUserTag;
