import React from "react";

interface AudioPlayerProps {
    audioUrl: any;
    
}



export function useAudioPlayer({audioUrl}:AudioPlayerProps) {

    const audioref = React.useRef<any>(null);
    const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<number>(0);

    React.useEffect(() => {
        const audio = getAudio();
        audio.play();
        setIsPlaying(true);

        return () => {
            audio.pause();
            setIsPlaying(false);
        };
    }, [audioUrl]);

    

    const OpenPlay = () => {
        if (!isPlaying) {
            audioref.current?.play();
            setIsPlaying(true);
        } else {
            audioref.current?.pause();
            setIsPlaying(false);
        }
    };

    const handleForward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime += 15;
    };

    const fastFarward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime += 30;
    };

    const handleBackward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime -= 15;
    };

    const fastbackward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime -= 30;
    };

    const handleProgress = (e: any) => {
        const audio = getAudio();
        if (!audio) return;
        const manualChange = Number(e.target.value);
        audio.currentTime = (audio.duration / 100) * manualChange;
        setProgress(manualChange);
    }

    const getAudio = () => {
        return audioref?.current
    };

    
    const onEnded = () => {
        setIsPlaying(false);
    }

    const onProgress = (e: any) => {
        setProgress((e.target.currentTime / e.target.duration) * 100);
    }
    const onTimeUpdate = () => {
        const progress = (audioref?.current.currentTime / audioref?.current.duration) * 100;
        setProgress(progress)
    }
    const getTime = (seconds: any) => {
        const val = Math.round(seconds);
        return [3600, 60].reduceRight((p: any, b: any) => (r: any) => [Math.floor(r / b)].concat(p(r % b)), r => [r])(val).map((a: any) => a.toString().padStart(2, '0')).join(':');
    }
   

    
    return{progress,isPlaying,audioref,OpenPlay,handleForward,fastFarward,handleBackward,fastbackward,handleProgress,onTimeUpdate,getTime,onProgress,onEnded,}
}