import { FC } from 'react';
import styles from './PDFviewer.module.css';
import { Dialog, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../Assests/Icons/Cross icon (2).svg';
import { usePdfPreview } from '../../Services/pdfPreviewService/pdfPreview';
import typoStyles from '../../Styles/component/Typography.module.css';

interface PdFviewerProps {
  open: boolean,
  handleClose: any,
  item: any
}

const PdFviewer: FC<PdFviewerProps> = ({ open, handleClose, item }) => {
  const { pdfUrl, pdfLoader, onloadimage } = usePdfPreview({ item });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      style={{ zIndex: 2000 }}
      PaperProps={{
        style: {
          // background: "none",
          //borderRadius: "16px",
          position: "inherit",
          boxShadow: pdfLoader ? "none" : "inherit",
          display: 'flex',
          alignItems: "center",
          justifyContent: "center",
          height: "inherit",
          padding:'14px'
        }
      }}
      maxWidth="md"
      aria-labelledby="responsive-dialog-title"
      onContextMenu={(e) => e.preventDefault()}
    >
      {!pdfLoader && <div style={{marginBottom:"10px", display:"flex", flexDirection:"row", width:"100%"}}>
				<div style={{width:"100%",display:"flex", alignItems:"center", justifyContent:"center"}}>
				<span className={typoStyles.typoRobotoMediumprimary1224124}>{item?.attachments?.fileName}</span>
				</div>
				<div >
        <Tooltip title={"Close"}>
				<IconButton size='small' onClick={() => handleClose()} className={styles.cross_Btn}>
					<CrossIcon className={styles.crossIcon} />
				</IconButton>
        </Tooltip>
				</div>
				
			</div>}
      { pdfUrl && <iframe className={styles.PdFviewer_div} onContextMenu={(e) => e.preventDefault()} width={'100%'} height={'100%'} src={pdfUrl + '#toolbar=0&scale=page-width&view=fitH&zoom=fitH'} onLoad={() => onloadimage()} draggable={false} />}
    </Dialog>
  )
}

export default PdFviewer;
