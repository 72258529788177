import React from 'react';
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider';
import { addMsg, getChat, getUserdata, getUsersdata, uploadFile } from '../api/messageAPIService';
import { allowedFileFormat, generateImageThumbnail, generateVideoThumbnail, attachmentTypes } from '../utils/ImageUtil';
import { paginationList } from '../paginationService';
import { chatListReduce } from '../reducer/chatReducer';
import axios from 'axios';
import { cancelErrMsg, urlParse } from '../utils/constant';
import { fileCount } from "../api/filecountAPIService";
import { threadupdateMessageCount } from '../api/threadAPIService';
import { readTranscriptFileFromURLToFile } from '../FileService/fileread';
import { useSelector } from 'react-redux';

let controller = axios.CancelToken.source();

interface Iprops {
    taskId: string,
    lastChat: any,
    socket: any,
    auditorId: any,
    managerId: any,
    providerId: any,
    subjectData: any,
    appointmentId: any,
    fillablePdf?: any,
    openConfirm?: any
}

export function useConversation(props: Iprops) {
    const { fillablePdf, socket, taskId, providerId, lastChat, subjectData, appointmentId, openConfirm } = props;
    const msgref = React.useRef<any>(null);
    const userId = useSelector((state: any) => state.persistReducers?.userReducer?.user?.id);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext);
    const [msgList, setMsgList] = React.useReducer(chatListReduce, []);
    const [currmsgList, setCurrMsgList] = React.useState<any>(null);
    const [userData, setUserdata] = React.useState<any>(null);
    const [patientData, setPatientdata] = React.useState<any>(null);
    const [pagination, setPagination] = React.useState(paginationList);
    const [text, setText] = React.useState<any>('');
    const [isMessage, setIsMessage] = React.useState<any>(false);
    const [uploadModel, setUploadModel] = React.useState<number | null>(null);
    const [progress, setProgress] = React.useState<any>(0);
    const [fileName, setFileName] = React.useState<any>(null);
    const [totalCount, setTotalCount] = React.useState<number | null>(null)
    const [msgthreadid, setmsgthreadid] = React.useState<string | null>(null)
    const [resultstatus, setResultstatus] = React.useState<any>(null);
    const [uploadedFile, setUploadedFile] = React.useState<any>(null);
    const [showButton, setShowButton] = React.useState(false);
    const [filetypes, setfiletype] = React.useState<any>(null);
    const [file, setfile] = React.useState<any>(null)
    const [isVideo, setIsVideo] = React.useState(false);
    const [showimage, setShowimage] = React.useState(false);

    const [send, setsend] = React.useState<any>(null)
    const [fillablePdfData, setFillablePdfData] = React.useState<any>(fillablePdf);
    const [chartNote, setChartNote] = React.useState<any>(false);

    let list = msgList;

    React.useEffect(() => {
        if (openConfirm && fillablePdfData && subjectData) {
            sendPDFToProvider()
        }
    }, [openConfirm]);

    const sendPDFToProvider = async () => {
        if (fillablePdfData && subjectData) {
            const filePath = fillablePdfData?.filePathPdf_ChartNote;
            const option = {
                method: 'GET',
                mode: 'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }
            const path: any = urlParse(filePath);
            const mainFileName = path.split('/')[path?.split('/').length - 1];
            const resultData: any = await readTranscriptFileFromURLToFile(option, path, mainFileName);

            if (resultData) {
                setShowButton(true)
                setsend(resultData)
                setUploadedFile(resultData?.name)
                setFileName(resultData?.name)
                setfiletype(resultData?.type)
                setFillablePdfData(null)
                setChartNote(true)
            }
        }
    }
    const addMessage = async (type: string, file: any, thumbnail: any, text: any) => {
        controller = axios.CancelToken.source();
        let result;
        const formData = new FormData();
        formData.append('taskId', taskId);
        formData.append('subjectId', subjectData?.id);

        if (type === 'media') {
            formData.append('mainFileName', file['name']);
            formData.append('thumbnailFileName', thumbnail['name']);
            formData.append('main', file);
            formData.append('thumbnail', thumbnail);
            result = await uploadFile(formData, setProgress, controller.token);
            updateTotalCount(result?.newMessage?.length);
        } else if (type === 'file') {
            formData.append('mainFileName', file['name']);
            if (chartNote && msgthreadid) {
                formData.append('chartNote', 'true');
                formData.append('threadId', msgthreadid);
            }
            formData.append('main', file);
            result = await uploadFile(formData, setProgress, controller.token);
            updateTotalCount(result?.newMessage?.length);
        } else {
            formData.append('text', text);
            result = await addMsg(formData);
        }

        setUploadModel(null);
        setProgress(0);
        setFileName(null);
        setFillablePdfData(null)
        if (result?.status) {

            if (result.newMessage[0].appointmentId === appointmentId && result.newMessage[0].subjectId === subjectData?.id) {

                setCurrMsgList(result.newMessage);
                setMsgList({ type: "addnew", payload: result.newMessage[0] });
                setText('')
                setIsMessage(true)
                setResultstatus(result)

                if (type !== 'text') {
                    snackbarSetting(true, result?.message, "success")
                }

            }
        } else {
            if (result?.message || result?.message !== '') {
                snackbarSetting(true, result?.message, "error")
            } else {
                snackbarSetting(true, cancelErrMsg, "error")
            }
        }
    }

    const uploadAttachment = async (e: any) => {

        setShowButton(false)
        setShowimage(false)
        setUploadedFile(null)
        setfiletype("null")
        setfile(null)
        setIsVideo(false)
        setChartNote(false)
        e.preventDefault();
        if (e.target.files?.length > 1) {
            setUploadModel(2)
            e.target.value = null;
        } else if (!allowedFileFormat.includes(e.target.files?.[0]?.["type"])) {
            setUploadModel(3)
            e.target.value = null;
        } else if (e.target.files?.[0]?.["size"] > 16e6) {
            setUploadModel(4)
            e.target.value = null;
        }
        else {

            setFileName(e.target.files?.[0]?.["name"]);

            const mainFile = e.target.files[0];

            if (mainFile?.["type"].includes('video')) {

                e.target.value = null;

                const reader = new FileReader();

                reader.onload = () => {
                    setfile(reader.result);
                    setShowimage(true)
                    setsend(mainFile)
                    setIsVideo(true);
                };

                reader.readAsDataURL(mainFile);


            } else if (mainFile?.["type"].includes('image')) {

                e.target.value = null;

                setIsVideo(false)

                const reader = new FileReader();

                reader.onload = () => {
                    setfile(reader.result);
                    setShowimage(true)
                    setsend(mainFile)
                };

                reader.readAsDataURL(mainFile);
            } else {
                e.target.value = null;
                setShowButton(true)
                setsend(mainFile)
                setUploadedFile(mainFile?.name)
                setfiletype(mainFile?.type.split('/')[1])
                setChartNote(false)
            }
            e.target.value = null;

        }

    }


    const submitMsg = async () => {

        let thumbnailImage;
        if (subjectData?.id && userId) {
            if (text !== '') {
                setText('');
                await addMessage('text', null, null, text);
            }
            else if (send && send.type.includes('image')) {
                setUploadModel(1);
                setProgress(0);
                const imgFlag = send?.["size"] < 200000 ? true : false;
                thumbnailImage = await generateImageThumbnail(send, imgFlag)

                await addMessage('media', send, thumbnailImage, null);
                setfile(null);
                setShowimage(false)
                setsend(null)
            }
            else if (send && send.type.includes('video')) {
                setUploadModel(1);
                setProgress(0);
                thumbnailImage = await generateVideoThumbnail(send);
                await addMessage('media', send, thumbnailImage, null);
                setfile(null);
                setShowimage(false)
                setsend(null)
            }
            else {
                setUploadModel(1);
                setProgress(0);
                await addMessage('file', send, null, null);
                setfile(null);
                setShowButton(false)
                setsend(null)
            }

        }
    }

    const handleClose = () => {

        setShowButton(false)
        setShowimage(false)
        setfile(null)

        setUploadedFile(null)
        setfiletype("null")
        setIsVideo(false)
        setChartNote(false)
    }
    const handleChange = (e: any) => {
        e.preventDefault()
        setText(e.target.value.replace(/(\*|[\uE000-\uF8FF]|\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, ""))
    }

    React.useEffect(() => {
        if (lastChat && subjectData) {
            setUserdata(lastChat.userData)
        } else if (lastChat) {
            setUserdata(lastChat.userData)
        } else {
            setUserdata(null)
        }
        setText('');
    }, [lastChat, subjectData,]);

    const onScroll = () => {
        if (msgref?.current) {
            const { scrollTop } = msgref.current;
            if (scrollTop === 0) {
                setPagination({ count: pagination.count, currentPage: pagination.currentPage + 1 })
            }
        }
    };

    const updateTotalCount = (count: number) => {
        setTotalCount((prevTotalCount) => (prevTotalCount ?? 0) + count);
    };



    React.useEffect(() => {
        async function initiate() {

            let patientDetails;
            if (!patientData) {
                loaderSetting(true)
                patientDetails = await getUserdata(taskId);
                if (patientDetails?.status) {
                    setPatientdata(patientDetails?.details[0])
                }
                loaderSetting(false)
            }
            const obj = {
                pageSize: pagination.count,
                pageNumber: pagination.currentPage,
                subjectId: subjectData?.id,
                appointmentId: patientData?.appointmentId ? patientData?.appointmentId : patientDetails?.details[0]?.appointmentId,
                providerId: providerId,
            }
            const result = await getChat(obj);
          
            setmsgthreadid(result?.messageData?.id)

            if (result?.status) {
                setResultstatus(result)

                setCurrMsgList(result?.messageData?.message)
                setMsgList({ type: "add", payload: result?.messageData?.message });
                setUserdata(result?.messageData?.userData);
                updateMessageStatus(result?.messageData?.id);

            } else {
                const usersData = await getUsersdata(taskId);
                if (usersData?.status) {
                    setUserdata(usersData?.userData)
                }
            }
        }
        initiate()
    }, [pagination,]);



    const updateMessageStatus = async (threadId: any) => {
        const data = {
            userId: userId,
            threadId: threadId
        };
        await threadupdateMessageCount(data);
    };


    const checktextstatus = (data: any, old: any) => {
        const updatedMsgData = [...old];

        if (data[0]?.chartNote === "true") {
            for (let j = 0; j < updatedMsgData.length; j++) {

                if (updatedMsgData[j]?.chartNote === 'true') {
                    updatedMsgData[j].type = "text";
                    updatedMsgData[j].text = "Removed";
                    updatedMsgData[j].isRemoved = "0";

                }
            }
        }


        updatedMsgData.push(data[0]);
        return updatedMsgData;

    };


    React.useEffect(() => {
        async function getFileCount() {
            const requestBody = {
                appointmentId: appointmentId,
                providerId: providerId,
                subjectId: subjectData?.id,
                count: true
            };

            try {
                const response = await fileCount(requestBody) as { attachmentCounts: number };
                setTotalCount(response.attachmentCounts);
            } catch (error) {
                console.error('Error:', error);
            }
        }


        getFileCount();


        socket?.on('receive_message', async (data: any) => {
            const message: any = data.message;
          
            if (message[0].appointmentId === appointmentId && message[0].subjectId === subjectData?.id) {
                const updatedMsgData = checktextstatus(message, list);
                setCurrMsgList(updatedMsgData);
                setMsgList({ type: "update", payload: updatedMsgData });
                updateMessageStatus(msgthreadid);
                if (attachmentTypes(message[0]?.type)) {
                    getFileCount();
                }

                list = updatedMsgData;


            }
        });


        return () => socket?.off('receive_message');
    }, [socket, msgthreadid, appointmentId, providerId, subjectData?.id,]);





    const checkstatus = (data: any, old: any) => {
        const updatedMessages = data.message;
        const updatedMsgData = [...old];

        for (let i = 0; i < updatedMessages.length; i++) {
            const msg = updatedMessages[i];

            for (let j = 0; j < updatedMsgData.length; j++) {
                const msgData = updatedMsgData[j];

                if (msg?.messageId === msgData?.id) {

                    msgData.doubleTick = msg.doubleTick;
                    msgData.allSeen = msg.allSeen;



                }
            }
        }

        return updatedMsgData;
    };


    React.useEffect(() => {
        socket?.on('receive_updated_message', async (data: any) => {
            const message: any = data.readMessageList;
            const updatedMsgData = checkstatus(message, msgList)
            setCurrMsgList(updatedMsgData);
            setMsgList({ type: "add", payload: updatedMsgData });

        });

        return () => socket?.off('receive_updated_message');
    }, [socket, msgList]);



    const cancelrequest = async (e: any) => {
        e.preventDefault()
        if (progress < 100) {
            controller.cancel();
            snackbarSetting(true, cancelErrMsg, "error")
        }
        setUploadModel(null)
        setShowButton(false)
        setShowimage(false)
    }

    return { send, setsend, file, isVideo, handleClose, uploadedFile, showButton, showimage, filetypes, resultstatus, fileName, totalCount, cancelrequest, progress, setUploadModel, uploadModel, uploadAttachment, msgref, onScroll, msgList, userData, patientData, submitMsg, handleChange, text, currmsgList, isMessage };
}