import React from 'react';
import styles from './SubjectList.module.css';
import { Divider, List, ListItem, Button } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import ChatNavigation from '../ChatNavigation/ChatNavigation';
import { useSubject } from '../../../../Services/chatService/subjectService';
import PatientHolder from '../../../../Components/PatientHolder/PatientHolder';
import ShadowDrawer from '../../../../Components/ShadowDrawer/ShadowDrawer';
import CreateSubject from "../CreateSubject/CreateSubject";
import CustomDialog from '../../../../Components/CustomDialog';
import ConfirmAlertFillablePdf from '../../../../Components/ConfirmAlertFillablePdf/ConfirmAlertFillablePdf';

interface Props {
	setFlagShowMsg: any,
	selectSubjcet: any,
	patientName: any,
	mrn: any,
}
const SubjectList = (props: Props) => {
	const { setFlagShowMsg, selectSubjcet, patientName, mrn, } = props;
	const { subjects, handleRemoveSubject, openConfirm, setOpenConfirm, subjectToRemove, setSubjectToRemove } = useSubject();
	const [open, setopen] = React.useState(false)
	const createSubjectRef = React.useRef<any>(null);
	const navigationClick = React.useCallback(() => {
		setFlagShowMsg(1)
	}, []);
	const handlesubject = () => {
		setopen(true)
	}
	const handleClickOutside = (event: any) => {
		if (createSubjectRef.current && !createSubjectRef.current.contains(event.target)) {
			setopen(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);
	const addSubjectToList = (value: any) => {
		setFlagShowMsg(4)
		selectSubjcet(value)
	};
	return (
		<div>
			<ChatNavigation navigationClick={navigationClick} />
			<PatientHolder patientName={patientName} mrn={mrn} />
			<div className={styles.header}>
				<span className={typoStyles.typoHelveticaRegoular14}>{"Select a subject"}</span>
				<Button onClick={handlesubject} className={`${styles.buttonstyle} ${typoStyles.typoRobotoRegularprimary21419}`}>
					{"Create a subject"}
				</Button>
			</div>
			<List component="nav" className={styles.list}>
				{subjects?.length > 0 && subjects?.map((item: { id: string, name: string, removed: string, type: string }) => (<div key={item.id} className={styles.listItem} onClick={() => selectSubjcet(item)}>
					<ListItem sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", cursor: "pointer" }} disablePadding>
						<div style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingBottom: "10px", alignItems: "center" }}>
							<span className={typoStyles.typoRobotoRegoularprimary571622}>{item.name}</span>
							<div className={styles.removebuttondiv}>
								{item.type === "3" && item.removed === "0" &&
									<Button onClick={(e) => {
										e.stopPropagation();
										setSubjectToRemove(item.id);
										setOpenConfirm(true);
									}} className={`${styles.removestyle} ${typoStyles.typoRobotoRegularprimary21419}`}>
										{"Remove"}
									</Button>
								}
								<ArrowForwardIosOutlinedIcon style={{ fontSize: "16px", fontWeight: "normal", color: "#959DAA", alignSelf: "center", marginTop: "3px" }} />
							</div>
						</div>
						<Divider />
					</ListItem>
				</div>
				))}
			</List>
			{open && <ShadowDrawer openDrawer={true} ><div ref={createSubjectRef}>
				<CreateSubject
					addSubjectToList={addSubjectToList}
				/>
			</div></ShadowDrawer>}
			<CustomDialog
				open={openConfirm}
				handleClick={() => {
					setOpenConfirm(false);
				}}
			>
				<ConfirmAlertFillablePdf msg={'Are you sure, you want to delete this subject?'}
					yes={() => handleRemoveSubject(subjectToRemove)}
					no={() => setOpenConfirm(false)} />
			</CustomDialog>
		</div>
	)
}

export default SubjectList;
