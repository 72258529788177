import React, { FC } from 'react';
import styles from './TaskNoRecord.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';
import emptyPng from '../../Assests/Icons/empty-folder/empty-folder.png';
import { Box } from '@mui/material';

interface TaskNoRecordProps {
  record: any
}

const TaskNoRecord: FC<TaskNoRecordProps> = ({ record }) => (<>
  {!record || record?.length === 0 &&
    <Box className={styles.boxModel} >
      <img src={emptyPng} alt='empty' />
      <span className={typoStyles.typoRobotoMediumprimary671833}>{"No Record"}</span>
    </Box>}
</>);

export default TaskNoRecord;
