import { combineReducers } from 'redux'

import authMonitorReducer from './authMonitorReducer'
import notificationCountReducer from './notificationCountReducer'
import audioPlayerReducer from './audioPlayerReducer'
import productionDateReducer from './productionDateReducer'
const nonPersistReducers = combineReducers({
    productionDateReducer: productionDateReducer,
    authMonitor: authMonitorReducer,
    notificationCountReducer: notificationCountReducer,
    audioPlayerReducer: audioPlayerReducer,
})

export default nonPersistReducers
