import React from 'react';
import { Box } from '@mui/material';

interface DrawerTabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export function TabPanel(props: DrawerTabProps) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box >
                  {children}
              </Box>
          )}
      </div>
  );
}
export function a11yProps(index: number) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
  };
}
