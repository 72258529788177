import React from 'react'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider';
import { addSubjectdata } from '../api/messageAPIService';

interface usesubjectprops {
    addSubjectToList: any
}

export function Usesubject(props: usesubjectprops) {
    const { addSubjectToList, } = props;
    const [text, setText] = React.useState('');
    const [characterCount, setCharacterCount] = React.useState(0);
    const { loaderSetting } = React.useContext(LoaderGFContext);
    const { snackbarSetting } = React.useContext(SnackbarContext)

    const handleTextChange = (event: any) => {
        event.preventDefault()
        const inputText = event.target.value.trim();
        if (inputText.length <= 40) {
            setText(event.target.value.replace(/(\*|[\uE000-\uF8FF]|\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, ""))
            setCharacterCount(inputText.length);

        }
    };

    const saveSubject = async (value: any) => {
        if (text.trim() === "") {
            return false;
        }
        loaderSetting(true)
        const bodyParams = {
            name: text,
            type: "3",
            removed: value
        };
        try {
            const data = await addSubjectdata(bodyParams);
            if (data.status === true) {

                addSubjectToList(data.subject)
            } else {
                snackbarSetting(true, data?.message, "error")
            }
        } catch (error) {
            console.error(error);
        } finally {
            loaderSetting(false);
        }
        setText("")
        setCharacterCount(0)
    }
    return {
        text, characterCount, handleTextChange, saveSubject,
    }
}