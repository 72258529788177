import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import SidebarView from '../Sidebar/Sidebar'
import Header from '../Header'
import styles from './Mainlayout.module.css'
import { useLocation, useOutlet } from 'react-router-dom'
import { GlobalAlertGFContext } from '../../../Components/GlobalAlert/GlobalAlertProvider'
import { authmonitor } from '../../../Services/api/authMonitor'
import { useDispatch, useSelector } from 'react-redux'
import { SocketContext } from '../../../Components/Sockets/Socket'
import { useSnackbar } from 'notistack'
import { getNotificationCount } from '../../../Services/api/notificationAPIService'
import { createPortal } from 'react-dom'
import DraggableComponent from '../../../Components/DraggableComponent/DraggableComponent'
import { notificationCountSetting } from '../../../Services/actions/notificattionAction'
import MenuIcon from '@mui/icons-material/Menu'
import { Button } from '@mui/material'

const drawerWidth = 252

export default function Mainlayout() {
    const outlet = useOutlet()
    const location = useLocation()
    const [toggle, setToggle] = React.useState<boolean>(false)
    const [broken, setBroken] = React.useState(
        window.matchMedia('(max-width: 800px)').matches,
    )
    const { alertSetting } = React.useContext(GlobalAlertGFContext)
    const user = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )
    const token = useSelector(
        (state: any) => state.persistReducers?.userReducer?.userToken,
    )
    const filePreview: boolean = useSelector(
        (state: any) => state.nonPersistReducers.authMonitor.filePreview,
    )
    const notificationCount = useSelector(
        (state: any) => state.nonPersistReducers.notificationCountReducer.count,
    )
    const audioModalOpen = useSelector(
        (state: any) =>
            state.nonPersistReducers.audioPlayerReducer.audioModalOpen,
    )

    const dispatch = useDispatch()

    const { socket } = React.useContext(SocketContext)
    const { enqueueSnackbar } = useSnackbar()
    // const handleDrawerToggle = () => {
    //     setMobileOpen(!mobileOpen)
    // }
    const container =
        window !== undefined ? () => window.document.body : undefined

    React.useEffect(() => {
        const interval = setInterval(() => {
            authmonitorAPI({
                userId: user?.id,
                token,
                role: user?.userRole,
                filePreview,
            })
        }, 60000)
        return () => clearInterval(interval)
    }, [filePreview])

    React.useEffect(() => {
        async function initiateAPI() {
            await authmonitorAPI({
                userId: user?.id,
                token,
                role: user?.userRole,
                filePreview,
            })
            await getNotificationCountFun()
        }
        initiateAPI()
    }, [filePreview])

    const authmonitorAPI = async (data: any) => {
        const result: any = await authmonitor(data)
        if (result?.statusCode === 410 || result?.response?.status === 410) {
            alertSetting(true, 1)
        } else if (
            result?.statusCode === 411 ||
            result?.response?.status === 411
        ) {
            alertSetting(true, 0)
        }
    }
    const receiverSocket = async (data: any) => {
        const message: any = data.message
        if (message.createBy !== user?.id) {
            const mess = await createMessage(message)
            dispatch(notificationCountSetting(notificationCount + 1))
            getNotificationCountFun()
            enqueueSnackbar(mess, {
                variant: 'reportComplete',
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                autoHideDuration: 5000,
            })
        }
    }

    const createMessage = async (data: any) => {
        switch (data.type) {
            case 'encounter':
                return `${data.byUser} has submitted the Audio for the task ${data.taskName}.`
            case 'transcript':
                return `Transcript for Task ${data.taskName} is generated.`
            case 'createTask':
                return `Task is created for${data.taskName} by ${data.byUser}.`
            case 'updateTask':
                return `Task is updated for ${data.taskName} by ${data.byUser}.`
            case 'markHasClaimed':
                return `Mark is claimed for the task ${data.taskName} by ${data.byUser}.`
            case 'statusUpdated':
                return `Status is changed from ${data.previousTaskStatus} to ${data.currentTaskStatus} for the task ${data.taskName} by ${data.byUser}`
            case 'reassignTask':
                return `${data.taskName} is reassigned to by ${data.byUser}.`
            case 'messageTextNotification':
                return `${data.byUser} sent a message to the task ${data.taskName} for the issue ${data.subject}.`
            case 'messageFileNotification':
                return `${data.byUser} uploaded a file to the task ${data.taskName} for the issue ${data.subject}.`
            case 'automaticTaskCreation':
                return `Task is created for ${data.taskName} and Encounter has been submitted by ${data.byUser}.`
            default:
                return null
        }
    }

    React.useEffect(() => {
        socket?.on('notification', receiverSocket)
    }, [socket])

    const getNotificationCountFun = async () => {
        const result: any = await getNotificationCount()
        if (result?.status) {
            dispatch(notificationCountSetting(result?.payload))
        }
    }

    return (
        <div className="d-flex">
            {audioModalOpen &&
                createPortal(<DraggableComponent />, document.body)}
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    ml: { sm: `${drawerWidth}px` },
                    height: '40px',
                    minHeight: '40px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 1px 4px #00000014',
                    opacity: 1,
                    justifyContent: 'center',
                    zIndex: 10,
                }}
            >
                <Header notificationCount={notificationCount} />
            </AppBar>
            <SidebarView
                toggle={toggle}
                setToggle={setToggle}
                setBroken={setBroken}
            />
            {/* <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                 <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            marginTop: '83px',
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            opacity: 1,
                        },
                    }}
                >
                    <SidebarView />
                </Drawer>
                {/* <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            marginTop: '83px',
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            opacity: 1,
                        },
                    }}
                    open
                >
                    
                </Drawer>
            </Box>*/}
            <main className={`flex-fill ${styles.siteContentWraper}`}>
                {/* <Toolbar /> */}
                <div className={styles.sidebarTransition}>
                    {broken ? (
                        <Button
                            style={{
                                marginTop: -22,
                                left: 0,
                                minWidth: 0,
                                position: 'fixed',
                                zIndex: 999,
                            }}
                            onClick={() => setToggle(true)}
                        >
                            <MenuIcon sx={{ fontSize: 35 }} />
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
                <div style={{ paddingTop: '20px' }}>{outlet}</div>
            </main>
        </div>
    )
}
