import React, { FC } from 'react';
import styles from './UserAvatarMap.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';
import { Avatar } from '@mui/material';
import { findLength, stringToborColor, stringToColor } from '../../Services/utils/constant';

interface UserAvatarMapProps {
  userData: any
}
export function stringAvatar(name: string) {
  return {
      sx: {
          bgcolor: stringToColor(name),
          width: '24px',
          height: '24px',
          border: `1px solid ${stringToborColor(name)}`
      },
      children:
          name.indexOf(' ') >= 1
              ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
              : name[0][0],
  }
}
const UserAvatarMap: FC<UserAvatarMapProps> = ({ userData }) => (
  <div className={styles.UserAvatarMap}>
    <div className={styles.userAvatarDiv}>
      <Avatar {...stringAvatar(userData[0]?.id.split(' ')[0])}>
        <span className={typoStyles.typoRobotoMediumprimary214190}>{userData[0]?.name.charAt(0).toUpperCase()}</span>
      </Avatar>
      <span className={typoStyles.typoRobotoRegularprimary661434}>{userData[0]?.name}{","}</span>
      <span className={[`${typoStyles.typoRobotoRegularprimary661434} ${styles.overflowDiv}`].join(" ")}>{findLength(userData?.slice(1))}</span>
    </div>
  </div>
)


export default UserAvatarMap;
