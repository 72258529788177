import buttonStyles from '../../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import { ReactComponent as Alert } from '../../../../Assests/Icons/Alert.svg'
import { Typography, Button } from '@mui/material';
import style from './MarkClaimAlert.module.css';
import { ViewTaskProps } from '../../../../Services/taskManagerService/viewTaskService';
import { useMarkClaim } from '../../../../Services/taskManagerService/markClaimTaskService';
import customDialoagStyles from '../../../../Styles/component/CustomDialog.module.css';

const MarkClaimAlert = (props: ViewTaskProps) => {
    if(!props.task && !props.updatedTaskList){
        return null;
    }
    const { task, updatedTaskList, menuHandleCloseClick } = props;
    const { markClaimSubmitted } = useMarkClaim({updatedTaskList})

    return (
        <div className={customDialoagStyles.root}>
            <div className={style.markClaimDiv}>
                <div className={style.alertDiv}>
                    <div>
                        <Alert />
                    </div>
                    <div className={style.claimColumnDiv}>
                        <div className={style.claimColumnGap}>
                            <Typography className={typoStyles.typoRobotoMediumprimary6014}>
                                {'Are you sure you want to mark claim submitted'}
                            </Typography>
                        </div>
                        <div className={style.claimAlignItem}>
                            <Button
                                className={buttonStyles.cancelbutton}
                                onClick={() => {
                                    if(menuHandleCloseClick){
                                        menuHandleCloseClick()
                                    }
                                }}
                            >
                                <Typography className={typoStyles.typoRobotoRegoularprimary5914}>
                                    {'Cancel'}
                                </Typography>
                            </Button>
                            <Button
                                className={buttonStyles.yesbutton}
                                onClick={() => {
                                    if(task?.id){
                                        markClaimSubmitted(task?.id)
                                    }
                                }}
                            >
                                <Typography className={typoStyles.typoRobotoMediumprimary214}>
                                    {'Yes'}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MarkClaimAlert;
