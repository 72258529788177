import API from '../api/index'

export const auditorListAPI = (data: any) =>
    API.handleApiRequest(() => API.post(`auditor/auditorList`, data))
export const taskListAPI = (data: any) =>
    API.handleApiRequest(() => API.post(`auditor/task`, data))
export const auditorManagerListAPI = (data: any) =>
    API.handleApiRequest(() => API.post(`auditor/managerList`, data))
export const auditorSerachListAPI = (data: any) =>
    API.handleApiRequest(() => API.post(`auditor/searchAuditorList`, data))
export const auditorManagerSerachListAPI = (data: any) =>
    API.handleApiRequest(() => API.post(`auditor/searchManagerList`, data))
export const updateAuditor = (data: any) =>
    API.handleApiRequest(() => API.post(`auditor/update-auditor`, data))
export const updateAuditorManager = (data: any) =>
    API.handleApiRequest(() => API.post(`auditor/update-manager`, data))
export const startTask = (data: any) =>
    API.handleApiRequest(() => API.post(`encounter/startTask`, data))
export const save2AChangesToCloud = (data: any) =>
    API.handleApiRequest(() => API.post(`encounter/edit2A`, data))
export const saveJAMALinkAPI = (data: any) =>
    API.handleApiRequest(() => API.post(`encounter/editJAMALink`, data))
export const savePNChangesToCloud = (data: any) =>
    API.handleApiRequest(() => API.post('encounter/editProgressNote', data))
export const getEncounterData = (data: any) =>
    API.handleApiRequest(() => API.post('encounter/fetch', data))
export const updateDisplayStatus = (data: any) =>
    API.handleApiRequest(() => API.post('encounter/updateDisplayStatus', data))
export const submitProgressNotes = (data: any) =>
    API.handleApiRequest(() => API.post('encounter/submitProgressNote', data))
