import { FC } from 'react';
import styles from './VideoPlayer.module.css';
import { useVideoPlayer } from '../../Services/videoplayerService/videoplayer';
import { CircularProgress, Dialog, IconButton, Slider, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { ReactComponent as Play } from '../../Assests/Icons/Icons svg/Playicon.svg';
import { ReactComponent as Pause } from '../../Assests/Icons/Icons svg/Pause button.svg';
import { ReactComponent as UnmuteIcon } from '../../Assests/Icons/unmute icon.svg';
import { ReactComponent as MuteIcon } from '../../Assests/Icons/mute icon.svg';
import { styled } from '@mui/material/styles';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { ReactComponent as CrossIcon } from '../../Assests/Icons/Icons svg/cross icon.svg';


interface VideoPlayerProps {
  open: boolean,
  handleClose: any,
  item: any
}

const VideoPlayer: FC<VideoPlayerProps> = ({ open, handleClose, item }) => {
  const { keyId,onErrolLoad, sourceref, muteEvent, onEnded, mute, videoUrl, onCanPlay, videoLoader, onProgress, togglePlay, videoref, isPlaying, handleProgress, progress, onMouseOver, onMouseLeave, onMouse, handleFullScreenrequest, handleVolume, volume, onTimeUpdate } = useVideoPlayer({item});
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      style={{ zIndex: 2000 }}
      PaperProps={{
        style: {
          background: "none",
          position: "inherit",
          overflow: "hidden",
          boxShadow: videoLoader ? "none" : "inherit",
          display: 'flex',
          alignItems: "center",
          justifyContent: "center"
        }
      }}
      maxWidth="lg"
      aria-labelledby="responsive-dialog-title"
    >
      {!videoLoader ? <div className={styles.header}>
      <Tooltip title={"Close"}>
        <IconButton onClick={() => handleClose()} className={styles.cross_Btn}>
          <CrossIcon className={styles.crossIcon} />
        </IconButton>
        </Tooltip>
      </div> :
        <CircularProgress color="inherit" sx={{ color: "#fff" }} />
      }
      <div className={styles.player_root} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} >
       {videoUrl && <video id={keyId} style={{ minHeight:"100%", minWidth:"100%", objectFit:"fill"}}  onError={(e) => onErrolLoad(e)}  onEnded={() => onEnded()} onCanPlay={() => onCanPlay()} muted={mute} autoPlay onClick={togglePlay} onTimeUpdate={onTimeUpdate} onVolumeChange={handleVolume} controlsList="nodownload noremoteplayback" onProgress={onProgress} ref={videoref} width={"100%"} height={"100%"} controls={false} >
          <source ref={sourceref} src={videoUrl}  />
        </video>}
        {!isPlaying &&  <Tooltip title={isPlaying ? "Pause" : "Play"}><IconButton onClick={togglePlay} sx={{ position: "absolute" }}>
          {isPlaying ? <Pause /> : <Play />}
        </IconButton></Tooltip>}

      </div>
      {(onMouse && !videoLoader) && <div className={styles.footer} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} >
        <div className={styles.footer_sub}>
          <Slider
            min={0}
            max={100}
            value={progress}
            onChange={handleProgress}
            aria-label="Song"
            sx={{
              width: "100%",
              color: '#427BE0',
              '& .MuiSlider-track': {
                border: 'none',
               },
              '& .MuiSlider-thumb': {
                width: 20,
                height: 20,
                background: '#427BE0',
                border: "none",
                opacity: 1,
                willChange: "transform"
              },
            }}
          />
          <LightTooltip placement='top' title={<VolumeToolTip volume={volume} handleVolume={handleVolume} />}>
            <IconButton style={{padding:"5px 0px 5px 0px"}} onClick={() => muteEvent()} >
              {!mute ? <MuteIcon /> : <UnmuteIcon />}
            </IconButton>
          </LightTooltip>
          <Tooltip title={"FullScreen"}>
          <IconButton onClick={handleFullScreenrequest}>
            <FullscreenIcon sx={{ color: "white" }} />
          </IconButton>
          </Tooltip>
        </div>
      </div>}
    </Dialog>
  );
};

export default VideoPlayer;
interface VolumeProps {
  volume: any,
  handleVolume: any
}
const VolumeToolTip = (props: VolumeProps) => {
  return (
    <div className={styles.Volume__root}>
      <Slider
        min={0}
        max={100}
        defaultValue={80}
        orientation='vertical'
        value={props.volume * 100}
        sx={{
          color: '#D3D3D3',
          '& .MuiSlider-track': {
            border: 'none',
          }
        }}
        size="small"
        onChange={props.handleVolume}
      />
    </div>
  )
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "none",
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
  },
}));