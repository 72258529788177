import React from 'react';
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { getlastChat } from '../api/messageAPIService';
import { chatListReduce } from '../reducer/messageThread';
import { UnreadCountContext } from '../../Components/Unreadcount/UnreadMessageCount';
import { totalunreadcount } from '../api/threadAPIService';
import { useSelector } from 'react-redux';

interface Props {
    taskId: any,
    socket: any,
    appointmentId: any,
}

export function useLastConversation(props: Props) {
    const userId = useSelector((state: any) => state.persistReducers?.userReducer?.user?.id);
    const { taskId, socket, appointmentId } = props;
    const [chatList, setChatList] = React.useReducer(chatListReduce, []);
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [loader, setLoader] = React.useState<any>(false);
    const { setUnreadCount } = React.useContext(UnreadCountContext);
    const [listPagination, setListPagination] = React.useState({
        pageSize: 10,
        pageNumber: 1
    });

    const listInnerRef = React.useRef<any>(null);
    const [totalCount, setTotalcount] = React.useState<any>(0);
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                setListPagination({ ...listPagination, pageSize: listPagination.pageSize, pageNumber: listPagination.pageNumber + 1 })
            }
        }
    };

    React.useEffect(() => {
        async function initiate() {

            await getlastmessagelist(listPagination.pageNumber)
            
        }
        initiate();

    }, [listPagination.pageSize, listPagination.pageNumber,]);

    const getlastmessagelist = async (page: number) => {
        loaderSetting(true)
        const bodyParams = {
            taskId,
            pageSize: 10,
            pageNumber: page
        }
        const result = await getlastChat(bodyParams);

        if (result?.status && result?.messageList?.length !== 0) {
            setTotalcount(result?.totalRecords);
            setChatList({ type: 'add', payload: result?.messageList });
            setLoader(false);
        } else {
            if (page === 1) {
                setLoader(true);
            }
        }
        loaderSetting(false);
    }

   

    const TotalunreadCount= async()=>{
        const bodyParams = {
            taskId:taskId
        };
        const response = await totalunreadcount(bodyParams);
        if(response.totalUnreadThreadCount && setUnreadCount){
            setUnreadCount(response.totalUnreadThreadCount);

        }
      
    }

    React.useEffect(() => {    
        TotalunreadCount();
    }, []);

   


    const receiverSocket = async (data: any) => {
        const message: any = data.message

        if (message[0].appointmentId === appointmentId) {
            setChatList({ type: 'removeall', payload: [] });
            await getlastmessagelist(1)

        }
    }
    React.useEffect(() => {

        socket?.on('receive_message', receiverSocket);
        return () => socket?.off('receive_message');
    }, [socket]);

    return { userId, chatList, loader, onScroll, listInnerRef, totalCount }
}

