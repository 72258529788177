import React from "react";
import { readFileFromURL } from "../FileService/fileread";
import { LoaderGFContext } from "../../Components/Loader/LoaderProvider";
import { convertToHtml } from "mammoth/mammoth.browser";
import { useSelector } from "react-redux";

interface DocPreviewProps {
    item: any
}
export function useDocPreview({ item }: DocPreviewProps) {
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const docRef = React.useRef<HTMLDivElement | null>(null);
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [ docUrl, setDocUrl ] = React.useState<any>(null);
    const [ docLoader, setDocLoader ] = React.useState<boolean>(false);
    const [ docError, setDocError ] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function initiateAPI() {
            setDocLoader(true);
            loaderSetting(true);
            setDocError(false);
            const option = {
                method: 'GET',
                mode:'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }
            const result = await readFileFromURL(item.attachments.relativePath, option);            
            if(!result) return;

            convertTohtml(result)
            setDocUrl(result);
            loaderSetting(false)
         }
        initiateAPI();
    },[]);

    const convertTohtml = (url: any) => {
        setDocLoader(false)
        const jsonFile = new XMLHttpRequest();
        jsonFile.open('GET', url, true);
        jsonFile.send();
        jsonFile.responseType = 'arraybuffer';
        jsonFile.onreadystatechange = () => {
          if (jsonFile.readyState === 4 && jsonFile.status === 200) {
            convertToHtml({ arrayBuffer: jsonFile.response }).then((result: any) => {
            if(docRef?.current){
                docRef.current.innerHTML = result.value;
            }
        })
            .catch((a: any) => {
              console.log('alexei: something went wrong', a);
              setDocError(true);
            })
            .done();
          }
        };
    }
    const onloadimage = () => {
        URL.revokeObjectURL(docUrl);
        setDocLoader(false);
        loaderSetting(false);
    }
    return { docUrl, docLoader, onloadimage, docRef, docError };
}