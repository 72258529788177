import {
    Button,
    MenuItem,
    Typography,
    Divider
} from '@mui/material'
import Dropdown from '../../../../Components/Dropdown'
import buttonStyles from '../../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import styles from './ReassignTask.module.css';
import { useReassign } from '../../../../Services/taskManagerService/reassignTaskService'
import { ViewTaskProps } from '../../../../Services/taskManagerService/viewTaskService'
import UserComponent from '../../../UserComponent/UserComponent';
import TaskdrawerHeader from '../../../../Components/TaskdrawerHeader/TaskdrawerHeader';

export default function ReassignTask(props: ViewTaskProps) {

    const { taskName, statusFlag1, statusFlag, handleChange, statusObj, status, reassignSubmitted } = useReassign(props)

    return (<div>
        <TaskdrawerHeader title={taskName} onclick={() => {
            if (props.menuHandleCloseClick) {
                props.menuHandleCloseClick()
            }
        }} />
        <Divider />
        <div className={styles.rootBox}>
            <div className={styles.LabelOneBox}>
                <Typography className={typoStyles.typoRobotoRegoular14}>
                    {'Reassign Task'}
                </Typography>
            </div>
            <div className={styles.LabelTwoBox}>
                <Typography className={typoStyles.typoRobotoitalic14}>
                    {statusFlag1 ? 'Task is reassigned to ' : 'Task Owner: '}
                </Typography>
                <div className={styles.labelTypo}>
                    <Typography className={typoStyles.typoRobotoitalicprimary5714}>
                        {statusObj.firstName + " " + statusObj.lastName}
                    </Typography>
                </div>
            </div>
            <div className={styles.LabelThreeBox}>
                <Typography className={typoStyles.typoRobotoRegoular14}>
                    {'Change Auditor'}
                </Typography>
            </div>
            <Dropdown disable={statusFlag1} handleChange={handleChange} value={status}>
                {props.auditorList?.map((item: { id: string, firstName: string, lastName: string }) => (
                    <MenuItem
                        sx={{
                            padding: '8px',
                        }}
                        hidden={props.task?.auditorId === item.id ? true: false}
                        key={item.id}
                        value={item.id}
                        disabled={props.task?.auditorId === item.id}
                    >
                        <div style={{ marginLeft: "15px" }}>
                            <UserComponent auditorName={item.firstName + " " + item.lastName} auditorId={item.id} />
                        </div>
                    </MenuItem>
                ))}
            </Dropdown>
            <div className={styles.DivBTNBox}>
                {statusFlag && (
                    <Button
                        className={buttonStyles.primarybutton}
                        onClick={() => reassignSubmitted()}
                    >
                        <Typography className={typoStyles.typoRobotoMediumprimary214}>
                            {'Save'}
                        </Typography>
                    </Button>
                )}
            </div>
        </div>
    </div>
    )
}
