import { FC } from 'react';
import styles from './DocViewer.module.css';
import { Dialog, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../Assests/Icons/Cross icon (2).svg';
import { useDocPreview } from '../../Services/docViewerService/docViewer';
import typoStyles from '../../Styles/component/Typography.module.css';
import { ReactComponent as Brokendocument } from '../../Assests/Icons/Broken document.svg';

interface DocViewerProps {
	open: boolean,
	handleClose: any,
	item: any
}

const DocViewer: FC<DocViewerProps> = ({ open, handleClose, item }) => {
	const { docLoader, docRef, docError } = useDocPreview({ item });

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth={true}
			style={{ zIndex: 2000 }}
			PaperProps={{
				style: {
					background: "white",
					//borderRadius: "16px",
					position: "inherit",
					boxShadow: docLoader ? "none" : "inherit",
					display: 'flex',
					alignItems: "center",
					justifyContent: "center",
					height: "inherit"
				}
			}}
			maxWidth="lg"
			aria-labelledby="responsive-dialog-title"
			onContextMenu={(e) => e.preventDefault()}
		>
			
			{!docLoader && 
			<div style={{width:"100%",height:"100%",background:"white", display:"flex", alignItems:'center',flexDirection:'column', overflow:'hidden'}}>
				<div style={{marginTop:"20px", display:"flex", flexDirection:"row", width:"100%"}}>
				<div style={{width:"100%",display:"flex", alignItems:"center", justifyContent:"center"}}>
				<span className={typoStyles.typoRobotoMediumprimary1224124}>{item?.attachments?.fileName}</span>
				</div>
				<div style={{marginRight:"10px"}}>
				<Tooltip title={"Close"}>
				<IconButton size='small' onClick={() => handleClose()} className={styles.cross_Btn}>
					<CrossIcon className={styles.crossIcon} />
				</IconButton>
				</Tooltip>
				</div>
			</div>
				{!docError ? <div className={styles.docxContainer} ref={docRef}></div>: 
				<div className={styles.brokenPlaceholder}>
					<Brokendocument />
					<span style={{marginTop:"50px"}} className={typoStyles.typoRobotoMediumprimary742042}>{"Oops! Something went wrong"}</span>
					</div>}
			</div>}
		</Dialog>
	)
};

export default DocViewer;
