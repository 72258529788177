import { Dayjs } from 'dayjs'
import Stack from '@mui/material/Stack'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

type CustomDatePickerProps = {
    value: Dayjs | null
    onChange?: (newValue: Dayjs | null) => void
    startedDate?: Dayjs | null
    readOnly?: any
    height?: any
}

export default function CustomDatePicker({
    height,
    value,
    onChange,
    startedDate,
    readOnly,
}: CustomDatePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DatePicker
                    value={value}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: height
                                ? '44px !important'
                                : '40px !important',
                            minHeight: '40px',
                            width: '195px',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            borderRadius: '4px',
                        },
                    }}
                    // minDate={startedDate}
                    readOnly={readOnly}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e)
                        }
                    }}
                />
            </Stack>
        </LocalizationProvider>
    )
}
