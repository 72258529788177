export interface CodingReportInitialValuesType {
    MDMComplexity?: string | any
    MDMRisk?: string | any
    MDMData?: string | any
    MDMCPTCode?: string | any
    FTFPT?: string | any
    NFTFPT?: string | any
    TPT?: string | any
    TBCPTCode?: string | any
    ICD10Codes?: Array<any>
    OCPTCodes?: Array<any> | any
    MedicalNecessity?: string | any
}
export interface reviewOfSystemType {
    // [key: string]: string
    GENERAL?: string | any
    HEENT?: string | any
    RESPIRATORY?: string | any
    CARDIOVASCULAR?: string | any
    GASTROINTESTINAL?: string | any
    MUSCULOSKELETAL?: string | any
    SKIN?: string | any
    NEUROLOGIC?: string | any
    PSYCHIATRIC?: string | any
    GENITOURINARY?: string | any
    HEMATOLOGIC?: string | any
    ENDOCRINE?: string | any
    LYMPHATIC?: string | any
}
export interface physicalExamType {
    VITALS?: string
    GENERAL?: string
    HEENT?: string
    NECK?: string
    RESPIRATORY?: string
    CARDIOVASCULAR?: string
    ABDOMEN?: string
    LYMPHATIC?: string
    EXTREMITIES?: string
    SKIN?: string
    PSYCHOLOGICAL?: string
    NEUROLOGICAL?: string
    GENITOURINARY?: string
    BREAST?: string
}
export interface initial2CValuesType {
    'CHIEF COMPLAINT'?: string | any
    'HISTORY OF PRESENT ILLNESS'?: string | any
    ALLERGIES?: string | any
    'CURRENT MEDICATIONS'?: string | any
    'MEDICAL HISTORY'?: string | any
    'SURGICAL HISTORY'?: string | any
    'SOCIAL HISTORY'?: string | any
    'FAMILY HISTORY'?: string | any
    'REVIEW OF SYSTEMS'?: reviewOfSystemType
    'PHYSICAL EXAM'?: physicalExamType
    'DIAGNOSTIC TESTS'?: string | any
    LABS?: string | any
    ASSESSMENT?: string | any
    PLAN?: string | any
    'CODING REPORT'?: any
}

export const rosKeys = [
    'GENERAL',
    'HEENT',
    'RESPIRATORY',
    'CARDIOVASCULAR',
    'GASTROINTESTINAL',
    'MUSCULOSKELETAL',
    'SKIN',
    'NEUROLOGIC',
    'PSYCHIATRIC',
    'GENITOURINARY',
    'HEMATOLOGIC',
    'ENDOCRINE',
    'LYMPHATIC',
]
export const peKeys = [
    'VITALS',
    'GENERAL',
    'HEENT',
    'NECK',
    'RESPIRATORY',
    'CARDIOVASCULAR',
    'ABDOMEN',
    'LYMPHATIC',
    'EXTREMITIES',
    'SKIN',
    'PSYCHOLOGICAL',
    'NEUROLOGICAL',
    'GENITOURINARY',
    'BREAST',
]

export const review = {
    GENERAL: '',
    HEENT: '',
    RESPIRATORY: '',
    CARDIOVASCULAR: '',
    GASTROINTESTINAL: '',
    MUSCULOSKELETAL: '',
    SKIN: '',
    NEUROLOGIC: '',
    PSYCHIATRIC: '',
    GENITOURINARY: '',
    HEMATOLOGIC: '',
    ENDOCRINE: '',
    LYMPHATIC: '',
}
export const PE = {
    VITALS: '',
    GENERAL: '',
    HEENT: '',
    NECK: '',
    RESPIRATORY: '',
    CARDIOVASCULAR: '',
    ABDOMEN: '',
    LYMPHATIC: '',
    EXTREMITIES: '',
    SKIN: '',
    PSYCHOLOGICAL: '',
    NEUROLOGICAL: '',
    GENITOURINARY: '',
    BREAST: '',
}
