import React, { FC } from 'react';
import styles from './ImagePreview.module.css';
import { CircularProgress, Dialog, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../Assests/Icons/Cross icon (2).svg';
import { useImagePreview } from '../../Services/ImagePreviewService/ImagePreview';

interface ImagePreviewProps {
  open: boolean,
  handleClose: any,
  item: any
}

const ImagePreview: FC<ImagePreviewProps> = ({ open, handleClose, item }) => {
  const { onErrolLoad, imageUrl, onloadimage, imageLoader } = useImagePreview({ item });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      style={{ zIndex: 2000 }}
      PaperProps={{
        style: {
          background: imageLoader ? 'none': "#494747",
          borderRadius: "16px",
          position: "inherit",
          boxShadow: imageLoader ? "none" : "inherit",
          display: 'flex',
          alignItems: "center",
          justifyContent: "center",
          overflowY: imageLoader ? "hidden" : "auto",
        }
      }}
      maxWidth="md"
      aria-labelledby="responsive-dialog-title"
    >
      {!imageLoader ? <div className={styles.header}>
      <Tooltip title={"Close"}>
        <IconButton onClick={() => handleClose()} className={styles.cross_Btn}>
        <CrossIcon className={styles.crossIcon} />
        </IconButton>
        </Tooltip>
      </div> :
        <CircularProgress color="inherit" sx={{color:"#fff"}} />
      }
      {imageUrl && <img 
        onLoad={() => onloadimage()} 
        onError={(e) => onErrolLoad(e)} 
        draggable={false} 
        className={`${styles.ImagePreview} ${styles.disableImageGallery}`}
        width={'100%'} 
        height={'100%'} 
        style={{maxWidth:'100%',maxHeight:'100%',objectFit:'contain',overflowY:"scroll"}}
        src={imageUrl} 
        alt='attachment' 
        />}
    </Dialog>
  )
}

export default ImagePreview;
