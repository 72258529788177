import { PRODUCTION_DATE } from '../../actions/actionType'
import dayjs from 'dayjs'

const date = new Date()
// const date = dayjs()
date.setHours(0, 0, 0, 0)
const initialState = { day: date }
// const initialState = { day: date }
console.log('initialstate', initialState)

export default function productionDateReducer(
    state = initialState,
    action: any,
) {
    switch (action.type) {
        case PRODUCTION_DATE:
            return {
                ...state,
                day: action.payload,
            }

        default:
            return state
    }
}
