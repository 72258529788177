import React from 'react';
import styles from './ChatNavigation.module.css';
import { IconButton, Tooltip } from '@mui/material';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import buttonStyles from '../../../../Styles/component/ButtonStyle.module.css';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

interface ChatNavigationProps {
  navigationClick: any,
  count?: number,
  children?:React.ReactNode,
  label?:string
}

const ChatNavigation = (props:ChatNavigationProps) => {
  const { navigationClick, count, children, label } = props;
  return (
    <div className={styles.chatHeader}>
         <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
         <Tooltip title="Back">
              <IconButton className={buttonStyles.listbutton} onClick={navigationClick}>
                  <ArrowBackOutlinedIcon style={{ color: '#fff', fontWeight: 'lighter'}}/>
              </IconButton>
              </Tooltip>
              {count ? <span className={typoStyles.typoRobotoRegoularprimary116}>{`Chat (${count})`}</span>:
              <span className={typoStyles.typoRobotoRegoularprimary116}>{label ? label :`Chat`}</span>}
          </div>
          {children}
      </div>
  )
}

export default ChatNavigation;
