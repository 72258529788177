import { FC } from 'react';
import styles from './ReadOnlyField.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';
import TextFormField from '../TextField';

interface ReadOnlyFieldProps {
  name?: string,
  value?: any,
}

const ReadOnlyField: FC<ReadOnlyFieldProps> = ({ name, value }) => {
    return (
        <div className={styles.ReadOnlyField}>
            <span className={typoStyles.typoRobotoBoldprimary314}>
                {name}
            </span>
            <TextFormField
                name="mrn"
                placeholder='Type here...'
                value={value}
            />
        </div>
    )
};

export default ReadOnlyField;
