import { Button } from '@mui/material';
import React, { FC } from 'react';
import styles from './InvalidMessage.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';
interface InvalidMessageProps {
  message: string,
  onclick: () => void;
}

const InvalidMessage: FC<InvalidMessageProps> = ({ message, onclick }) => (
  <div className={styles.InvalidMessage}>
    <div>
      <span className={typoStyles.typoRobotoMediumprimary721624}>{"Upload Error!"}</span>
    </div>
    <div>
      <span className={[`${typoStyles.typoRobotoRedoularprimary601624} ${styles.errMessage}`].join(' ')}>{message}</span>
    </div>
    <div>
      <Button onClick={() => onclick()} className={styles.okbtn}><span className={typoStyles.typoRobotoRegoularprimary214}>{"OK"}</span></Button>
    </div>
  </div>
);

export default InvalidMessage;
