import { Button, MenuItem, Typography, Divider, styled } from '@mui/material'
import Dropdown from '../../../../Components/Dropdown'
import buttonStyles from '../../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import styles from './TaskStatusChange.module.css'
import { ViewTaskProps } from '../../../../Services/taskManagerService/viewTaskService'
import TaskdrawerHeader from '../../../../Components/TaskdrawerHeader/TaskdrawerHeader'
import statusStyles from '../../../../Styles/component/StatusLabel.module.css'
import {
    statusLabel,
    statusTagMenuItem,
} from '../../../../Services/utils/constant'
import { useTaskStatusUpdate } from '../../../../Services/taskManagerService/statusUpdateService'

const StatusTypography = styled(Typography)({
    padding: '3px 8px 3px 8px',
})

export default function TaskStatusChange(props: ViewTaskProps) {
    const {
        taskName,
        statusFlag1,
        statusFlag,
        handleChange,
        statusObj,
        status,
        saveStatus,
    } = useTaskStatusUpdate(props)

    return (
        <div>
            <TaskdrawerHeader
                title={taskName}
                onclick={() => {
                    if (props.menuHandleCloseClick) {
                        props.menuHandleCloseClick()
                    }
                }}
            />
            <Divider />
            <div className={styles.rootBox}>
                <div className={styles.LabelOneBox}>
                    <Typography className={typoStyles.typoRobotoRegoular14}>
                        {'Change Status'}
                    </Typography>
                </div>
                <div className={styles.LabelTwoBox}>
                    <Typography className={typoStyles.typoRobotoitalic14}>
                        {'Current Status'}
                    </Typography>
                    <div className={statusStyles[`${statusObj?.divPrimary}`]}>
                        <StatusTypography
                            className={typoStyles[`${statusObj?.typography}`]}
                        >
                            {statusLabel(statusObj?.key)}
                        </StatusTypography>
                    </div>
                </div>
                <div className={styles.LabelThreeBox}>
                    <Typography className={typoStyles.typoRobotoRegoular14}>
                        {'Change Status'}
                    </Typography>
                </div>
                <Dropdown
                    disable={statusFlag1}
                    handleChange={handleChange}
                    value={status}
                >
                    {statusTagMenuItem?.map(
                        ({ key, label, id, divPrimary, typography }) => (
                            <MenuItem
                                sx={{ padding: '11px' }}
                                hidden={key === status ? true : false}
                                key={id}
                                value={key}
                            >
                                <div className={statusStyles[`${divPrimary}`]}>
                                    <StatusTypography
                                        className={typoStyles[`${typography}`]}
                                    >
                                        {label}
                                    </StatusTypography>
                                </div>
                            </MenuItem>
                        ),
                    )}
                </Dropdown>
                <div className={styles.DivBTNBox}>
                    {statusFlag && (
                        <Button
                            className={buttonStyles.primarybutton}
                            onClick={() => saveStatus()}
                        >
                            <Typography
                                className={
                                    typoStyles.typoRobotoMediumprimary214
                                }
                            >
                                {'Save'}
                            </Typography>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
