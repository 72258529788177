import api from './index'

export const getEncounters = async (
    providerId: string,
    appointmentId: string,
) => {
    try {
        const payload = await api.get(
            `task/encounters/${providerId}/${appointmentId}`,
        )
        if (payload) return payload
    } catch (error) {
        return error
    }
}
export const getPatients = (providerId: string, appointmentDate: any) =>
    api.handleApiRequest(() =>
        api.post(`task/patients`, { providerId: providerId, appointmentDate }),
    )
export const getTasklist = (
    pageSize: number,
    pageNumber: number,
    sortingObj: any,
    startDate: Date,
    endDate: Date,
    filters?: any,
) =>
    api.handleApiRequest(() =>
        api.post(`task/list`, {
            pageSize,
            pageNumber,
            sortingObj,
            startDate,
            endDate,
            filters,
        }),
    )
export const apiReassignSubmit = (id: string, auditorId: string) =>
    api.handleApiRequest(() => api.post(`task/assign`, { id, auditorId }))
export const statusChange = (id: string, data: any) =>
    api.handleApiRequest(() =>
        api.put(`task/update-status `, { ...data, id: id }),
    )
export const updateTask = (data: any) =>
    api.handleApiRequest(() => api.put(`task/edit`, data))
export const addTask = (data: any) =>
    api.handleApiRequest(() => api.post('task/add', data))
export const getAuditors = () =>
    api.handleApiRequest(() => api.get('auditor/getUsers'))
export const getManagers = () =>
    api.handleApiRequest(() => api.get('auditor/managers'))
export const getProviders = () =>
    api.handleApiRequest(() => api.get('task/providers'))
export const apiClaimSubmit = (data: any) =>
    api.handleApiRequest(() => api.post(`task/claim`, { id: data }))
export const getChatbyFilter = (data: any) =>
    api.handleApiRequest(() => api.post(`task/filter`, data))
export const taskSearch = (data: any) =>
    api.handleApiRequest(() => api.post(`task/taskSearch`, data))
export const taskSearchbyField = (data: any) =>
    api.handleApiRequest(() => api.post(`task/taskSearchByKey`, data))
export const getTask = (data: any) =>
    api.handleApiRequest(() => api.post('task/get-task', { id: data }))
export const getShifts = (start: string, end: string) =>
    api.handleApiRequest(() => api.get('shifts?start=' + start + '&end=' + end))
export const addShift = (start: string, end: string) =>
    api.handleApiRequest(() => api.post('shifts', { start: start, end: end }))
export const updateShift = (id: string, start: string, end: string) =>
    api.handleApiRequest(() =>
        api.put('shifts', { shiftId: id, start: start, end: end }),
    )
export const deleteShift = (id: string) =>
    api.handleApiRequest(() => api.del('shifts?shiftId=' + id, null))
