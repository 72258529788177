import React from "react";
import { ReactComponent as CrossIcon } from '../../../../Assests/Icons/Cross icon (4).svg';
import { ReactComponent as PauseIcon } from '../../../../Assests/Icons/Pause icon.svg';
import { ReactComponent as ForwardIcon } from '../../../../Assests/Icons/Fast forward.svg';
import { ReactComponent as BackwardIcon } from '../../../../Assests/Icons/Fast forward (1).svg';
import { ReactComponent as PlayIcon } from '../../../../Assests/Icons/Icons svg/Play icon.svg';
import { IconButton, Slider, Tooltip } from '@mui/material';
import { ReactComponent as FastForwardIcon } from '../../../../Assests/Icons/Forward icon.svg';
import { ReactComponent as FastBackwardIcon } from '../../../../Assests/Icons/Backward icon.svg';
import styles from './ViewAudioPlayer.module.css';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import { useAudioPlayer } from "./AudioService"


interface ViewAudioPlayerProps {
    audioUrl: any;
    audioFilename: any;
    toogleAudio: any
   
}

const ViewAudioPlayer: React.FC<ViewAudioPlayerProps> = ({ audioUrl, audioFilename, toogleAudio, }) => {

    const { audioref, isPlaying, progress, OpenPlay, handleForward, fastFarward, handleBackward, fastbackward, handleProgress, onTimeUpdate, getTime, onProgress, onEnded, } = useAudioPlayer({ audioUrl, });
    
    return (
        <div className={styles.AudioPlayer}>
                <Tooltip title={"Close Player"}>

            <IconButton onClick={toogleAudio} className={styles.cross_Btn}>
                <CrossIcon className={styles.crossIcon} />
            </IconButton>
          </Tooltip>
             <div className={styles.audio_root}>
                <div className={styles.name_root}>
                    <span className={typoStyles.typoRobotoNormalprimary121621}>{audioFilename}</span>
                </div>
                <Slider
                    min={0}
                    max={100}
                    value={progress}
                    onChange={handleProgress}
                   
                    aria-label="Song"
                    sx={{
                        width: '100%',
                        color: '#D3D3D3',
                        height: '8px',
                        paddingTop: '10px',
                        paddingBottom: '0px',
                        '& .MuiSlider-track': {
                            border: 'none',
                            color: '#427BE0',
                        },
                        '& .MuiSlider-thumb': {
                            display: 'none',
                        },
                    }}
                />
                <div className={styles.sliderTimer}>
                    <span className={[`${styles.curTime} ${typoStyles.typoRobotoRegularprimary121417}`].join(" ")}>
                        {getTime(audioref?.current?.currentTime || 0.0)}
                    </span>
                    <span className={[`${styles.durTime} ${typoStyles.typoRobotoRegularprimary121417}`].join(" ")}>
                        {getTime(audioref?.current?.duration || 0.0)}
                    </span>
                </div>
                <div className={styles.control_btn_root}>
                <Tooltip title={"Fast Backward"}>
                    <IconButton disableRipple className={styles.control_btn} onClick={fastbackward}>
                        <FastBackwardIcon />
                    </IconButton>
                    </Tooltip>
                <Tooltip title={"Backward"}>
                    <IconButton disableRipple className={styles.control_btn} onClick={handleBackward}>
                        <ForwardIcon />
                    </IconButton>
                    </Tooltip>
                <Tooltip title={!isPlaying? "Play": "Pause"}>
                    <IconButton disableRipple className={styles.control_btn} onClick={OpenPlay}>
                        {isPlaying ? <PauseIcon className={styles.PlayIcon} /> : <PlayIcon className={styles.PlayIcon} />}
                    </IconButton>
                    </Tooltip>
                <Tooltip title={"Forward"}>
                    <IconButton disableRipple className={styles.control_btn} onClick={handleForward}>
                        <BackwardIcon />
                    </IconButton>
                    </Tooltip>
                <Tooltip title={"Fast Forward"}>
                    <IconButton disableRipple className={styles.control_btn} onClick={fastFarward}>
                        <FastForwardIcon />
                    </IconButton>
                    </Tooltip>
                </div>
            </div>

            {audioUrl && <audio ref={audioref} onEnded={() => onEnded()}  src={audioUrl} onTimeUpdate={onTimeUpdate} onProgress={onProgress} controls={false} />}
        </div>
    );
};

export default ViewAudioPlayer;

