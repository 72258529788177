import React from 'react';
import moment from 'moment';
import styles from './Message.module.css';
import attchmentStyles from './Attachment.module.css';
import { Stack } from '@mui/material';
import UserAvatar from '../../../TaskManager/Component/UserAvatar';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import { timerChstGet } from '../../../../Services/api/messageAPIService';
import AttachmentMessage from '../AttachmentMessage/AttachmentMessage';
import SingleTick from '../../../../Assests/Icons/Single Tick.svg';
import DoubleTick from '../../../../Assests/Icons/Double Tick.svg';
import DoubleBlueTick from '../../../../Assests/Icons/Double Blue Tick.svg';

interface Props {
    userName: string,
    createdBy: any
}
export const UserAva = ({ userName, createdBy }: Props) => {
    return (
        <Stack direction="row" spacing={2} sx={{ alignSelf: "flex-end" }}>
            <UserAvatar name={createdBy}>
                <span className={typoStyles.typoRobotoMediumprimary214}>
                    {userName?.charAt(
                        0,
                    )}
                </span>
            </UserAvatar>
        </Stack>
    )
}
interface Iprops {
    item: any,
    isMine: any,
    startsSequence: any,
    endsSequence: any,
    showTimestamp: any,
    lastEl: any,
    type: any,
    tooglePreview: any,
    resultstatus: any,

}

export default function Message(props: Iprops) {
    const {
        item,
        isMine,
        startsSequence,
        endsSequence,
        showTimestamp,
        lastEl,
        type,
        tooglePreview,
        resultstatus,

    } = props;
    const mref = React.useRef<any>(null);
    const friendlyTimestamp = moment(item?.createdAt).format('LLLL');

    React.useEffect(() => {
        if (mref?.current && lastEl) {
            mref.current.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "start"
            })

        }
    }, [lastEl, item]);


    const roleName = (name: string) => {
        const rolesname = [
            { key: 'auditor', value: 'Auditor' },
            { key: 'auditormanager', value: 'Manager' },
            { key: 'provider', value: 'Provider' },
            { key: 'auditoradmin', value: 'Admin' }
        ]
        const rolekey = rolesname.find((item) => item?.key === name)
        return rolekey?.value

    }

    const textStyle = isMine ? `${styles.mine}` : '';
    const startsSequenceStyle = startsSequence ? `${styles.start}` : '';
    const endsSequenceStyle = endsSequence ? `${styles.end}` : '';
    const sendertimerStyle = isMine ? `${styles.sendertimer}` : `${styles.receivertimer}`;
    const endTimerStyle = endsSequence ? `${styles.endTimer}` : `${styles.shownSpan}`;
    const attachmentStyle = isMine ? `${attchmentStyles.mine}` : ``;
    const attachmentTimerStyle = isMine ? `${attchmentStyles.sendertimer}` : `${attchmentStyles.receivertimer}`;
    const timer = isMine ? `You, ${moment(item?.createdAt).format('LT')}` : `${item?.userName?.split(' ')[0]} (${roleName(item?.userRole)}), ${moment(item?.createdAt).format('LT')}`;
    const senderWhiteDivStyle = (startsSequence && !isMine) ? `${styles.receiverWhiteDiv}` : `${styles.leftAlignment}`;
    const receiverWhiteDivStyle = (startsSequence && isMine) ? `${styles.senderWhiteDiv}` : `${styles.rightAlignment}`


    let tick = null;

    if (isMine && resultstatus?.status) {
        if (item?.allSeen && item?.doubleTick) {
            tick = <span className={styles.tickstyles}><img src={DoubleBlueTick} alt="DoubleBlueTick" /></span>;
        } else if (!item?.allSeen && item?.doubleTick) {

            tick = <span className={styles.tickstyles}><img src={DoubleTick} alt="DoubleTick" /></span>;
        } else {

            tick = <span className={styles.tickstyles}><img src={SingleTick} alt="SingleTick" /></span>;
        }
    }


    if (type === 'text') {
        return (
            <div ref={mref} className={[
                `${styles.message}`,
                `${textStyle}`,
                `${startsSequenceStyle}`,
                `${endsSequenceStyle}`
            ].join(" ")}>
                {
                    showTimestamp &&
                    <div className={styles.timerDiv}>
                        <span className={typoStyles.typoRobotoBoldprimary6614}> {timerChstGet(item?.createdAt)}</span>
                    </div>
                }

                {item?.isRemoved === "0" ? (
                    <div className={styles.bubblecontainer}>
                        {(startsSequence && !isMine) && <UserAva userName={item?.userName} createdBy={item?.createdBy} />}
                        <div className={senderWhiteDivStyle} />
                        <div style={{ whiteSpace: "pre-line" }} className={`${styles.bubbleremove} ${styles.textcolour}`} title={friendlyTimestamp}>
                            <span className={typoStyles.typoRobotoRegoularprimary781621}>{item?.text}</span>
                        </div>

                        {(startsSequence && isMine) && <UserAva userName={item?.userName} createdBy={item?.createdBy} />}

                        <div className={receiverWhiteDivStyle} />
                    </div>
                ) : (
                    <div className={styles.bubblecontainer}>
                        {(startsSequence && !isMine) && <UserAva userName={item?.userName} createdBy={item?.createdBy} />}
                        {(startsSequence && !isMine) && <div className={styles.receiverColorDiv} />}
                        <div className={senderWhiteDivStyle} />
                        <div style={{ whiteSpace: "pre-line" }} className={styles.bubble} title={friendlyTimestamp}>
                            {item?.text}
                        </div>
                        {(startsSequence && isMine) && <UserAva userName={item?.userName} createdBy={item?.createdBy} />}
                        {(startsSequence && isMine) && <div className={styles.senderColorDiv} />}
                        <div className={receiverWhiteDivStyle} />
                    </div>
                )}

                <span className={[
                    `${typoStyles.typoRobotoRegularprimary661419}`,
                    `${sendertimerStyle}`,
                    `${endTimerStyle}`
                ].join(" ")}>{timer} {tick}
                </span>



            </div>
        );
    } else {
        return (<div ref={mref} className={[
            `${attchmentStyles.attachment}`,
            `${attachmentStyle}`,
        ].join(" ")}>
            {
                showTimestamp &&
                <div className={styles.timerDiv}>
                    <span className={typoStyles.typoRobotoBoldprimary6614}> {timerChstGet(item?.createdAt)}</span>
                </div>
            }
            <div className={attchmentStyles.attachmentContainter}>
                {(!isMine) && <UserAva userName={item?.userName} createdBy={item?.createdBy} />}
                <AttachmentMessage tooglePreview={tooglePreview} key={item?.id} isMine={isMine} item={item} type={item?.type} Filename={item?.attachments?.fileName} />
                {(isMine) && <UserAva userName={item?.userName} createdBy={item?.createdBy} />}
            </div>
            <span className={[
                `${typoStyles.typoRobotoRegularprimary661419}`,
                `${attachmentTimerStyle}`,
            ].join(" ")}>{timer} {tick} </span>

        </div>
        )
    }
}