import styles from './ChatUserAvator.module.css';
import UserListPopper from "../../../../Components/UserListPopper/UserListPopper";

interface IProps {
    data?: any
}
export default function ChatUserAvator(props: IProps) {
    const { data } = props;
    return (
        <div className={styles.rootDiv}>
            <UserListPopper data={data} />
        </div>
    )
}