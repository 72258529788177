import React from 'react';
import styles from './LastConversation.module.css';
import { Divider, List, ListItem, ListItemButton } from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import ChatNavigation from '../ChatNavigation/ChatNavigation';
import ChatButton from '../ChatButton/ChatButton';
import { useLastConversation } from '../../../../Services/chatService/lastConversationService';
import Nochatplaceholder from './Nochatplaceholder.svg';
import { timerGet } from '../../../../Services/api/messageAPIService';
import PatientHolder from '../../../../Components/PatientHolder/PatientHolder';
import UserAvatarMap from '../../../../Components/UserAvatarMap/UserAvatarMap';
import { renderimage, renderunreadimage, typeFileCheck } from '../../../../Services/utils/ImageUtil';
import Badge from '@mui/material/Badge';

interface Props {
  setFlagShowMsg: any,
  taskId: any,
  handleItemClick: any,
  socket: any,
  appointmentId: any,
  patientName: any,
  mrn: any,
  menuHandleCloseClick?:any
}
interface ListitemProps {
  handleItemClick: any,
  item: any,
  userId: any
}
function Listitem(props: ListitemProps) {
  const { handleItemClick, item } = props;
  
  const onClick = React.useCallback(() => {
    handleItemClick(item)
  }, [item]);

 
  return <ListItem disablePadding >
    <ListItemButton sx={{ justifyContent: "space-between" }} onClick={onClick}>
      <div className={[`${styles.toolbar} ${styles.toolbarroot}`].join(' ')}>
        <div className={styles.userDiv} >
          <span className={[`${typoStyles.typoRobotoMediumprimary5718} ${styles.overflw}`].join(' ')}>{item.subjects.name}</span>
         
          {item.latestMessage?.text !== null && item.latestMessage?.text !== undefined ? (
        
            <span style={{ marginTop: "5px" }} className={[item.unreadThreadCount > 0 ? `${typoStyles.typoRobotoMediumprimary121434} ${styles.overflw}` : `${typoStyles.typoRobotoMediumprimary661434} ${styles.overflw}`].join(' ')}>
              {item.latestMessage.text}
            </span>
          ) : item.latestMessage?.attachments !== null ? (
            
            <div style={{ gap: "5px", display: "flex", marginTop: "5px" }}>
              <img
                alt={item.latestMessage?.attachments?.type}
                src={
                  item.unreadThreadCount > 0
                    ? renderunreadimage(item.latestMessage?.attachments?.type)
                    : renderimage(item.latestMessage?.attachments?.type)
                }
                draggable={false}
              />
              <span
                className={
                  item.unreadThreadCount > 0
                    ? typoStyles.typoRobotoRegularprimary631434
                    : typoStyles.typoRobotoRegoularprimary631434
                }
              >
                {item.latestMessage?.attachments?.type !== 'docx' &&
                  item.latestMessage?.attachments?.type !== 'doc' &&
                  item.latestMessage?.attachments?.type !== 'csv' &&
                  item.latestMessage?.attachments?.type !== 'pdf'
                  ? typeFileCheck(item.latestMessage?.attachments?.type)
                  : item.latestMessage?.attachments?.fileName}
              </span>
            </div>
          ) : null}

          <UserAvatarMap userData={item?.userData} />
        </div>
      </div>
      <div className={styles.tagtoolbar}>
        <span>
          <span style={{ paddingRight: '8px' }} className={typoStyles.typoHelveticalNormalprimary6312}>{timerGet(item.latestMessage?.createdAt)}</span>
          <ArrowForwardIosOutlinedIcon className={styles.arrowIcon} />

        </span>
        <span style={{ marginTop: "4px", marginRight: "20px", }} >
          <Badge badgeContent={item.unreadThreadCount} color="primary" />

        </span>
      </div>
    </ListItemButton>
  </ListItem>

}
const LastConversation = (props: Props) => {
  const { setFlagShowMsg, handleItemClick, patientName, mrn, menuHandleCloseClick } = props;
  const { chatList, userId, loader, onScroll, listInnerRef, totalCount } = useLastConversation(props)


  const navigationClick = React.useCallback(() => {
    setFlagShowMsg(null)
      menuHandleCloseClick()
    
  }, []);
  const handleClick = React.useCallback(() => {
   
    setFlagShowMsg(2)
   
  }, []);

  return (
    <div>
      <ChatNavigation navigationClick={navigationClick} count={totalCount} />
      <PatientHolder patientName={patientName} mrn={mrn} />
      {!loader ? <List className={styles.list} ref={listInnerRef} onScroll={onScroll}>
        {chatList?.map((item: any) => (<div key={item.id} >
          <Listitem item={item} key={item.id} handleItemClick={handleItemClick} userId={userId} />
          <Divider />
        </div>
        ))}</List> :
        <div className={styles.lastConversationCenter}>
          <img src={Nochatplaceholder} alt='Nochatplaceholder' />
          <span style={{ marginTop: "12px" }} className={typoStyles.typoRobotoBoldprimary691621}>{"No Message"}</span>
        </div>}
      <ChatButton disable={false} handleClick={handleClick} label='Start a new chat' />
    </div>
  )
}
export default LastConversation;

