import React from 'react';
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider';
import { addComment, addReply, editReply, getCommentlist } from '../api/commentAPIService';

type CommentProps = {
    task?: any,
    auditorList?: any,
    updatedTaskList?: any
}

type CommentsProps = {
    text: string,
    createdBy: any,
    updatedBy: string,
    createdAt: string,
    updatedAt: string,
    attachments: string | null,
    _id: string,
    id: string,
    reply: any,
}
export function useComment({ updatedTaskList, task }: CommentProps) {
    const { snackbarSetting } = React.useContext(SnackbarContext);
    const { loaderSetting } = React.useContext(LoaderGFContext);
    const [comment, setComment] = React.useState<CommentsProps[]>();
    const [commentReplyId, setCommentReplyId] = React.useState<number | null>(null);
    const [newText, setText] = React.useState('');
    const [replyText, setReplyText] = React.useState('');
    const [replyFlag, setReplyFlag] = React.useState(false);
    const [editReplyId, setEditReplyId] = React.useState('');
    const [taskState, setTask] = React.useState<any>(null);

    React.useEffect(() => {
        if (task) {
            setTask(task)
        }
    }, []);

    const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setText(e.target.value.replace(/(\*|[\uE000-\uF8FF]|\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, ""));
    }
    const handleChangereply = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setReplyText(e.target.value.replace(/(\*|[\uE000-\uF8FF]|\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, ""))
    }
    const submitReply = async (commentId: string) => {
        loaderSetting(true)
        const obj = {
            taskId: taskState?.id,
            commentId,
            text: replyText
        }
        const result: any = await addReply(obj);
            if (result?.status) {
                snackbarSetting(true, result?.message, "success")
                await getAllComment(taskState?.id);
            } else {
                snackbarSetting(true, result?.message, "error")
            }
            setEditReplyId('')
            setText('');
            setReplyText('')
            setCommentReplyId(null)
            loaderSetting(false);
            setReplyFlag(false)
    }
    const submitEditReply = async () => {
        loaderSetting(true)
        const obj = {
            taskId: taskState?.id,
            replyId: editReplyId,
            text: replyText
        }
        const result: any = await editReply(obj);
            if (result?.status) {
                snackbarSetting(true, result?.message, "success")
                await getAllComment(taskState?.id);
            } else {
                snackbarSetting(true, result?.message, "error")
            }
            setEditReplyId('')
            setText('');
            setReplyText('')
            setCommentReplyId(null)
            loaderSetting(false);
            setReplyFlag(false)
    }
    const submitComment = async () => {
        loaderSetting(true);
        const result: any = await addComment({taskId: taskState?.id, text: newText });         
        if (result?.status) {
            setEditReplyId('')
            setText('');
            setReplyText('')
            await updatedTaskList(result?.taskData);
            await getAllComment(taskState?.id);
            setTask(result?.taskData)
            snackbarSetting(true, result?.message, "success");
            setCommentReplyId(null)
            setReplyFlag(false)
        } else {
            snackbarSetting(true, result?.message, "error")
        }
        loaderSetting(false)
    }
    React.useEffect(() => {
        async function getComments() {
            if (task) {
                loaderSetting(true)
                await getAllComment(task?.id);
            }
        }
        getComments()
    }, []);

    const getAllComment = async (taskId: string) => {        
        const result: any = await getCommentlist(taskId);
        if (result?.status && result.commentsData) {
            setComment(result.commentsData);
        }else{
            setComment([]);
        }
        loaderSetting(false);
    }
    return { taskState, setEditReplyId, setText, setReplyText, commentReplyId, setCommentReplyId, replyFlag, setReplyFlag, submitEditReply, submitReply, handleChangereply, replyText, comment, handleChangeComment, newText, submitComment };
}

