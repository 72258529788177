import React from 'react'
import { Autocomplete, MenuItem, TextField, Popper } from '@mui/material'
import UserComponent from '../../Pages/UserComponent/UserComponent'

interface SearchDropdownTextFieldProps {
    options: any[]
    onChange?: any
    searchQuery: any
}

const SearchDropdownTextField: React.FC<SearchDropdownTextFieldProps> = ({
    options,
    onChange,
    searchQuery,
}) => {
    const getOptionLabel = (option: any) =>
        option.patientName ? option.patientName : ''

    return (
        <div>
            <Autocomplete
                id="combo-box-demo"
                options={options}
                value={searchQuery}
                onChange={onChange}
                renderOption={(props, option) => {
                    return (
                        <MenuItem
                            {...props}
                            key={option.appointmentId}
                            value={option.appointmentId}
                        >
                            <UserComponent
                                detail={true}
                                auditorName={option.patientName}
                                auditorId={option.appointmentId}
                            />
                        </MenuItem>
                    )
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Select"
                        variant="outlined"
                        sx={{
                            width: '195px',
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                padding: '0px 10px 0px 10px',
                            },
                        }}
                    />
                )}
                getOptionLabel={getOptionLabel}
                PopperComponent={(props) => (
                    <Popper
                        {...props}
                        style={{
                            zIndex: 1500,
                            width: '221px',
                            boxShadow:
                                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                            borderRadius: '4px',
                        }}
                    >
                        {props.children}
                    </Popper>
                )}
            />
        </div>
    )
}

export default SearchDropdownTextField
