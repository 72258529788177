import dayjs from 'dayjs'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
export type taskProps = {
    type: string
    auditorName: string
    providerName: string
    auditor: [
        {
            email: string
            firstName: string
            id: string
            lastName: string
            _id: string
        },
    ]
    auditorId: string
    createdAt: string
    encounterId: string
    endDate: string
    id: string
    location: string
    mrn: string
    orgTid: string
    patientAccount: string
    patientGender: string
    appointmentId: string
    patientName: string
    patientdob: string
    provider: [
        {
            email: string
            firstName: string
            id: string
            lastName: string
            role: string
            userName: string
            _id: string
        },
    ]
    providerId: string
    speciality: string
    startDate: string
    status: string
    taskName: string
    updatedAt: string
    __v: string
}
export const addinitState = {
    taskName: '',
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    auditorId: '',
    orgTid: '',
    location: '',
    providerId: '',
    mrn: '',
    type: '',
    encounter: [],
    appointmentId: '',
    patientName: '',
    patientAccount: '',
    patientdob: dayjs(new Date()),
    patientGender: '',
    speciality: '',
    comment: '',
    status: 'to do',
    scheduleId: '',
}
export const errorTaskFlag = {
    auditorIdError: false,
    orgTidError: false,
    providerIdError: false,
    patientIdError: false,
    specialityError: false,
    taskNameError: false,
}
export const auditorState = {
    mainSpecialityId: '',
    secondarySpecialityId: '',
    managerId: '',
    providerId: '',
    auditorId: '',
}
export const errorAuditorFlag = {
    mainSpecialityError: false,
    providerIdError: false,
    managerIdError: false,
}
export const auditorValidate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setError: {
        (
            value: React.SetStateAction<{
                mainSpecialityIdError: boolean
                providerIdError: boolean
                managerIdError: boolean
            }>,
        ): void
        (arg0: any): void
    },
    error: {
        managerIdError: boolean
        mainSpecialityIdError: boolean
        providerIdError: boolean
    },
) => {
    switch (e.target.name) {
        case 'mainSpecialityId':
            if (e.target.value === '') {
                setError({ ...error, taskNameError: true })
            } else {
                setError({ ...error, taskNameError: false })
            }
            break
        case 'managerId':
            if (e.target.value === '') {
                setError({ ...error, auditorIdError: true })
            } else {
                setError({ ...error, auditorIdError: false })
            }
            break
        case 'providerId':
            if (e.target.value === '') {
                setError({ ...error, providerIdError: true })
            } else {
                setError({ ...error, providerIdError: false })
            }
            break
        default:
            break
    }
}
export const validate = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setError: {
        (
            value: React.SetStateAction<{
                auditorIdError: boolean
                taskNameError: boolean
                orgTidError: boolean
                providerIdError: boolean
                patientIdError: boolean
                specialityError: boolean
            }>,
        ): void
        (arg0: any): void
    },
    error: {
        auditorIdError: boolean
        orgTidError: boolean
        providerIdError: boolean
        patientIdError: boolean
        specialityError: boolean
    },
) => {
    switch (e.target.name) {
        case 'taskName':
            if (e.target.value === '') {
                setError({ ...error, taskNameError: true })
            } else {
                setError({ ...error, taskNameError: false })
            }
            break
        case 'auditorId':
            if (e.target.value === '') {
                setError({ ...error, auditorIdError: true })
            } else {
                setError({ ...error, auditorIdError: false })
            }
            break
        case 'orgTid':
            if (e.target.value === '') {
                setError({ ...error, orgTidError: true })
            } else {
                setError({ ...error, orgTidError: false })
            }
            break
        case 'providerId':
            if (e.target.value === '') {
                setError({ ...error, providerIdError: true })
            } else {
                setError({ ...error, providerIdError: false })
            }
            break
        case 'appointmentId':
            if (e.target.value === '') {
                setError({ ...error, patientIdError: true })
            } else {
                setError({ ...error, patientIdError: false })
            }
            break
        case 'speciality':
            if (e.target.value === '') {
                setError({ ...error, specialityError: true })
            } else {
                setError({ ...error, specialityError: false })
            }
            break
        default:
            break
    }
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#FFFFFF',
        boxShadow: '0px 3px 6px 2px #00000029',
        borderRadius: '13px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: "12px 20px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
        fontSize: '24px',
        marginTop: '2px',
    },
}))
