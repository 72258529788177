import React from 'react';
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider';
import { apiClaimSubmit } from '../api/taskAPIService';

type markHasClaimProps = {
    updatedTaskList?: (value : boolean) => void,
}
export function useMarkClaim({ updatedTaskList }: markHasClaimProps) {
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext)

    const markClaimSubmitted =async (id: string) => {
        loaderSetting(true)
        const result = await apiClaimSubmit(id);
        if(result !== null && updatedTaskList){
            if(result?.status){
                updatedTaskList(result?.task);
            }else{
                snackbarSetting(true, result?.message, "error")
                loaderSetting(false)
            }
        }else{
            loaderSetting(false);
        }
    }
    return { markClaimSubmitted };
}