import React from "react";
import dayjs, { Dayjs } from 'dayjs'
import { addinitState, errorTaskFlag, validate } from "./common";
import { getAuditors, getEncounters, getPatients, getProviders, addTask } from '../api/taskAPIService'
import { PatientlistProps, ProviderlistProps, AuditorlistProps, IdProps, PatientIdProps } from "../types/taskmanagertypes";
import { LoaderGFContext } from "../../Components/Loader/LoaderProvider";
import { SnackbarContext } from "../../Components/Snackbar/SnackbarProvider";

type addTaskProps = {
    updatedTaskList: (value: boolean) => void,
}
export function useAddTask({ updatedTaskList }: addTaskProps) {
    const [states, setStates] = React.useState(addinitState);
    const [error, setError] = React.useState(errorTaskFlag);
    const [patientList, setPatientList] = React.useState<PatientlistProps[]>([]);
    const [providerList, setProviderList] = React.useState<ProviderlistProps[]>([]);
    const [auditorList, setAuditorList] = React.useState<AuditorlistProps[]>([]);
    const [open, setOpen] = React.useState(false);
    const [showView, setView] = React.useState(false);
    const [showEdit, setEdit] = React.useState(false);
    const [tableValue, setTableValue] = React.useState(0);
    const [appointmentDate, setAppointmentDate] = React.useState<Dayjs | null>(dayjs(new Date()));
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTableValue(newValue);
    };
    const [patientName, setPatientName] = React.useState('');

    const { snackbarSetting } = React.useContext(SnackbarContext)

    const { loaderSetting } = React.useContext(LoaderGFContext)

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        validate(e, setError, error);
        setStates({ ...states, [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9 ]/g,"").replace(/(\*|[\uE000-\uF8FF]|\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,"")});
    };
    const handleorgChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        validate(e, setError, error);
        setStates({ ...states, [e.target.name]: e.target.value.replace(/\D/g, '')});
    };
    const handleStartChange = (newValue: Dayjs | null) => {
        const selected = newValue?.format('YYYY-MM-DD');
        const maxdate = states.endDate?.format('YYYY-MM-DD');
        if (newValue && selected && (new Date(selected) <= new Date(maxdate))) {
            setStates({ ...states, startDate: newValue })
        }else{
            snackbarSetting(true, "Start date must be lesser than end date!", "success")
        }
    }
    const handleEndChange = (newValue: Dayjs | null) => {  
        const selected = newValue?.format('YYYY-MM-DD');
        const maxdate = states.startDate?.format('YYYY-MM-DD');
        if (newValue && selected &&  (new Date(selected) >= new Date(maxdate))) {
            setStates({ ...states, endDate: newValue })
        }else{
            snackbarSetting(true, "End date must be greater than start date!", "success")
        }
    }
    const handlePatient = async (value: string) => {        
        loaderSetting(true)
        const patient = patientList?.find((item: PatientIdProps) => item.appointmentId === value);  
        if (patient) {
            await getEncounters(states?.providerId, patient?.appointmentId).then((data: any) => {
                if(data){
                    loaderSetting(false)
                    setStates({...states, ...patient,patientdob: dayjs(patient?.patientdob), encounter: data?.encounter, type: patient?.type})
                }else{
                    loaderSetting(false)
                }
            }).catch(err => {
                console.log(err);
                loaderSetting(false)
            });
        }
    }
    const handleProvider = async (value: string) => {
        const provider = providerList?.find((item: IdProps) => item.id === value);
        if (provider?.id) {
            setStates({ ...states, providerId: provider?.id });
            handlePatientList(provider?.id, appointmentDate)
        }
    }
    const handlePatientList = async (providerId: any, appointmentDate: any) => {        
        loaderSetting(true)       
        await getPatients(providerId, appointmentDate).then((result: any) => {
            loaderSetting(false)                   
            if(result?.patients?.length >= 1){                    
                loaderSetting(false)
                setPatientList(result.patients)
                setStates({ ...states,
                    providerId: providerId,
                    appointmentId:'',
                    patientName:'', 
                    location:'',
                    mrn:'',
                    patientAccount:'',
                    patientGender:'',
                    patientdob:dayjs(new Date()),
                    scheduleId:'',
                    type:''
                })
            }else{
                setPatientList([])
                setStates({ ...states,
                    providerId: providerId,
                    appointmentId:'',
                    patientName:'', 
                    location:'',
                    mrn:'',
                    patientAccount:'',
                    patientGender:'',
                    patientdob:dayjs(new Date()),
                    scheduleId:'',
                    type:''
                })
                loaderSetting(false)
            }
        }).catch(err => {
            console.log(err);
            setPatientList([])
            setStates({ ...states,
                providerId: providerId,
                appointmentId:'',
                patientName:'', 
                location:'',
                mrn:'',
                patientAccount:'',
                patientGender:'',
                patientdob:dayjs(new Date()),
                scheduleId:'',
                type:''
            })
        })
    }
    const handleAppointmentDateChange = (newValue: Dayjs | null) => {  
        setAppointmentDate(newValue);
        handlePatientList(states?.providerId, newValue)
        setStates({ ...states,
            appointmentId:'',
            patientName:'', 
            location:'',
            mrn:'',
            patientAccount:'',
            patientGender:'',
            patientdob:dayjs(new Date()),
            scheduleId:'',
            type:''
        })
    }
    const toggleEdit = () => {
        setEdit(!showEdit)
    }
    const handleDropChange = (value: string, name: string) => {
        setStates({ ...states, [name]: value });
    }
    const handleSubmit = async () => {
        loaderSetting(true)
        await addTask(states).then((res: any) =>{                        
            if (res?.status) {                
                snackbarSetting(true, res?.message, 'success')
                setStates(res?.task);
                setView(true);
                updatedTaskList(res?.task);
                loaderSetting(false)
            }else{
                snackbarSetting(true, res?.message, 'error')
                loaderSetting(false)
            }
        });
        setOpen(true)
    }
    React.useEffect(() => {
        async function initCall() {
            await getAuditors().then((auditorsRes: any)=> {
                if (auditorsRes) {
                    setAuditorList(auditorsRes.users);
                }
            });
            await getProviders().then((providersRes: any) => {
                if (providersRes) {
                    setProviderList(providersRes.providerList)
                }
            });
        }
        initCall();
    }, [])
    function checkAllField() {
        if (states.taskName.replace(/\s/g, '').length !== 0 && states.orgTid.replace(/\s/g, '').length !== 0 && states.speciality.replace(/\s/g, '').length !== 0 &&states.auditorId !== '' && states.orgTid !== '' && states.providerId !== '' && states.providerId !== '' && states.speciality !== '' && states.appointmentId !== '') {
            return false;
        } else {
            return true;
        }
    }
    const handleSearch = (event: React.ChangeEvent<any>, value: any) => {
        if (value) {
            setPatientName(value);
            handlePatient(value.appointmentId);
        } else {
            setPatientName('');
            setStates({ ...states,
                appointmentId:'',
                patientName:'', 
                location:'',
                mrn:'',
                patientAccount:'',
                patientGender:'',
                patientdob:dayjs(new Date()),
                scheduleId:'',
                type:''
            })
        }
      };
    return { patientName, handleSearch, appointmentDate, handleAppointmentDateChange, handleorgChange, showEdit, toggleEdit, handleTabChange, tableValue, showView, open, setOpen, error, checkAllField, patientList, auditorList, providerList, handleChange, handleSubmit, handleDropChange, handleProvider, handleEndChange, handleStartChange, states };
}