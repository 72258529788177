import React from "react";
import { readFileFromURL } from "../FileService/fileread";
import { LoaderGFContext } from "../../Components/Loader/LoaderProvider";
import { useSelector } from "react-redux";

interface PDFPreviewProps {
    item: any
}
export function usePdfPreview({ item }: PDFPreviewProps) {
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [pdfUrl, setPdfUrl] = React.useState<any>(null);
    const [pdfLoader, setPdfLoader] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function initiateAPI() {
            setPdfLoader(true);
            loaderSetting(true)
            const option = {
                method: 'GET',
                mode: 'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }
            const result = await readFileFromURL(item.attachments.relativePath, option);
            if (!result) return;
            setPdfUrl(result);
            loaderSetting(false)
        }
        initiateAPI();
    }, []);

    const onloadimage = () => {
        URL.revokeObjectURL(pdfUrl);
        setPdfLoader(false);
        loaderSetting(false);
    }
    return { pdfUrl, pdfLoader, onloadimage };
}