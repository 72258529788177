import React from 'react';
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider';
import { getSubjectdata, removeSubjectdata } from '../api/messageAPIService';

export function useSubject() {

    const [subjects, setSubject] = React.useState<any>([]);
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [subjectToRemove, setSubjectToRemove] = React.useState<string | null>(null);
    const { snackbarSetting } = React.useContext(SnackbarContext)
  
    React.useEffect(() => {
        async function getSubjects() {
            loaderSetting(true)
            const data = await getSubjectdata();
            loaderSetting(false)
            if (data) {
                setSubject(data.data)
            
            }
        }
        getSubjects()
    }, [])

    const handleRemoveSubject = async (subjectId: any) => {
        loaderSetting(true)
        const bodyParams = {
            subjectId: subjectId,


        };
        const data = await removeSubjectdata(bodyParams)
    
        if (data.status) {
            snackbarSetting(true, data?.message, "success")
            const updatedSubjects = subjects.filter((item: any) => item.id !== subjectId);
            loaderSetting(false)
            setSubject(updatedSubjects);
            setOpenConfirm(false)
        }
        else{
            snackbarSetting(true, data?.message, "error")
        }
    };

    return { subjects,  handleRemoveSubject, openConfirm, setOpenConfirm, subjectToRemove, setSubjectToRemove }
}