
import React from 'react';
import { fileCount, searchFiles } from '../api/filecountAPIService';
import { mediaReducer } from '../reducer/mediaReducer';
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { readFileFromURL } from '../FileService/fileread';
import { typeimageCheck } from '../utils/ImageUtil';
import imagePlaceHolder from '../../Assests/Icons/Image thumbnail.png';
import { useSelector } from 'react-redux';
interface useMediaProps {
  providerId?: any;
  subjectData?: any;
  appointmentId?: any;
}

export function useMedia({ providerId, subjectData, appointmentId }: useMediaProps) {
  const rootref = React.useRef<any>(null);
  const { loaderSetting } = React.useContext(LoaderGFContext);
  const [pagination, setPagination] = React.useState({
    pageSize: 9,
    pageNumber: 1,
  });
  const [mediaData, setMediaData] = React.useReducer(mediaReducer, []);
  const [loader, setLoader] = React.useState(false);
  const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);

  const [thumbnailUrls, setThumbnailUrls] = React.useState<string[]>([]);
  const [attachmentCount, setAttachmentCount] = React.useState({
    mediaCount: null,
    fileCount: null,
  });
  const [searchValue, setSearchValue] = React.useState('');

  const onScroll = () => {
    if (rootref.current) {
      const { scrollTop, scrollHeight, clientHeight } = rootref.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPagination((prevState) => ({
          ...prevState,
          pageNumber: prevState.pageNumber + 1,
        }));
      }
    }
  };

  React.useEffect(() => {
    const fetchMediaData = async () => {
      loaderSetting(true);
      getFiles()
      loaderSetting(false);
    };

    fetchMediaData();
  }, [pagination.pageNumber]);

  const getFiles = async () => {
    const bodyParams = {
      appointmentId: appointmentId,
      providerId: providerId,
      subjectId: subjectData,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
      fileType: 'media',
      count: 'false',
    };
    const response = (await fileCount(bodyParams)) as { details: any, mediaCount: any, fileCount: any };
    setAttachmentCount({ mediaCount: response?.mediaCount, fileCount: response?.fileCount })
    if (response?.details?.length) {
      setMediaData({ type: 'add', payload: response.details });
      setLoader(true);
    }

  }
  React.useEffect(() => {
    const fetchThumbnailUrls = async () => {
      if (userToken && mediaData.length) {        
        const thumbnailUrls: string[] = [];
        for (const media of mediaData) {
          if (typeimageCheck(media?.attachments?.type?.toLowerCase())) {
            const filepath = media?.attachments?.thumbnailObj?.relativePath;            
            const option = {
              method: 'GET',
              mode: 'cors' as RequestMode,
              headers: new Headers({
                Authorization: 'Bearer ' + userToken,
              }),
            };
            const result = await readFileFromURL(filepath, option);
            if (result) {
              thumbnailUrls.push(result.toString());
            } else {
              thumbnailUrls.push('');
            }
          } else {
            thumbnailUrls.push('');
          }
        }
        setThumbnailUrls(thumbnailUrls);
      }
    };

    fetchThumbnailUrls();
  }, [mediaData, userToken, loader]);

  const onloadimage = () => {
    URL.revokeObjectURL(mediaData);
  }
  const onErrolLoad = (e: any) => {
    e.target.src = imagePlaceHolder;
  }

  const handleFileSearch = async (e: any) => {

    setSearchValue(e.target.value);
    const bodyParams = {
      appointmentId: appointmentId,
      providerId: providerId,
      subjectId: subjectData,
      fileType: 'media',
      searchValue: e.target.value
    };
    if (searchValue !== '') {
      const response = await searchFiles(bodyParams) as { payload: any };      
      if (response?.payload?.length) {        
        setLoader(true)
        const arrY: any = [];        
        response?.payload[0]?.attachments?.map((item: any) =>{
          const data = {
            attachments: item,
            createdAt: response?.payload[0].createdAt,
            id: response?.payload[0].id
          };
          arrY.push(data)
        } )
        setMediaData({ type: 'addNew', payload: arrY });
      } else {
        setLoader(false)
      }
    } else {
      getFiles()
    }
  }
  return { searchValue, handleFileSearch, attachmentCount, onScroll, rootref, mediaData, loader, thumbnailUrls, onloadimage, onErrolLoad };
}

