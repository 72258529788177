import React from "react";
import { readFileFromURL } from "../FileService/fileread";
import BrokenVideo from "../../Assests/Icons/Broken Video.svg";
import { useSelector } from "react-redux";

interface VideoPreviewProps {
    item: any
}

export function useVideoPlayer({ item }: VideoPreviewProps) {
    const videoref = React.useRef<any>(null);
    const sourceref = React.useRef<any>(null);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const [isPlaying, setIsPlaying] = React.useState<boolean>(true);
    const [progress, setProgress] = React.useState<number>(0);
    const [onMouse, setOnMouse] = React.useState<boolean>(true);
    const [volume, setVolume] = React.useState<number>(0);
    const [videoLoader, setVideoLoader] = React.useState<boolean>(true);
    const [videoUrl, setVideoUrl] = React.useState<any>(null);
    const [mute, setMute] = React.useState<boolean>(true);
    const [inital, setInitial] = React.useState<boolean>(true);

    React.useEffect(() => {
        async function initiateAPI() {
            setVideoLoader(true);
            const option = {
                method: 'GET',
                mode: 'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }
            const result = await readFileFromURL(item.attachments.relativePath, option);
            if (!result) return;
            setVideoUrl(result);
        }
        initiateAPI();
    }, []);
    const onFullScreenChange = () => {
        if(videoref?.current.paused){
            setIsPlaying(false);
        }else{
            setIsPlaying(true);
        }
    }
    const keyId = 'Video';
    React.useEffect(() => {
        const videoElm = document.querySelector(`${keyId}`);        
        if (videoElm) {
          videoElm.addEventListener("fullscreenchange", onFullScreenChange);
          videoElm.addEventListener("mozfullscreenchange", onFullScreenChange);
          videoElm.addEventListener("webkitfullscreenchange",onFullScreenChange);
          videoElm.addEventListener("msfullscreenchange", onFullScreenChange);
        }
        return () => {
            if (!videoElm) return;
            videoElm.removeEventListener("fullscreenchange", onFullScreenChange);
            videoElm.removeEventListener("mozfullscreenchange", onFullScreenChange);
            videoElm.removeEventListener("webkitfullscreenchange",onFullScreenChange);
            videoElm.removeEventListener("msfullscreenchange", onFullScreenChange);
          };
    });

    React.useEffect(() => {
        if (videoref?.current) {
            videoref?.current.play();
            setIsPlaying(true);
            setVideoLoader(false)
            setProgress(videoref?.current.currentTime);
        }
    }, []);

    const getVideo = () => {
        const obj = videoref?.current || {};
        return obj;
    };

    const handleFullScreenrequest = () => {
        const video = getVideo();   
        if (!video) return;
        if (video?.requestFullscreen) {
            video.requestFullscreen();
        } else if (video?.webkitEnterFullScreen) {
            video.webkitEnterFullScreen();
        } else if (video?.webkitRequestFullScreen) {
            video.webkitRequestFullScreen();
        } else if (video?.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        }
    }

    const onProgress = (e: any) => {
        setProgress((e.target.currentTime / e.target.duration) * 100);
    }
    const togglePlay = () => {
        if (isPlaying) {
            videoref.current.pause();
        } else {
            videoref.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleProgress = (e: any) => {
        if (videoref?.current) {
            const manualChange = Number(e.target.value);
            videoref.current.currentTime = (videoref.current.duration / 100) * manualChange;
            setProgress(manualChange);
        }
    }

    const handleVolume = (e: any) => {
        if(videoref?.current.muted){
            setMute(true)
        }else{
            const value = e.target.value ? e.target.value : Math.floor(e.target.volume * 100);
            if (value || value === 0) {
                setVolume(value / 100);
                setMute(false)
                const player = getVideo();
                if (!player) return;
                player.volume = value / 100;
            }
        }
        
    };

    const onMouseOver = React.useCallback(() => {
        if (inital && mute) {
            setMute(false);
            setInitial(false)
        }
        setOnMouse(true);
    }, [inital, mute]);

    const onMouseLeave = React.useCallback(() => {
        if (onMouse) {
            setOnMouse(false);
        }
    }, []);

    const onTimeUpdate = () => {
        const progress = (videoref.current.currentTime / videoref.current.duration) * 100;
        setProgress(progress)
    }
    const onCanPlay = () => {
        // URL.revokeObjectURL(videoUrl);
        setVideoLoader(false);
    }
    const onEnded = () => {
        setIsPlaying(false);
    }
    const muteEvent = () => {
        if (mute) {
            setMute(false);
            setVolume(100)
        } else {
            setMute(true);
            setVolume(0)
        }
    }
    const onErrolLoad = (e: any) => {
        e.target.src = BrokenVideo;
    }
    return { onErrolLoad,sourceref,muteEvent, onEnded, mute, videoUrl, onCanPlay, videoLoader, onTimeUpdate, handleVolume, volume, handleFullScreenrequest, onProgress, togglePlay, videoref, isPlaying, handleProgress, progress, onMouseOver, onMouseLeave, onMouse, keyId };
}