import React, { FC } from 'react';
import styles from './UserListPopper.module.css';
import { Popper, Fade, Paper, ClickAwayListener, Avatar, AvatarGroup } from '@mui/material';
import typoStyles from '../../Styles/component/Typography.module.css';
import { stringAvatar } from '../../Pages/TaskManager/Component/UserAvatar';
import { roleName } from '../../Services/utils/constant';
import { useSelector } from 'react-redux';
interface UserListPopperProps {
  data?: any
}

const UserListPopper: FC<UserListPopperProps> = ({ data }) => {
  
  const [isShown, setIsShown] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const anchorElPosition = React.useRef<any>(undefined);
  const user = useSelector((state: any) => state.persistReducers?.userReducer?.user);

  const handleClickAway = () => {
    setAnchorEl(null)
    setIsShown(false)
  }
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
    setIsShown(true)
  }  
  return (<div>
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
      <div >
        <div style={{ display: 'flex', flexDirection: 'row'}} ref={anchorElPosition?.current} onMouseEnter={handleClick}>
          <AvatarGroup max={3}>
            {data?.slice(0, 3)?.map((item: any) => (
              item.id && item.name &&
              <Avatar style={{ border: "none" }}  {...stringAvatar(item.id)} key={item.id}>
                <span className={typoStyles.typoRobotoMediumprimary214}>{item.name?.charAt(0).toUpperCase()}</span>
              </Avatar>
            ))}
          </AvatarGroup>
          {data?.slice(3)?.length !== 0 && data?.slice(3) !== undefined && <div style={{ marginLeft: '5px' }}>
            <span className={typoStyles.typoRobotoMediumprimary214}>{"+" + data?.slice(3)?.length}</span>
          </div>}
        </div>
        <Popper
          open={isShown}
          anchorEl={anchorEl}
          placement={'bottom-start'}
          transition
          sx={{
            zIndex: 2000
          }}
        >
          {({ TransitionProps }) => (
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <Fade {...TransitionProps} >
                <Paper className={styles.popPaper}>
                  {data?.map((item: any) =>
                  item.id && item.name &&
                    <div key={item.id} style={{padding: 10,gap: 10, display: 'flex', flexDirection: 'row', width:"inherit" }}>
                        <div>
                          <Avatar style={{ border: "none" }}  {...stringAvatar(item.id)} key={item.id}>
                            <span className={typoStyles.typoRobotoMediumprimary214}>{item.name?.charAt(0).toUpperCase()}</span>
                          </Avatar>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span className={typoStyles.typoRobotoRegoularprimary121621}>{item.name}</span>
                          <span className={typoStyles.typoRobotoRegulargreyprimary661622}>{user?.id === item.id ? "You" : roleName(item.role)}</span>
                        </div>
                    </div>)}
                </Paper>
              </Fade>
            </div>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  </div>
  )
};
export default UserListPopper;
