
import styles from './CreateSubject.module.css';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import { Usesubject } from '../../../../Services/chatService/CreateSubjectService';

interface subjectprops {
    addSubjectToList:any  
}

const CreateSubject = (props: subjectprops) => {
    const { text, characterCount, handleTextChange, saveSubject,} = Usesubject(props);
    return (
         <div className={styles.header}>
            <div className={styles.countsContainer}>
                <div className={typoStyles.typoRobotoRegulargreyprimary21419}>{`${characterCount}/40`}</div>
            </div>
            <div className={styles.textcontainer}>
                <TextField
                    multiline
                    placeholder="Type Here…"
                    rows={7}
                    value={text}
                    onChange={handleTextChange}
                    inputProps={{
                        sx: {
                            maxLength: 40,
                            "&::placeholder": {
                                fontFamily: 'RobotoItalic, sans-serif !important',
                                fontSize: ' 1rem !important',
                                lineHeight: '1.313rem !important',
                                letterSpacing: '0px !important',
                                color: '#707070  !important',
                                opacity: 1
                            },
                            color: '#000000 !important',
                            fontFamily: 'RobotoRegualr, sans-serif !important',
                            fontSize: ' 1rem !important',
                            letterSpacing: '0px !important',
                            opacity: 1,
                            textTransform: 'none'


                        }
                    }}
                    sx={{
                        width: "358px",
                        height: "193px",
                        borderRadius: "5px",
                        opacity: "1px",
                        background: "#F5F5F5 0% 0% no-repeat padding-box",
                        '& .MuiInputBase-root': {
                            border: "none",
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: "none",

                            },
                        },

                    }}
                />
            </div>
            <div className={styles.footer}>
                <Button disabled={text.trim().length === 0 ? true: false} onClick={() => saveSubject("0")} className={`${text.trim().length === 0 ? styles.buttonstylesdisabled : styles.buttonstyles} ${typoStyles.typoRobotoRegularprimary21419}`}>{"Save & Continue"}</Button>
                <span onClick={() => saveSubject('1')} className={`${text.trim().length === 0 ? styles.continuestyledisabled : styles.continuestyle} ${typoStyles.typoRobotoRegularblueprimary21419}`}>{"Continue Without Saving"}</span>
            </div>
        </div>
    );
};

export default CreateSubject;
