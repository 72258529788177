import React from "react";
import { readTextFromURL } from "../FileService/fileread";
import { LoaderGFContext } from "../../Components/Loader/LoaderProvider";
import CSV from 'comma-separated-values'
import { useSelector } from "react-redux";
interface CsvPreviewProps {
    item: any
}
export function useCsvPreview({ item }: CsvPreviewProps) {
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [ documentLoader, setDocumentLoader ] = React.useState<boolean>(false);
    const [ document, setDocument ] = React.useState<any>(null);
    const [ documentErr, setDocumentErr ] = React.useState<any>(false);

    React.useEffect(() => {
        async function initiateAPI() {
            setDocumentLoader(true);
            loaderSetting(true);
            setDocumentErr(false)
            const option = {
                method: 'GET',
                mode:'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }
            const result = await readTextFromURL(item.attachments.relativePath, option);            
            if(!result) return;            
            const csvData = csvParse(result);            
            if(csvData) {
              if(csvData?.rows.length > 0){
                setDocument(csvData);
              }else{
                setDocumentErr(true);
              }
            }else{
                setDocument(null);
            }
            loaderSetting(false);
            setDocumentLoader(false);
         }
        initiateAPI();
    },[]);

    const csvParse = (documentData: any) => {
        const rows: any = [];
        const columns: any = [];        
        new CSV(documentData).forEach((array: any) => {
            if (columns.length < 1) {                
              array.forEach((cell: any, idx: any) => {
                columns.push({
                  key: `key-${idx}`,
                  name: cell,
                  resizable: true,
                  sortable: true,
                  filterable: true,
                });
              });
            } else {
              const row: any = {};
              array.forEach((cell: any, idx: any) => {
                row[`key-${idx}`] = cell;
              });
              rows.push(row);
            }
          });
          
        return { rows, columns };
    } 

    return { documentLoader, document, documentErr };
}