import UserAvatar from '../TaskManager/Component/UserAvatar'
import styles from './UserComponent.module.css'
import typoStyles from '../../Styles/component/Typography.module.css'

interface UserComponentProps {
    auditorName: string
    auditorId: string
    detail?: boolean
    workload?: boolean
    showSpeciality?: boolean
    speciality?: any
}

const UserComponent = ({
    auditorName,
    auditorId,
    detail,
    workload,
    showSpeciality,
    speciality,
}: UserComponentProps) => {
    if (!auditorName && !auditorId) {
        return null
    }
    return (
        <div
            className={
                showSpeciality
                    ? styles.specialities_user_component
                    : styles.UserComponent
            }
        >
            {/* <UserAvatar name={auditorId}>
      <span style={{cursor:"pointer"}} className={typoStyles.typoRobotoMediumprimary214}>
        {auditorName.charAt(0).toUpperCase()}
      </span>
    </UserAvatar> */}
            {showSpeciality ? (
                <div className={styles.specialities_div}>
                    <span
                        className={`${
                            typoStyles.typoRobotoRegularprimary71824
                        } ${!detail && `${styles.textOverflow}`}`}
                    >
                        {auditorName}
                    </span>
                    <span className={typoStyles.typoRobotoRegoularprimary71621}>
                        {speciality}
                    </span>
                </div>
            ) : workload ? (
                <span
                    className={`${typoStyles.typoRobotoRegularprimary71824} ${
                        !detail && `${styles.textOverflow}`
                    }`}
                >
                    {auditorName}
                </span>
            ) : (
                <span
                    className={`${
                        detail
                            ? typoStyles.typoRobotoRegoularprimary7160
                            : typoStyles.typoRobotoRegoularprimary714pointer
                    } ${styles.textOverflow}`}
                >
                    {auditorName}
                </span>
            )}
        </div>
    )
}

export default UserComponent
