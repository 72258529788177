import { createContext, FC, ReactNode, useState } from "react";
import { MessageContext } from "./interface";


export const UnreadCountContext = createContext<MessageContext>({}as MessageContext);

export const UnreadCountProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  return (
    <UnreadCountContext.Provider value={{ unreadCount, setUnreadCount }}>
      {children}
    </UnreadCountContext.Provider>
  );
};
