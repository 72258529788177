import { Box } from '@mui/material';
import { FC } from 'react';
import styles from './DrawerPlaceHolder.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';
import emptyPng from '../../Assests/Icons/empty-folder/empty-folder.png';

interface DrawerPlaceHolderProps {
  record: any
}

const DrawerPlaceHolder: FC<DrawerPlaceHolderProps> = ({ record }) => (<>
  {!record || record?.length === 0 &&
    <Box className={styles.DrawerPlaceHolder} >
      <img src={emptyPng} alt='empty' />
      <span className={typoStyles.typoRobotoMediumprimary671833}>{"No Record"}</span>
    </Box>}
</>);

export default DrawerPlaceHolder;
