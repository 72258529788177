import React from 'react'
import styles from './TableThreeDotMenu.module.css'
import { styled, alpha } from '@mui/material/styles'
import Menu, { MenuProps } from '@mui/material/Menu'

import {
    IconButton,
    MenuItem,
    Typography,
    Tooltip,
    ClickAwayListener,
} from '@mui/material'
import { ReactComponent as IconMoreSharp } from '../../Assests/Icons/IconMoreSharp.svg'
import { options } from '../../Services/utils/constant'
import typoStyles from '../../Styles/component/Typography.module.css'
import { LightTooltip } from '../../Services/taskManagerService/common'
import { useSelector } from 'react-redux'

type TableThreeDotMenuProps = {
    data?: any
    onclick: any
}

const TableThreeDotMenu = ({ data, onclick }: TableThreeDotMenuProps) => {
    // const [open, setOpen] = React.useState(false)
    const [active, setActive] = React.useState(false)
    // const anchorRef = React.useRef<HTMLButtonElement | null>(null)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setActive(true)
    }
    const user = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )

    // const handleClick = () => {
    //     setOpen((prevOpen) => !prevOpen)
    //     setActive(true)
    // }

    const handleMenuItemClick = (option: any) => {
        onclick(option.key, data)
        // setOpen(false)
        setAnchorEl(null)
        setActive(false)
    }

    const handleClose = () => {
        // setOpen(false)
        setAnchorEl(null)
        setActive(false)
    }

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light'
                    ? 'rgb(55, 65, 81)'
                    : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }))

    return (
        <div>
            <ClickAwayListener onClickAway={handleClose}>
                <div>
                    <Tooltip title="Open Menu">
                        <IconButton
                            className={`${styles.dotbtn} ${
                                active ? styles.active : ''
                            }`}
                            // ref={anchorRef}
                            aria-controls={open ? 'menu-list-fade' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                            sx={{ width: 40, height: 40 }}
                        >
                            <IconMoreSharp />
                        </IconButton>
                    </Tooltip>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <div
                        // style={{
                        //     paddingTop: '10px',
                        //     paddingBottom: '10px',
                        // }}
                        >
                            {options.map(
                                (option) =>
                                    option.roles?.some(
                                        (ro) => user?.userRole === ro,
                                    ) &&
                                    (data.status !== 'claim submitted' ||
                                        option.label === 'View Task') && (
                                        <MenuItem
                                            className={styles.menuItem}
                                            key={option.id}
                                            selected={option.label === 'Pyxis'}
                                            onClick={() =>
                                                handleMenuItemClick(option)
                                            }
                                        >
                                            <Typography
                                                className={
                                                    typoStyles.typoRobotoRegoularprimary5714
                                                }
                                            >
                                                {option.label}
                                            </Typography>
                                        </MenuItem>
                                    ),
                            )}
                        </div>
                    </StyledMenu>
                    {/* <LightTooltip open={open} placement='left-start' title={
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              {options.map((option) => (
                (option.roles?.some(ro => user?.userRole === ro) && (data.status !== "claim submitted" || option.label === 'View Task')) &&
                <MenuItem
                  className={styles.menuItem}
                  key={option.id}
                  selected={option.label === 'Pyxis'}
                  onClick={() => handleMenuItemClick(option)}
                >
                  <Typography className={typoStyles.typoRobotoRegoularprimary5714}>{option.label}</Typography>
                </MenuItem>
              ))}
            </div>
          } arrow>
            
          </LightTooltip> */}
                </div>
            </ClickAwayListener>
        </div>
    )
}

export default TableThreeDotMenu
