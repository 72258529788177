import { Card, CardHeader, CircularProgress, Tooltip } from '@mui/material';
import styles from './ViewAudios.module.css';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import moment from 'moment';
import { useViewAudio } from '../../../../Services/taskManagerService/viewAudioService';
import ViewAudioPlayer from './ViewAudioPlayer'
import CircularProgressWithLabel from '../../../../Components/CircularProgressWithLabel/CircularProgressWithLabel';
import ShadowDrawer from '../../../../Components/ShadowDrawer/ShadowDrawer';
import { ReactComponent as CrossIcon } from '../../../../Assests/Icons/Cross icon (4).svg';
// import typoStyles from '../../../../Styles/component/Typography.module.css';

type ViewAudiosProps = {
  appointmentId: string | undefined | null,
  scheduleId: string | undefined | null,
  encounterId?:any
}
const ViewAudios = (props: ViewAudiosProps) => {
  const { selectedId, curSong, handleExpandClick, encounter, onScroll, rootref, loaderProgress, audioLoader, openPreview, toogleClosePreview } = useViewAudio(props);

  const loderComponent = (value: any) => {
    if (value > 0) {
      return <CircularProgressWithLabel value={value} />;
    }
    return <CircularProgress />;
  }

  return (
    <div className={styles.ViewAudios} onScroll={onScroll} ref={rootref}>
      {encounter?.map((item: any) => (

        <Card key={item.id} className={styles.audioCard}>
          <CardHeader
            avatar={ <Tooltip title={selectedId === item.id ? "Pause": "Play" }>
              <IconButton sx={{ width: "42px", height: "42px", background: "#F2F2F2 0% 0% no-repeat padding-box" }} onClick={() => handleExpandClick(item)} >
                {selectedId === item.id ? <PauseIcon sx={{ color: "#E93322" }} /> : <PlayArrowIcon sx={{ color: "#8D8C8C" }} />}
              </IconButton></Tooltip>
            }
            title={item.filePath.split('/')[item.filePath.split('/').length - 1]}
            subheader={<span>{`${moment(item.createdAt).format('MM/DD/yyyy')}`}</span>}
          />
          {/* <span style={{marginTop:"18px"}} className={typoStyles.typoRobotoMediumprimary1014}>{"View Logs"}</span> */}

          {audioLoader && selectedId === item.id ? (
            <ShadowDrawer openDrawer={openPreview}>
             
              <div className={styles.AudioPlayer}>
                <IconButton onClick={toogleClosePreview} className={styles.cross_Btn}>
                  <CrossIcon className={styles.crossIcon} />
                </IconButton>
                <div className={styles.loder_rootDiv}>
                  {loderComponent(loaderProgress)}
                </div>
              </div>
            
            </ShadowDrawer>
          ) : (
            selectedId === item.id && curSong && (
              <ShadowDrawer openDrawer={openPreview}>
                <ViewAudioPlayer
                  key={item.id}
                  audioUrl={curSong}
                  toogleAudio={toogleClosePreview}
                  audioFilename={item.filePath.split('/')[item.filePath.split('/').length - 1]}
                 
                />
              </ShadowDrawer>
            )
          )}

        </Card>

      ))}

    </div>
  )
};

export default ViewAudios;

