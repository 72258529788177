import React, { useState } from 'react'
import IndividualAuditor from '../../../../Components/IndividualAuditor/IndividualAuditor'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton } from '@mui/material'

function TATMeterAuditorIndividual() {
    const navigate = useNavigate()
    const location = useLocation()
    console.log('lost', location.state)
    return (
        <div style={{ margin: '5px 5px 20px 5px' }}>
            <div style={{ paddingLeft: 5 }}>
                <IconButton
                    sx={{ marginRight: 5 }}
                    aria-label="back a page"
                    onClick={() => navigate(-1)}
                >
                    <ArrowBackIcon />
                </IconButton>
                Job List For {location.state.firstName}{' '}
                {location.state.lastName}
            </div>
            <IndividualAuditor id={location.state.id} />
        </div>
    )
}

export default TATMeterAuditorIndividual
