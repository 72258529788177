import React, { useContext } from 'react';
import LastConversation from './Component/LastConversation/LastConversation';
import SubjectList from './Component/SubjectList/SubjectList';
import Conversation from './Component/Conversation/Conversation';
import { SocketContext } from '../../Components/Sockets/Socket';
import AttachmentList from './Component/AttachmentList/AttachmentList';
import TaskdrawerHeader from '../../Components/TaskdrawerHeader/TaskdrawerHeader';


interface Props {
	setFlagShowMsg: any,
	chatnavigation: any,
	taskId: string,
	providerId: any,
	auditorId: any,
	managerId: any,
	appointmentId: any,
	patientName: any,
	mrn: any,
	taskName: any,
	menuHandleCloseClick?: any,
	subject?: any,
	fillablePdf?: any,
	openConfirm?:any,
	setSendPdfToProvider?:any
}
const ChatComponent = (props: Props) => {
	const { setSendPdfToProvider, openConfirm, subject, fillablePdf, patientName, mrn, setFlagShowMsg, chatnavigation, taskId, auditorId, providerId, managerId, appointmentId } = props;
	const [lastChat, setLastChat] = React.useState<any>(null);
	const { socket } = useContext(SocketContext);
	const [preFlag, setPreFlag] = React.useState<any>(false);
	const [subjectData, setSubjectdata] = React.useState<any>(subject);

	const handleItemClick = React.useCallback((data: any) => {
		setLastChat(data);
		setPreFlag(true)
		setSubjectdata(data?.subjects)
		setFlagShowMsg(4)
		if(setSendPdfToProvider){
			setSendPdfToProvider(false)
		}
	}, [])
	const selectSubjcet = React.useCallback((data: any) => {
		setSubjectdata(data);
		setLastChat(null)
		setFlagShowMsg(4)
		if(setSendPdfToProvider){
			setSendPdfToProvider(false)
		}
	}, [])

	return (
		<div>
			<TaskdrawerHeader title={props.taskName} onclick={() => {
				if (props.menuHandleCloseClick) {					
					props.menuHandleCloseClick()
					setFlagShowMsg(null)
					if(setSendPdfToProvider){
						setSendPdfToProvider(false)
					}
				}
			}} />
			{chatnavigation === 1 &&
				<LastConversation
					taskId={taskId}
					socket={socket}
					patientName={patientName}
					mrn={mrn}
					appointmentId={appointmentId}
					handleItemClick={handleItemClick}
					setFlagShowMsg={setFlagShowMsg}
					menuHandleCloseClick={props.menuHandleCloseClick}
				/>}
			{chatnavigation === 2 &&
				<SubjectList
					patientName={patientName}
					mrn={mrn}
					setFlagShowMsg={setFlagShowMsg}
					selectSubjcet={selectSubjcet}
				/>}
			{chatnavigation === 4 &&
				<Conversation
					lastChat={lastChat}
					taskId={taskId}
					preFlag={preFlag}
					setPreFlag={setPreFlag}
					socket={socket}
					auditorId={auditorId}
					managerId={managerId}
					providerId={providerId}
					setFlagShowMsg={setFlagShowMsg}
					subjectData={subjectData}
					appointmentId={appointmentId}
					fillablePdf={fillablePdf}
					openConfirm={openConfirm}
				/>}
			{chatnavigation === 5 &&
				<AttachmentList
					providerId={providerId}
					subjectData={subjectData?.id}
					appointmentId={appointmentId}
					setFlagShowMsg={setFlagShowMsg}
				/>}
		</div>
	)
}

export default ChatComponent;
