import { FC } from 'react';
import styles from './CSVViewer.module.css';
import { Dialog, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../Assests/Icons/Cross icon (2).svg';
import { useCsvPreview } from '../../Services/csvViewerService/csvViewer';
import ReactDataGrid from 'react-data-grid';
import typoStyles from '../../Styles/component/Typography.module.css';
import 'react-data-grid/lib/styles.css';
import { ReactComponent as Brokendocument } from '../../Assests/Icons/Broken document.svg';

interface CSVViewerProps {
	open: boolean,
	handleClose: any,
	item: any
}

const CSVViewer: FC<CSVViewerProps> = ({ open, handleClose, item }) => {
	const { documentLoader, document, documentErr } = useCsvPreview({ item });

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth={true}
			style={{ zIndex: 2000 }}
			PaperProps={{
				style: {
					background: "white",
					//borderRadius: "16px",
					position: "inherit",
					boxShadow: documentLoader ? "none" : "inherit",
					display: 'flex',
					alignItems: "center",
					justifyContent: "center",
					height: "inherit",
					overflow:"hidden"
				}
			}}
			maxWidth="lg"
			aria-labelledby="responsive-dialog-title"
			onContextMenu={(e) => e.preventDefault()}
		>
			<div style={{marginTop:"45px", display:"flex", flexDirection:"row", width:"100%"}}>
				<div style={{width:"100%",display:"flex", alignItems:"center", justifyContent:"center"}}>
				<span className={typoStyles.typoRobotoMediumprimary1224124}>{item?.attachments?.fileName}</span>
				</div>
				<div style={{marginRight:"10px"}}>
				<Tooltip title={"Close"}>
				<IconButton size='small' onClick={() => handleClose()} className={styles.cross_Btn}>
					<CrossIcon className={styles.crossIcon} />
				</IconButton>
				</Tooltip>
				</div>
				
			</div>
			{(document && !documentErr) ? <div className={styles.documentViewer}>
			
				<ReactDataGrid
					columns={document.columns}
					rowsCount={document.rows.length}
					rows={document.rows}
					rowGetter={(i: any) => document.rows[i]}
					style={{ height: "100%" }}
				/>
			</div>: <div className={styles.brokenPlaceholder}>
					<Brokendocument />
					<span style={{marginTop:"50px"}} className={typoStyles.typoRobotoMediumprimary742042}>{"Oops! Something went wrong"}</span>
					</div>}

		</Dialog>
	)
};

export default CSVViewer;
