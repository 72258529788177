import typoStyles from '../../../../Styles/component/Typography.module.css';
import ChatNavigation from "../ChatNavigation/ChatNavigation";
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ViewMedia from '../ViewMedia/ViewMedia'
import ViewFile from '../ViewFile/ViewFile'
import { Badge } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface IProps {
  providerId?: any
  subjectData?: any
  appointmentId?: any
  setFlagShowMsg: any,
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function AttachmentList(props: IProps) {
  const {  providerId, appointmentId, subjectData, setFlagShowMsg } = props;
  const [value, setValue] = React.useState(0);
  const [attachmentCount, setAttachmentCount] = React.useState({
    mediaCount: null,
    fileCount: null,
  });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigationClick = () => {
    setFlagShowMsg(4)
  }
  
  const handleCount = (response: any) => {
    setAttachmentCount({mediaCount: response?.mediaCount, fileCount: response?.fileCount})
  }
  return (
    <div >
      <ChatNavigation label={'File Repository'} navigationClick={navigationClick}>
      </ChatNavigation>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab sx={{ textTransform: "initial" }} label={<Badge badgeContent={attachmentCount?.mediaCount} color="primary">
            {"Media"}
</Badge>}  {...a11yProps(0)} className={typoStyles.typoRobotoMedium1633} />
            <Tab sx={{ textTransform: "initial" }} label={<Badge badgeContent={attachmentCount?.fileCount} color="primary">
            {"Files"}
</Badge>} {...a11yProps(1)} className={typoStyles.typoRobotoMedium1633} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ViewMedia handleCount={handleCount}  providerId = {providerId}  subjectData={subjectData} appointmentId={appointmentId}  setFlagShowMsg={setFlagShowMsg} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ViewFile  providerId = {providerId}  subjectData={subjectData} appointmentId={appointmentId} setFlagShowMsg={setFlagShowMsg} />
        </TabPanel>
      </Box>
    </div>
  )
}