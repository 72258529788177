import * as React from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { IconButton, OutlinedInput, SelectChangeEvent } from '@mui/material'
import { ReactComponent as DownArrow } from '../Assests/Icons/Down Arrow.svg'

type DropdownProps = {
    value?: string
    handleChange: (e: SelectChangeEvent) => void
    children: React.ReactNode,
    disable?:any
}
export default function Dropdown({
    value,
    handleChange,
    children,
    disable
}: DropdownProps) {
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth disabled={disable}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    input={<OutlinedInput id="select-multiple-chip" />}
                    onChange={(e) => {
                        if(!disable){
                            handleChange(e)
                        }
                    }}
                    IconComponent={(props) => (
                        <IconButton {...props}>
                            <DownArrow />
                        </IconButton>
                    )}
                    sx={{
                        height: '56px',
                        marginTop: '12px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        border: '1px solid #E2DFDF !important',
                        borderRadius: '12px',
                        opacity: 1,
                        '& .MuiInputBase-input': {
                            paddingLeft: '6px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2DFDF !important',
                            borderRadius: '12px',
                        },
                        '& .MuiOutlinedInput-input.Mui-disabled':{
                            '-webkit-text-fill-color': 'unset' 
                        }
                    }}
                >
                    {children}
                </Select>
            </FormControl>
        </Box>
    )
}
