import { FC } from 'react'
import styles from './Notification.module.css'
import typoStyles from '../../Styles/component/Typography.module.css'
import { useNotification } from '../../Services/notificationService/notification'
import moment from 'moment'
import { ReactComponent as Slideback } from '../../Assests/Icons/Slide back.svg'
import { Button, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import { StatusTag } from '../../Pages/TaskManager/TaskManagerMUIStyles'
import { useSelector } from 'react-redux'

interface NotificationProps {
    open?: boolean
    closedrawer: any
}
const CustomIconButton = styled(Button)({
    height: 26,
    minWidth: 26,
    '&:hover': {
        fill: '#427BE0',
    },
})
const Notification: FC<NotificationProps> = ({ closedrawer }) => {
    const {
        notificationData,
        onScroll,
        listRootRef,
        markAllasReadFun,
        markasReadFun,
    } = useNotification()
    const user = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )

    return (
        <div className={styles.Notification}>
            <div className={styles.NotificationHeader}>
                <div className={styles.cardactions}>
                    <span className={typoStyles.typoRobotoNormalprimary122233}>
                        Notifications{' '}
                    </span>
                    <div className={styles.headerTag}>
                        <Button
                            onClick={() => markAllasReadFun()}
                            className={styles.markBtn}
                        >
                            <span
                                className={
                                    typoStyles.typoRobotoRegoularprimary761421lh
                                }
                            >
                                Mark all as Read
                            </span>
                        </Button>
                        <Tooltip title="Close Notification">
                            <CustomIconButton
                                size="small"
                                onClick={() => {
                                    closedrawer()
                                }}
                            >
                                <Slideback />
                            </CustomIconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div
                className={styles.NotificationPaper}
                ref={listRootRef}
                onScroll={onScroll}
            >
                {notificationData?.map((item: any) => (
                    <div
                        onClick={() => markasReadFun(item)}
                        key={item.id}
                        className={
                            item.readby?.includes(user?.id)
                                ? styles.cardPaper
                                : styles.unReadedcardPaper
                        }
                    >
                        <div className={styles.cardcontent}>
                            <CreateMessageComponent item={item} />
                        </div>
                        <div className={styles.cardaction}>
                            <div className={styles.cardfooter}>
                                <span
                                    className={
                                        typoStyles.typoRobotoRegoularprimary6114
                                    }
                                >
                                    {moment(item?.createdAt).format(
                                        'MMMM D YYYY',
                                    )}
                                    &nbsp;
                                </span>
                                <span
                                    className={
                                        typoStyles.typoRobotoRegoularprimary6114
                                    }
                                >
                                    &nbsp;
                                    {moment(item?.createdAt).format('hh:mm A')}
                                </span>
                            </div>
                            {/* <Button className={styles.viewBtn}><span className={typoStyles.typoRobotoRegoularprimary31621lh}>View Task</span></Button> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Notification

interface CreateMessageComponentProps {
    item: any
}

const CreateMessageComponent: FC<CreateMessageComponentProps> = ({ item }) => {
    if (item.type === 'encounter') {
        return (
            <div>
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {`has submitted the Audio for the task`}&nbsp;
                    <span className={typoStyles.typoRobotoNormalprimary1216210}>
                        {"'"}
                        {item.taskName}
                        {"'"}
                    </span>
                    {'.'}
                </span>
            </div>
        )
    } else if (item.type === 'transcript') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {`Transcript for Job`}&nbsp;
                    <span className={typoStyles.typoRobotoNormalprimary1216210}>
                        {"'"}
                        {item.taskName}
                        {"'"}
                    </span>
                    &nbsp;{'is generated.'}
                </span>
            </div>
        )
    } else if (item.type === 'createTask') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'Job is created for'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'by'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'updateTask') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'Job is updated for'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'by'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'markHasClaimed') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'Mark is claimed for the task'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'by'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'statusUpdated') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'Status is changed from'}
                </span>
                &nbsp;
                <StatusTag tag={true} status={item.previousTaskStatus} />
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'to'}
                </span>
                &nbsp;
                <StatusTag tag={true} status={item.currentTaskStatus} />
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'for the task'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'by'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'reassignTask') {
        return (
            <div>
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'is reassigned to'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.reassignedAuditor}&nbsp;
                </span>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'by'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'messageTextNotification') {
        return (
            <div>
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'sent a message to the task'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}&nbsp;
                </span>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'for the issue'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.subject}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'messageFileNotification') {
        return (
            <div>
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'uploaded a file to the task'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}&nbsp;
                </span>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'for the issue'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.subject}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'automaticTaskCreation') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'Job is created for'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {"'"}
                    {item.taskName}
                    {"'"}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'and Encounter has been submitted by'}
                </span>
                &nbsp;
                <span className={typoStyles.typoRobotoNormalprimary1216210}>
                    {item.byUser}
                </span>
                {'.'}
            </div>
        )
    } else if (item.type === 'encounterTranscriptionFailed') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'Transcription for task has failed: '}
                    <span className={typoStyles.typoRobotoNormalprimary1216210}>
                        {"'"}
                        {item.taskName}
                        {"'"}
                    </span>
                    {'.'}
                </span>
            </div>
        )
    } else if (item.type === 'encounterTranscriptionSuccess') {
        return (
            <div>
                <span className={typoStyles.typoRobotoRegoularprimary31621lh}>
                    {'Transcription for task is ready: '}
                    <span className={typoStyles.typoRobotoNormalprimary1216210}>
                        {"'"}
                        {item.taskName}
                        {"'"}
                    </span>
                    {'.'}
                </span>
            </div>
        )
    } else {
        return null
    }
}
