import { Backdrop, Drawer } from '@mui/material';
import React, { FC } from 'react';
const drawerWidth = 462;

interface ShadowDrawerProps {
  openDrawer: boolean
  children: React.ReactNode
}

const ShadowDrawer: FC<ShadowDrawerProps> = ({ openDrawer, children }) => (
  <Drawer
    open={openDrawer}
    SlideProps={{
      unmountOnExit: true
    }}
    sx={{
      width: drawerWidth,
      overflow: "hidden",
      background:"none",
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        background:"none",
        border:"1px solid #707070"
      },
      '& .MuiBackdrop-root':{
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      }
    }}
    variant="persistent"
    anchor="right"
  >
    <Backdrop open={true} sx={{ width: drawerWidth, position:"absolute"}}>
    {children}
    </Backdrop>
  </Drawer>
);

export default ShadowDrawer;
