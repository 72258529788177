import React from 'react';
import styles from './ChatUserInfo.module.css';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import moment from 'moment';
import Collapse from '@mui/material/Collapse'
import { ReactComponent as DownArrow } from '../../../../Assests/Icons/Down Arrow.svg'
import { ReactComponent as UpArrow } from '../../../../Assests/Icons/Up Arrow.svg'
import { ReactComponent as Attachemnticon } from '../../../../Assests/Icons/Icons svg/Attachemnt icon.svg';
import Badge from '@mui/material/Badge';
import { Tooltip } from '@mui/material';

interface ChatUserInfoProps {
  taskId: string,
  patientData: any
  navigationClickRepo:any,
  count:any,
  onCollapseToggle: () => void,
  isCollapsed:any,
}

const ChatUserInfo = (props: ChatUserInfoProps) => {
  const { patientData,navigationClickRepo,count,onCollapseToggle, isCollapsed } = props;
 
  return (
    <div className={styles.info}>
      {patientData && <div className={styles.infor1} >
        <div>
          <div className={styles.info3}>
            <div className={styles.nameInfo}>
              <span className={typoStyles.typoRobotoRegoularBoldprimary1216}>{`First Name: `}&nbsp;</span>
              <span className={typoStyles.typoRobotoRegoularprimary121621}>{` ${patientData.patientFirstName}`}</span>
            </div>
            <div className={`${styles.nameInfo} ${styles.lastNameSpacing}` }>
              <span className={typoStyles.typoRobotoRegoularBoldprimary1216}>{`Last Name: `}&nbsp;</span>
              <span className={typoStyles.typoRobotoRegoularprimary121621}>{` ${patientData.patientLastName}`}</span>
            </div>
            <div className={styles.info3}>
            <div className={styles.attachmentInfo} >
            <div style={{ display: "flex", alignItems: "center",marginLeft:"-30px" }}>
            <Tooltip title="Open File Repository">
            <Badge badgeContent={count} color="error" sx={{ width: 20, height: 15, }}>
              <div
                style={{
                  width: 40,
                  height: count > 0 ? 22 : 15,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", 
                  cursor: "pointer"
                }}
                onClick={navigationClickRepo}
              >
                <Attachemnticon style={{ width: '30px', height: '30px', }} />
              </div>
            </Badge>
            </Tooltip>
          </div>
            </div>
            <Tooltip title={isCollapsed ? "Close": "Open"}>
            <div className={styles.arrowInfo} onClick={()=>onCollapseToggle()} >
            {isCollapsed ? <UpArrow /> : <DownArrow />}
            </div>
            </Tooltip>
            </div>
         

          </div>
          <Collapse in={isCollapsed}>
          <div className={styles.info2}>
            <div className={styles.patientInfo}>
              <span className={typoStyles.typoRobotoRegoularBoldprimary1216}>{`DOB: `}&nbsp;</span>
              <span className={typoStyles.typoRobotoRegoularprimary121621}>{moment(patientData.patientDateOfBirth).format("MM-DD-YYYY")}</span>
            </div>
            <div className={`${styles.patientInfo} ${styles.genderspace} `}>
              <span className={typoStyles.typoRobotoRegoularBoldprimary1216}>{`Gender: `}&nbsp;</span>
              <span className={typoStyles.typoRobotoRegoularprimary121621}>{` ${patientData.gender === 'M' ? 'Male' : 'Female'}`}</span>
            </div>
          </div>
          <div className={styles.info2}>
            <div className={styles.patientInfo}>
              <span className={typoStyles.typoRobotoRegoularBoldprimary1216}>{`MRN: `}&nbsp;</span>
              <span className={typoStyles.typoRobotoRegoularprimary121621}>{` #${patientData.patientMrn}`}</span>
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <span className={typoStyles.typoRobotoRegoularBoldprimary1216}>{"Appointment Date & Time:"}&nbsp;</span>
            <span className={typoStyles.typoRobotoRegoularprimary121621}>{moment(patientData.appointmentDate).format("DD MMMM YYYY") + " " + patientData.time}</span>
          </div>
          <div style={{ marginTop: "5px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            <span className={typoStyles.typoRobotoRegoularBoldprimary1216}>{"Reason for Visit:"}&nbsp;</span>
            <span className={typoStyles.typoRobotoRegoularprimary121621}>{patientData.appointmentReason} </span>
          </div>
          </Collapse>
        </div>
      </div>}
    </div>
  )
};

export default ChatUserInfo;
