import React, { FC } from 'react';
import styles from './SuccessAlert.module.css';
import { ReactComponent as Success } from '../../Assests/Icons/success.svg'
import { Button, DialogContent } from '@mui/material'
import buttonStyles from '../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../Styles/component/Typography.module.css'

interface SuccessAlertProps {
  primaryLable: string,
  conFirmOnClick: React.MouseEventHandler<HTMLButtonElement>
}

const SuccessAlert: FC<SuccessAlertProps> = ({ primaryLable, conFirmOnClick }) => (
  <div className={styles.SuccessAlert}>
    <Success style={{ position: 'absolute', top: '-30px' }} />
    <DialogContent>
      <span className={typoStyles.typoRobotoMediumprimary6014}>
        {primaryLable}
      </span>
      <Button
        style={{ marginTop: '25px' }}
        className={buttonStyles.okbutton}
        onClick={conFirmOnClick}
      >
        <span className={typoStyles.typoRobotoMediumprimary214}>
          {'OK'}
        </span>
      </Button>
    </DialogContent>
  </div>
);

export default SuccessAlert;
