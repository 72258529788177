import React, { FC } from 'react';
import { Button } from "@mui/material";
import { ReactComponent as Crossicon } from '../../../../Assests/Icons/Cross icon (5).svg';
import { ReactComponent as PlayIcon } from '../../../../Assests/Icons/Play icon (3).svg';
import styles from './Previewfield.module.css';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import { fileNameSectag, renderImg } from '../../../../Services/utils/ImageUtil';

interface previewprops {
    
    showButton: any
    filetypes: any,
    uploadedFile: any,
    handleClose: any,
    file: any,
    isVideo: any
    showimage:any

}

const PreviewField: FC<previewprops> = ({ showButton,showimage, filetypes, uploadedFile, handleClose, isVideo, file }) => {

    return (
        <div >
            {showButton && <Button
                className={styles.cardDiv}

            >
                <div className={styles.rowDiv}>
                    <div className={styles.svgStyles}>
                        <img
                            draggable={false}
                            src={renderImg(filetypes)}
                            alt='attachment'
                            width={24}
                            height={32}
                        />
                    </div>
                    <div className={styles.details}>
                        <span className={typoStyles.typoRobotoNormalprimary71419} >
                            {fileNameSectag(uploadedFile, filetypes)}
                        </span>

                    </div>
                    <div className={styles.crossIcon} onClick={handleClose}>
                        <Crossicon width={22} height={22} />
                    </div>
                </div>
            </Button>
            }
            { file && showimage && (
                <div className={styles.medirowDiv}>
                   
                        {isVideo ? (
                          
                            <video
                                src={file}
                                crossOrigin='anonymous'
                                draggable={false}
                                preload="auto" 
                                style={{ width: "100%", height: "100%",objectFit: "cover", borderRadius: "14px" }}

                            />
                           
                        ) : (
                            <img
                                src={file}
                                crossOrigin='anonymous'
                                draggable={false}
                                alt=""
                                style={{ width: "64px", height: "64px", borderRadius: "14px" }}
                            />
                        )}
                        {isVideo?
                       ( <div className={styles.playButtonContainer}>
                            <Button className={styles.playButton}>
                                <PlayIcon />
                            </Button>
                        </div>):null}
                    
                    <div className={styles.crossdiv} onClick={handleClose}>

                        <Crossicon width={22} height={22} />

                    </div>
                </div>
            )}


        </div>
    )
}
export default PreviewField;