import _ from 'lodash';

export const taskListReduce = (state: any, action: any) => {
    switch (action.type) {
        case 'add':
            return _.unionBy([...state,...action.payload]);
        case 'update': {
            const productIndex = state.findIndex((item: any) => item.id === action?.payload?.id);
            if(productIndex < 0) {
                return state;
            }
            const update = [...state];            
            update.splice(productIndex, 1, action.payload)           
            return _.unionBy(update);
        }
        case 'remove': {
            const productIndex = state.findIndex((item: any) => item.id === action?.payload?.id);
            if(productIndex < 0) {
                return state;
            }
            const update = [...state];            
            update.splice(productIndex, 1)           
            return _.unionBy(update);
        }
        case 'removeall':      
            return action.payload;
        default:
            return state;
    }
}
export const taskReduce = (state: any, action: any) => {
    switch (action.type) {
        case 'add':
            return action.payload;
        case 'update': {
            const productIndex = state.findIndex((item: any) => item.id === action?.payload?.id);
            if(productIndex < 0) {
                return state;
            }
            const update = [...state];            
            update.splice(productIndex, 1, action.payload)           
            return _.unionBy(update);
        }
        case 'remove': {
            const productIndex = state.findIndex((item: any) => item.id === action?.payload?.id);
            if(productIndex < 0) {
                return state;
            }
            const update = [...state];            
            update.splice(productIndex, 1)           
            return _.unionBy(update);
        }
        case 'removeall':      
            return action.payload;
        default:
            return state;
    }
}