import React from 'react'
import { ViewTaskProps } from './viewTaskService'
import { SelectChangeEvent } from '@mui/material'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider'
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider'
import { statusChange } from '../api/taskAPIService'
import { statusTagMenuItem } from '../utils/constant'
import { updateDisplayStatus } from '../../Services/api/auditorAPIService'
export function useTaskStatusUpdate(props: ViewTaskProps) {
    const [status, setStatus] = React.useState(props.task?.displayStatus)
    // eslint-disable-next-line
    const [statusObj, setStatusobj] = React.useState(statusTagMenuItem[0])
    const [statusFlag, setStatusFlag] = React.useState(false)
    const [statusFlag1, setStatusFlag1] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [taskName, setTaskName] = React.useState(props?.task?.taskName)

    React.useEffect(() => {
        async function getcurrentStatus() {
            const statusObj = statusTagMenuItem?.find(
                (item: any) => item.key === status,
            )
            if (statusObj) {
                setStatusobj(statusObj)
            }
        }
        getcurrentStatus()
    }, [props?.task?.displayStatus])

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value)
        setStatusFlag(true)
    }
    const saveStatus = async () => {
        loaderSetting(true)
        const bodyObj = {
            id: props?.task?.id,
            status: status,
        }
        if (props?.task?.id && bodyObj) {
            // const result = await statusChange(props?.task?.id, bodyObj);
            const result = await updateDisplayStatus({
                encounterId: props?.task?.encounterId,
                displayStatus: status,
            })
            console.log('statusObj', result)
            if (result !== null && props.updatedTaskList) {
                // if (result?.displayStatus) {
                if (result?.statusCode == 200) {
                    loaderSetting(false)
                    setStatusFlag(false)
                    setStatusFlag1(true)
                    setTaskName(result?.task?.taskName)
                    props?.updatedTaskList(result?.task)
                    snackbarSetting(true, result?.message, 'success')
                    const statusObj = statusTagMenuItem?.find(
                        (item: any) => item.key === status,
                    )
                    props.task.displayStatus = status
                    if (statusObj) {
                        setStatusobj(statusObj)
                    }
                } else {
                    loaderSetting(false)
                    snackbarSetting(true, result?.message, 'error')
                }
            } else {
                loaderSetting(false)
            }
        }
    }
    return {
        taskName,
        setOpen,
        statusFlag1,
        statusFlag,
        open,
        handleChange,
        statusObj,
        status,
        saveStatus,
    }
}
