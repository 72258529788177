import React from 'react';
import { ViewTaskProps } from './viewTaskService';
import {  SelectChangeEvent } from '@mui/material';
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider';
import { apiReassignSubmit } from '../api/taskAPIService';


export function useReassign(props: ViewTaskProps) {
    const [status, setStatus] = React.useState(props.task?.auditorId)
    // eslint-disable-next-line
    const [statusObj, setStatusobj] = React.useState({
        id:props.task?.auditorId,
        firstName: props.task?.auditorName?.split(" ")[0],
        lastName:props.task?.auditorName?.split(" ")[1]
    })
    const [statusFlag, setStatusFlag] = React.useState(false)
    const [statusFlag1, setStatusFlag1] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [taskName, setTaskName] = React.useState(props.task?.taskName)

    const { snackbarSetting } = React.useContext(SnackbarContext)

    const { loaderSetting } = React.useContext(LoaderGFContext)

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value)
        setStatusFlag(true)
    }
    const reassignSubmitted =async () => {
        loaderSetting(true)
        if(props?.task?.id && status){
            const result = await apiReassignSubmit(props?.task?.id, status);
            if(result !== null && props.updatedTaskList){            
                if(result?.status){
                    loaderSetting(false);
                    snackbarSetting(true, result?.message,"success");
                    setTaskName(result?.task?.taskName);
                    props?.updatedTaskList(result?.task);
                    setStatusobj(props?.auditorList?.find((item: any) => { return item.id === status}))
                }else{              
                    loaderSetting(false);
                    snackbarSetting(true, result?.message,"error");   
                }
                setStatusFlag(false)
                setStatusFlag1(true)
            }else{
                loaderSetting(false);
            }
        }
    }
    return { taskName, setOpen, statusFlag1, statusFlag, open, handleChange, statusObj, status, reassignSubmitted };
}