import * as React from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { IconButton, OutlinedInput } from '@mui/material'
import { ReactComponent as DownArrow } from '../Assests/Icons/Down Arrow.svg'
import typoStyles from '../Styles/component/Typography.module.css';
import { Clear } from '@mui/icons-material';

type CustomeSelectProps = {
    value: string | undefined,
    name: string,
    handleChange?: (e: string, n: string) => void
    children: React.ReactNode,
    placeHolder?: any,
    clearHandle?: any
}
export default function CustomeSelect({
    value,
    name,
    handleChange,
    children,
    placeHolder,
    clearHandle
}: CustomeSelectProps) {
    return (
        <Box sx={{ width: '195px' }}>
            <FormControl fullWidth sx={{display:"flex", flexDirection:"row"}}>
                <Select
                    displayEmpty
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    placeholder={placeHolder ? placeHolder: "select"}
                    input={
                        <OutlinedInput
                            placeholder={placeHolder ? placeHolder: "select"}
                            id="select-multiple-chip"
                        />
                    }
                    onChange={(e) => {
                        if (e.target.value && name && handleChange) {
                            handleChange(e.target.value, name)
                        }
                    }}
                    name={name}
                    IconComponent={(props) => (
                        (value && clearHandle) ? (
                            <IconButton onClick={()=> clearHandle(name)} sx={{marginRight:"10px"}}>
                              <Clear sx={{fontSize: 18}} />
                            </IconButton>
                          ) : <IconButton  {...props}>
                          <DownArrow />
                      </IconButton>
                    )}
                    sx={{
                        height: '40px',
                        alignSelf: 'end',
                        textAlign:"initial",
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        borderRadius: '4px',
                        width: '195px',
                    }}
                    renderValue={
                        value !== "" ? undefined : () => <span className={typoStyles.typoRobotoRegoular14}>{placeHolder ? placeHolder: "Select"}</span>
                      }
                >
                    {children}
                </Select>
              
            </FormControl>
        </Box>
    )
}
