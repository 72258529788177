import React from "react";
import { getEncounters } from "../api/audioAPIService";
import { VidewAudioReducer } from "../reducer/viewaudioReducer";
import { readFromURL } from "../FileService/fileread";
import { useDispatch, useSelector } from 'react-redux';
import { file_preview_open } from "../actions/authMonitorAction";
interface AudioProps {
    appointmentId: string | undefined | null,
    scheduleId: string | undefined | null,
    encounterId?: any
}
export function useViewAudio({ appointmentId, scheduleId, encounterId }: AudioProps) {
    const rootref = React.useRef(null);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const [ audioLoader, setAudioLoader ] = React.useState<boolean>(false);
    const [ loaderProgress, setLoaderProgress ] = React.useState<number>(0);
    const [openPreview, setOpenPreview] = React.useState(false);
    const [ encounter, setEncounter] = React.useReducer(VidewAudioReducer, []);
    const [selectedId, setSelectedId] = React.useState<number | null>(null);
    const [curSong, setCursong] = React.useState<any>(null);

    const [pagination, setPagination] = React.useState({
        pageSize: 10,
        pageNumber: 1
    });
    const dispatch = useDispatch();

    const toogleClosePreview=()=>{
        setOpenPreview(false)
        setAudioLoader(false)
        setSelectedId(null);
        dispatch(file_preview_open(false))
    }


    const handleExpandClick = async (data: any) => {       
        if (selectedId === data.id) {
            setSelectedId(null);
        }
        else {
            setAudioLoader(true)
            setLoaderProgress(0)
            setCursong(null)
            setSelectedId(data.id);
            setOpenPreview(true); 
            dispatch(file_preview_open(true))
            if (userToken) {
                const option = {
                    method: 'GET',
                    mode:'cors' as RequestMode,
                    headers: new Headers({
                        'Authorization': 'Bearer ' + userToken
                    })
                }
                
                const result = await readFromURL(data.filePathKey, option,setLoaderProgress);
                if (result) {
                    setCursong(result)
                    setAudioLoader(false)
                    
                }
            }
        }
    };

    

    const onScroll = () => {
        if (rootref.current) {
            const { scrollTop, scrollHeight, clientHeight } = rootref.current;
            if ((scrollTop) + clientHeight === scrollHeight) {
                setPagination({ ...pagination, pageSize: pagination.pageSize, pageNumber: pagination.pageNumber + 1 })
            }
        }
    };

    React.useEffect(() => {
        async function initiate() {
            const bodyParams = {
                pageSize: pagination.pageSize,
                pageNumber: pagination.pageNumber,
                appointmentId: appointmentId,
                scheduleId: scheduleId,
                encounterId: encounterId
            };
            const encounters: any = await getEncounters(bodyParams);
            if (encounters?.status) {
                setEncounter({ type: "add", payload: encounters?.result });
            }
        }
        initiate();
    }, [pagination.pageNumber]);

    return { selectedId, curSong, handleExpandClick, encounter, onScroll, rootref,loaderProgress,audioLoader,openPreview,toogleClosePreview};
}