import { TableCell, TableHead, TableSortLabel, TableRow } from '@mui/material'
import styles from '../Pages/TaskManager/TaskManager.module.css'
import { useTableService } from '../Services/taskManagerService/tableService'
import { useSelector } from 'react-redux'

interface Name {
    sortingObj: any
    handleRequestSort: (property: string) => void
    from?: any
}

const labelMap: { [key: string]: string } = {
    taskName: 'Job Name',
    location: 'Contract Client',
    city: 'City, State',
    site: 'Site of Service',
    speciality: 'Speciality',
    mrn: 'MRN',
    patientAccount: 'Patient Account',
    encounterCollection: 'Encounter Collection',
    appointmentType: 'Appointment Type',
    orgTid: 'Org. TIN',
    auditorName: 'Auditor',
    providerName: 'Provider Name',
    patientName: 'Patient Name',
    startDate: 'Encounter Date',
    endDate: 'End Date',
    status: 'Status',
    audio: 'Audio Name',
    transcript: 'Transcripts',
    fillablePdf: 'Progress Notes',
    document3: 'Document 3',
    comment: '',
    coding: 'Coding Form',
    TATtime: ' TAT',
    audioReceived: 'Audio Received',
}

export default function TableHeader(props: Name) {
    const { handleRequestSort, sortingObj, from } = props
    const { selectField } = useTableService({
        from: from,
    })
    const user = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )
    return (
        <TableHead>
            {selectField.field1 && (
                <TableRow
                    sx={{
                        background:
                            '#F7F8F9 0% 0% no-repeat padding-box !important',
                    }}
                >
                    {Object.values(selectField)?.map(
                        (item: any) =>
                            item &&
                            item.roles?.some(
                                (ro: any) => ro === user?.userRole,
                            ) && (
                                <TableHeaderField
                                    key={item.id}
                                    name={item.key}
                                    item={item}
                                    sortingObj={sortingObj}
                                    createsorthandler={handleRequestSort}
                                />
                            ),
                    )}

                    <TableHeaderField name="" />
                </TableRow>
            )}
        </TableHead>
    )
}

type TableHeaderFieldProps = {
    createsorthandler?: (value: string) => void
    name?: string
    sortingObj?: any
    item?: any
}

const TableHeaderField = ({
    createsorthandler,
    name,
    sortingObj,
    item,
}: TableHeaderFieldProps) => {
    const handleClick = (property: string) => {
        if (createsorthandler) {
            createsorthandler(property)
        }
    }
    // console.log('name', name)

    return (
        <TableCell
            className={styles.HeaderCell}
            size="small"
            component="th"
            align={name === 'status' || name === 'comment' ? 'center' : 'left'}
            sx={{
                whiteSpace: 'nowrap',
                ...((name === 'audio' ||
                    name === 'transcript' ||
                    name === 'fillablePdf' ||
                    name === 'document3') && { textAlign: 'left' }),
            }}
        >
            {name &&
                name !== 'comment' &&
                name !== 'audio' &&
                name !== 'transcript' &&
                name !== 'fillablePdf' &&
                name !== 'document3' && (
                    <TableSortLabel
                        active={true}
                        direction={sortingObj[name] === -1 ? 'desc' : 'asc'}
                        onClick={() => handleClick(name)}
                    >
                        {item.label}
                    </TableSortLabel>
                )}
            {name &&
                (name === 'comment' ||
                    name === 'audio' ||
                    name === 'transcript' ||
                    name === 'fillablePdf' ||
                    name === 'document3') &&
                item.label}
        </TableCell>
    )
}
