import React from 'react';
import styles from './Conversation.module.css';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import { TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as SendIcon } from '../../../../Assests/Icons/Icons svg/Send.svg'
import ChatNavigation from '../ChatNavigation/ChatNavigation';
import ChatUserInfo from '../ChatUserInfo/ChatUserInfo';
import ChatUserAvator from '../ChatUUserAvator/ChatUserAvator';
import { ReactComponent as Export } from '../../../../Assests/Icons/Icons svg/Export.svg';
import Message from '../Message/Message';
import moment from 'moment';
import { ReactComponent as Attachemnticon } from '../../../../Assests/Icons/Icons svg/Attachemnt icon.svg';
import { useConversation } from '../../../../Services/chatService/conversationService';
import ShadowDrawer from '../../../../Components/ShadowDrawer/ShadowDrawer';
import ProgressLoader from '../../../../Components/ProgressLoader/ProgressLoader';
import InvalidMessage from '../../../../Components/InvalidMessage/InvalidMessage';
import { attachMentErrmsg } from '../../../../Services/utils/ImageUtil';
import AudioPlayer from '../../../../Components/AudioPlayer/AudioPlayer';
import VideoPlayer from '../../../../Components/VideoPlayer/VideoPlayer';
import ImagePreview from '../../../../Components/ImagePreview/ImagePreview';
import PDFviewer from '../../../../Components/PDFviewer/PDFviewer';
import CSVViewer from '../../../../Components/CSVViewer/CSVViewer';
import DocViewer from '../../../../Components/DocViewer/DocViewer';
import { useDispatch, useSelector } from 'react-redux';
import PreviewField from '../PreviewField/PreviewField'
import { file_preview_open } from '../../../../Services/actions/authMonitorAction';

interface Props {
  taskId: string,
  lastChat: any,
  socket: any,
  auditorId: any,
  managerId: any,
  providerId: any,
  setFlagShowMsg: any,
  subjectData: any,
  appointmentId: any,
  preFlag: any,
  setPreFlag: any,
  fillablePdf?: any,
  openConfirm?:any
}

const Conversation = (props: Props) => {
  const { taskId, setFlagShowMsg, subjectData, preFlag, setPreFlag, } = props;
  const { file, send, setsend, isVideo, handleClose, uploadedFile, showButton, showimage, filetypes, resultstatus, totalCount, fileName, cancelrequest, progress, setUploadModel, uploadModel, submitMsg, text, handleChange, msgList, onScroll, currmsgList, msgref, patientData, userData, isMessage, uploadAttachment } = useConversation(props);
  const userId = useSelector((state: any) => state.persistReducers?.userReducer?.user?.id);

  const subRef = React.useRef<any>(null);
  const textheight = React.useRef<HTMLInputElement>(null);
  const [textfiledheight, setTextFiledHeight] = React.useState<any>(90);
  const [openPreview, setOpenPreview] = React.useState<number | null>(null);
  const [msg, setMsg] = React.useState<any>(null);
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState<any>();

  React.useEffect(() => {
    const element = textheight?.current;
    if (!element) return;
    const observer = new ResizeObserver(() => {
      if (textheight?.current?.clientHeight) {
        setTextFiledHeight(34 + textheight?.current?.clientHeight)
      }
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [textfiledheight])

  function startsequence(prevBySameAuthor: any, previousDuration: any, previous: any) {
    return prevBySameAuthor && previousDuration.as('minutes') < 1 && previous.type === 'text';
  }
  function nextMessage(next: any) {
    return next && next.type === 'text'
  }
  const tooglePreview = (data: any, flag: number) => {
    if (data) {
      setMsg(data);
    }
    dispatch(file_preview_open(true))
    setOpenPreview(flag);
  }
  const toogleClosePreview = () => {
    setMsg(null);
    dispatch(file_preview_open(false))
    setOpenPreview(null);
  }

  function renderMessage(data: any, cur: any,) {

    let i = 0;
    const messageCount = data?.length;
    const lstEl = cur[cur.length - 1];
    const tempMessages = [];

    while (i < messageCount) {
      const previous = data[i - 1];
      const current = data[i];

      const next = data[i + 1];
      const isMine = current?.createdBy === userId;
      const currentMoment = moment(current?.createdAt);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      let lastEl = false;
      if (previous) {
        const previousMoment = moment(previous.createdAt);
        const previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous?.createdBy === current?.createdBy;
        if (startsequence(prevBySameAuthor, previousDuration, previous)) {
          startsSequence = false;
        }
        if (previousDuration.as('days') < 1) {
          showTimestamp = false;
        }
      }
      if (nextMessage(next)) {
        const nextMoment = moment(next.createdAt);
        const nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next?.createdBy === current?.createdBy;
        if (nextBySameAuthor && nextDuration.as('minutes') < 1) {
          endsSequence = false;
        }
      }
      lastEl = current?.id === lstEl?.id;

      tempMessages.push(
        <Message
          key={i}
          type={current?.type}
          isMine={isMine}
          tooglePreview={tooglePreview}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          item={current}
          lastEl={lastEl}
          resultstatus={resultstatus}



        />
      );
      i += 1;
    }
    return tempMessages;
  }
  const navigationClick = React.useCallback(() => {
    if (preFlag) {
      setFlagShowMsg(1)
      setPreFlag(false)
    } else {
      if (isMessage) {
        setFlagShowMsg(1)
        setPreFlag(false)
      } else {
        setFlagShowMsg(2)
        setPreFlag(false)
      }
    }
  }, [isMessage]);

  const onKeyDown = React.useCallback((e: any) => {

    const trimmedText = text.trim();
    if (e.key === 'Enter' && !e.shiftKey) {
      if (trimmedText === '' && showButton || showimage) {

        setsend(send);

        submitMsg();
      }
      else if (trimmedText === '') {
        e.preventDefault();
      }

      else {
        e.preventDefault();
        submitMsg();
      }
    }



  }, [text, send, submitMsg, showButton, showimage]);



  const onSubmit = React.useCallback(() => {

    setsend(send);

    submitMsg();
  }, [send, submitMsg]);

  const invalidOnclick = React.useCallback(() => {
    setUploadModel(null)
  }, [])

  function submitvalidation(text: any) {
    if (text === '') {
      return true;
    }
    return text.replace(/\s/g, '').length !== 0 ? false : true;
  }

  const navigationClickRepo = () => {
    setFlagShowMsg(5)
    setPreFlag(false)
  }


  React.useEffect(() => {
    const element = subRef?.current;
    if (!element) return;

    const observer = new ResizeObserver(() => {
      if (subRef?.current?.clientHeight) {
        const newMaxHeight = isCollapsed
          ? `calc(100vh - ${132 + subRef?.current?.clientHeight + textfiledheight}px)`
          : `calc(100vh - ${126 + subRef?.current?.clientHeight + textfiledheight}px)`;

        setMaxHeight(newMaxHeight);
      }
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [isCollapsed, subRef?.current?.clientHeight, textfiledheight]);



  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };


  return (
    <div>
      <ChatNavigation navigationClick={navigationClick}>

        <span className={typoStyles.typoHelveticalNormalprimary216234} >{subjectData?.name}</span>

        <div className={styles.navDiv} >
          <ChatUserAvator data={userData} />
        </div>


      </ChatNavigation>


      <div ref={subRef}>
        <ChatUserInfo patientData={patientData} taskId={taskId} isCollapsed={isCollapsed} onCollapseToggle={handleCollapseToggle} count={totalCount} navigationClickRepo={navigationClickRepo} />
      </div>

      {msgList?.length > 0 &&
        <div style={{
          maxHeight: maxHeight,
          marginBottom: `${textfiledheight}px`
        }} className={styles.rootmsg} ref={msgref} onScroll={onScroll}>
          {renderMessage(msgList, currmsgList)}
        </div>}

      <div className={styles.cmtSendDiv}>
        <TextField
          autoComplete="off"
          ref={textheight}
          fullWidth
          multiline
          maxRows={'4'}
          value={text}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          type='text'
          autoFocus
          disabled={showButton || showimage}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ marginRight: 0 }}
              >

                <PreviewField
                  showimage={showimage}
                  showButton={showButton}
                  filetypes={filetypes}
                  uploadedFile={uploadedFile}
                  handleClose={handleClose}
                  file={file}
                  isVideo={isVideo}
                />

              </InputAdornment>
            ),

            endAdornment: (

              <InputAdornment
                position="start"
                sx={{ marginRight: 0 }}
              >

              <Tooltip title={"Upload File"}>
                <IconButton className={styles.iconBtn} component="label" size='small'>
                  <Attachemnticon width={40} height={40} style={{ position: "fixed" }} />
                  <input id='file' name='fileupload' hidden type="file" accept='image/jpg, image/jpeg, image/png, text/csv,  application/pdf, video/mp4, audio/mp3, audio/aac, audio/x-m4a, audio/mpeg, application/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document, audio/vnd.dlna.adts' multiple={false} onChange={(e) => uploadAttachment(e)} />
                </IconButton>
                </Tooltip>
                <Tooltip title={"Send"}>
                <IconButton
                  type='submit'
                  disabled={!showButton && !showimage && submitvalidation(text)}
                  className={styles.sendIcon}
                  size="small"
                  onClick={onSubmit}>
                  <SendIcon width={40} height={40} style={{ position: "fixed" }} />
                </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}

          inputProps={{
            maxLength: 2000,
            sx: {
              "&::placeholder": {
                fontFamily: 'RobotoItalic, sans-serif !important',
                fontSize: ' 1rem !important',
                lineHeight: '1.375rem !important',
                letterSpacing: '-0.32px !important',
                color: '#959DAA  !important',
                opacity: 1
              }
            }
          }}
          sx={{

            justifyContent: 'center',
            '& .MuiInputBase-root': {
              border: 0,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 0,
                borderWidth: 'inherit !important',
              },
            },
          }}
          className={styles.cmtSendtextfd}
          style={{
            minHeight: showimage || showButton ? '80px' : '55px',
          }}
          placeholder={showimage || showButton ? "" : "Type Something....."}
        />
         <Tooltip title={"Export"}>
        <IconButton className={styles.iconBtn} size='small' >
          <Export width={40} height={40} style={{ position: "fixed" }} />
        </IconButton>
        </Tooltip>
      </div>
      {openPreview === 0 && <ShadowDrawer openDrawer={openPreview === 0 ? true : false} > <AudioPlayer item={msg} toogleAudio={toogleClosePreview} /></ShadowDrawer>}
      {openPreview === 1 && <ImagePreview item={msg} open={openPreview === 1 ? true : false} handleClose={toogleClosePreview} />}
      {openPreview === 2 && <VideoPlayer item={msg} open={openPreview === 2 ? true : false} handleClose={toogleClosePreview} />}
      {openPreview === 3 && <PDFviewer item={msg} open={openPreview === 3 ? true : false} handleClose={toogleClosePreview} />}
      {openPreview === 4 && <CSVViewer item={msg} open={openPreview === 4 ? true : false} handleClose={toogleClosePreview} />}
      {openPreview === 5 && <DocViewer item={msg} open={openPreview === 5 ? true : false} handleClose={toogleClosePreview} />}

      <ShadowDrawer openDrawer={(uploadModel === 1 || uploadModel === 2 || uploadModel === 3 || uploadModel === 4) ? true : false}>
        {uploadModel === 1 && <ProgressLoader label={fileName} value={progress} cancelrequest={cancelrequest} />}
        {(uploadModel === 2 || uploadModel === 3 || uploadModel === 4) && <InvalidMessage message={attachMentErrmsg(uploadModel)} onclick={invalidOnclick} />}
      </ShadowDrawer>
    </div>
  )
}

export default Conversation;
