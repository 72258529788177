import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import typoStyles from '../../../../Styles/component/Typography.module.css'
import commentStyles from '../../../../Styles/component/CommentStyle.module.css'
import { Button, InputAdornment, TextField, Tooltip } from '@mui/material'
import { ReactComponent as Upload } from '../../../../Assests/Icons/Upload.svg'
import { ReactComponent as Cross } from '../../../../Assests/Icons/Cross.svg'
import moment from 'moment';
import UserAvatar from '../UserAvatar'
import { useComment } from '../../../../Services/commentService/commentService';
import styles from './comments.module.css';
import { ReactComponent as SendIcon } from '../../../../Assests/Icons/Icons svg/Send.svg'
import DrawerPlaceHolder from '../../../../Components/DrawerPlaceHolder/DrawerPlaceHolder'
import { useSelector } from 'react-redux'
type CommentProps = {
  menuHandleCloseClick?: any,
  task?: any,
  showHeader?: boolean,
  updatedTaskList?: any
}
export default function Comments(props: CommentProps) {
  const [expanded, setExpanded] = React.useState<number | null>(null)
  const { taskState, setEditReplyId, setText, setReplyText, replyFlag, setReplyFlag, submitEditReply, submitReply, handleChangereply, replyText, comment, handleChangeComment, newText, submitComment } = useComment(props);
  const userId = useSelector((state: any) => state.persistReducers?.userReducer?.user?.id);
  return (<>
    <div className={styles.Comments}>
      <div className={styles.header}>
        <span className={typoStyles.typoRobotoRegoularprimary31422}>{"Comments"}</span>
      </div>
      <div className={styles.commentsDiv} style={{ overflowX: "hidden", overflowY: 'auto', height: `calc(100vh - ${taskState?.status !== 'claim submitted' ? 230 : 150}px)` , position: "relative", width: "100%", paddingLeft: "15px", paddingRight: "15px" }}>
        {(comment) ? comment?.map((item: any, index) => (
          <Card key={item.id} className={commentStyles.comment} elevation={0}>
            <CardContent className={styles.cardComment}>
              <div className={styles.cardCommentDiv}>
                {item?.createdBy &&
                  <UserAvatar
                    name={item.createdBy[0].firstName + " " + item.createdBy[0].lastName}
                  >
                    <Typography className={typoStyles.typoRobotoMediumprimary214}>
                      {item.createdBy[0].firstName.charAt(
                        0,
                      )}
                    </Typography>
                  </UserAvatar>}
              </div>
              <Typography
                variant="body2"
                color="text.secondary"
                className={typoStyles.typoRobotoRegoularprimary316break}
              >
                {item?.text !== '' ? item?.text : item?.attachments?.path}
              </Typography>
            </CardContent>
            <CardActions className={styles.cardAction}>
              <div className={styles.cardActionDiv}>
                <Typography className={typoStyles.typoRobotoRegoularprimary6114}>
                  {moment(item?.createdAt).format('LL')}
                </Typography>
                <Typography className={typoStyles.typoRobotoRegoularprimary6114}>
                  {moment(item?.createdAt).format('LT')}
                </Typography>
              </div>
              {expanded !== index && item.reply.length === 0 && taskState?.status !== 'claim submitted' && (
                <Button
                  onClick={() => setExpanded(index)}
                  variant="text"
                  sx={{ textTransform: 'initial' }}
                >
                  <Typography className={typoStyles.typoRobotoMediumprimary814}>
                    {'Reply'}
                  </Typography>
                </Button>
              )}
            </CardActions>
            {item.reply.length >= 1 && (
              item?.reply?.map((rely: any, indx: number) => (
                <CardContent key={rely.id} sx={{ padding: "2px 20px 5px 20px !important" }}>
                  <div className={styles.cardContentDiv}>
                    <div className={styles.cardContentPrimaryDiv}>
                      {userId === rely.createdBy.id ? <Typography className={typoStyles.typoRobotoBoldprimary71419}>
                        {'You'}
                      </Typography> :
                        <UserAvatar
                          name={rely.createdBy.firstName + ' ' + rely.createdBy.lastName}
                        >
                          <Typography className={typoStyles.typoRobotoMediumprimary214}>
                            {rely.createdBy.firstName.charAt(0)}
                          </Typography>
                        </UserAvatar>}
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className={typoStyles.typoRobotoRegoularprimary316break}
                      >
                        {rely?.text}
                      </Typography>
                    </div>
                  </div>
                  <CardActions className={styles.cardAction}>
                    <div className={styles.cardEditActionDiv}>
                      <Typography className={typoStyles.typoRobotoRegoularprimary6114}>
                        {moment(rely?.createdAt).format('LL')}
                      </Typography>
                      <Typography className={typoStyles.typoRobotoRegoularprimary6114}>
                        {moment(rely?.createdAt).format('LT')}
                      </Typography>
                    </div>
                    {expanded !== index && taskState?.status !== 'claim submitted' && <div>
                      {rely.createdBy.id === userId && indx + 1 === item.reply.length &&
                        <Button
                          onClick={() => {
                            setReplyFlag(true)
                            setEditReplyId(rely.id)
                            setReplyText(rely.text)
                            setExpanded(index)
                          }}
                          variant="text"
                          sx={{ textTransform: 'initial' }}
                        >
                          <Typography className={typoStyles.typoRobotoMediumprimary814}>
                            {'Edit'}
                          </Typography>
                        </Button>}
                      {indx + 1 === item.reply.length &&
                        <Button
                          onClick={() => {
                            setReplyFlag(false)
                            setExpanded(index)
                            setReplyText('')
                            setText('')
                          }}
                          variant="text"
                          sx={{ textTransform: 'initial', justifyContent: "flex-end", marginRight: "-10px" }}
                        >
                          <Typography className={typoStyles.typoRobotoMediumprimary814}>
                            {'Reply'}
                          </Typography>
                        </Button>}
                    </div>}
                  </CardActions>
                </CardContent>
              ))
            )}
            <Collapse
              in={expanded === index}
              timeout="auto"
              unmountOnExit
            >
              <CardContent sx={{ padding: "10px 14px 12px 14px !important" }}>
                <div
                  className={commentStyles.expandcard}
                  style={{ position: 'relative' }}
                >
                  <div className={commentStyles.btndiv}>
                  <Tooltip title="Close">
                    <IconButton
                      onClick={() => setExpanded(null)}
                    >
                      <Cross />
                    </IconButton>
                    </Tooltip>
                  </div>
                  <TextField
                    fullWidth
                    value={replyText}
                    onChange={handleChangereply}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        if (taskState?.id) {                                
                          if (!replyFlag && replyText !== '' && replyText.replace(/\s/g, '').length !== 0) {
                            submitReply(item?.id)
                          } else {
                            submitEditReply()
                          }
                        }
                        setExpanded(null)
                      }
                    }}
                    inputProps={{
                      maxLength: 2000,
                      sx: {
                        "&::placeholder": {
                          fontFamily: 'RobotoItalic, sans-serif !important',
                          fontSize: ' 1rem !important',
                          lineHeight: '1.375rem !important',
                          letterSpacing: '-0.32px !important',
                          color: '#959DAA  !important',
                          opacity: 1
                        }
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginRight: 0 }}
                        ><Tooltip title="Submit Reply">
                          <IconButton
                            size="small"
                            disabled={replyText !== '' && replyText.replace(/\s/g, '').length !== 0 ? false : true}
                            onClick={() => {
                              if (taskState?.id) {                                
                                if (!replyFlag && replyText !== '' && replyText.replace(/\s/g, '').length !== 0) {
                                  submitReply(item?.id)
                                } else {
                                  submitEditReply()
                                }
                              }
                              setExpanded(null)
                            }}
                          >
                            <Upload />
                          </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      justifyContent: 'center',
                      '& .MuiInputBase-root': {
                        '& .MuiOutlinedInput-notchedOutline':
                        {
                          border: 0,
                          borderWidth:
                            'inherit !important',
                        },
                      },
                    }}
                    className={commentStyles.textfd}
                    placeholder="Reply"
                  />
                </div>
              </CardContent>
            </Collapse>
          </Card>
        )): <DrawerPlaceHolder record={[]}/>}
      </div>
      {taskState?.status !== 'claim submitted' &&
        <div className={styles.cmtSendDiv} >
          <TextField
            fullWidth
            value={newText}
            onKeyDown={(e) => {
              const trimmedText = newText.trim();
              if (e.key === 'Enter' && !e.shiftKey) {
                if (trimmedText === '') {
                  e.preventDefault();
                } else {
                  e.preventDefault();
                  submitComment();
                }
              }
            }}
            multiline
            maxRows={'4'}
            autoComplete="off"
            inputProps={{
              maxLength: 2000,
              sx: {
                "&::placeholder": {
                  fontFamily: 'RobotoItalic, sans-serif !important',
                  fontSize: ' 1rem !important',
                  lineHeight: '1.375rem !important',
                  letterSpacing: '-0.32px !important',
                  color: '#959DAA  !important',
                  opacity: 1
                }
              }
            }}
            onChange={handleChangeComment}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ marginRight: 0 }}
                ><Tooltip title="Submit Comment">
                  <IconButton className={styles.sendIcon} disabled={newText !== '' && newText.replace(/\s/g, '').length !== 0 ? false : true} size="small" onClick={() => {                    
                    if (taskState?.id && newText !== '' && newText.replace(/\s/g, '').length !== 0) {
                      submitComment()
                    }
                  }}>
                    <SendIcon width={40} height={40} style={{ position: "fixed" }} />
                  </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={{
              justifyContent: 'center',
              '& .MuiInputBase-root': {
                border: 0,
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                  borderWidth: 'inherit !important',
                },
              },
            }}
            className={styles.cmtSendtextfd}
            placeholder="Type Something....."
          />
        </div>}
    </div>
  </>
  )
}
