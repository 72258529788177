import * as React from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { Select } from '@mui/material'
import { statusTagMenuItem } from '../Services/utils/constant'

type DropdownProps = {
    value: string
    name: string
    handleChange?: (e: string, n: string) => void
    children: React.ReactNode
    readOnlyFalg?: boolean
}
export default function StatusDropdown({
    value,
    name,
    handleChange,
    children,
    readOnlyFalg,
}: DropdownProps) {
    const data = statusTagMenuItem?.find((item: any) => item.key === value)

    return (
        <Box sx={{ minWidth: 165 }}>
            <FormControl fullWidth style={{ padding: '0px 20px 0px 20px' }}>
                <Select
                    name={name}
                    readOnly={readOnlyFalg ? true : false}
                    inputProps={{
                        readOnly: value === 'claim submitted' ? true : false,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    onChange={(e) => {
                        if (e.target.value && name && handleChange) {
                            handleChange(e.target.value, name)
                        }
                    }}
                    sx={{
                        height: '31px',
                        borderRadius: '4px',
                        opacity: 1,
                        width: '100%',
                        minWidth: '165px',
                        border: 0,
                        background: `${data?.divPrimary} !important`,
                        '& .MuiInputBase-root': {
                            border: 0,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 0,
                            borderWidth: 'inherit !important',
                        },
                    }}
                >
                    {children}
                </Select>
            </FormControl>
        </Box>
    )
}
