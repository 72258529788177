import dayjs from 'dayjs';

export const initiateState = {
    taskName: "",
    auditorId:"",
    orgTid:"",
    providerId:"",
    speciality:"",
    appointmentId:"",
    patientdob:dayjs(new Date()),
    patientGender:"",
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    location:"",
    mrn:"",
    patientAccount:"",
    scheduleId:"",
    status:"",
    id:"",
    type:"",
    providerName:"",
    patientName:""
}

export const editTaskReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'add': {
            const key = Object.prototype.hasOwnProperty.call(initiateState, action.field);
            return key ? { ...state, [action.field]: action.payload} : { ...state };
        }
        case 'removeall': { 
            return action.payload;
        }
        default:
            return state;
    }
}
