import { IconButton, Paper } from '@mui/material';
import React, { FC } from 'react';
import ProgressbarLinear from '../ProgressbarLinear/ProgressbarLinear';
import styles from './ProgressLoader.module.css';
import CloseIcon from '@mui/icons-material/Close';
import typoStyles from '../../Styles/component/Typography.module.css';

interface ProgressLoaderProps { 
	label: string,
	value: any,
	cancelrequest: any,
	
}

const ProgressLoader: FC<ProgressLoaderProps> = ({ label, value, cancelrequest, }) => (

	
	<div className={styles.ProgressLoader}>
		<Paper className={styles.progressDiv}>
			<div className={styles.progressSecDiv}>
				<div className={styles.lableDiv}>
					<span className={[`${typoStyles.typoRobotoRegoularprimary7160} ${styles.fNStyle}`].join("")}>{label}</span>
					<span className={typoStyles.typoRobotoRegoularprimary7160}>{Math.round(value)}{"%"}</span>
				</div>
				<div className={styles.barDi}>
					<ProgressbarLinear value={value} />
				</div>
			</div>
			<div className={styles.barDiv}>
			{value < 100 && <IconButton size='small' onClick={(e) => cancelrequest(e)}>

					<CloseIcon sx={{ height: "14px", width: "14px", color: "black", fontWeight: "bold" }} />
				</IconButton>}
			</div>
		</Paper>
	</div>
);

export default ProgressLoader;
