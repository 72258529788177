import React from 'react'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider';
import { fileCount, searchFiles } from "../api/filecountAPIService";
import { fileReducer } from '../reducer/fileReducer';

interface useFileProps {
    providerId?: any
    subjectData?: any
    appointmentId?: any
}

export function useFile({ providerId, subjectData, appointmentId }: useFileProps) {

    const [pagination, setPagination] = React.useState({
        pageSize: 10,
        pageNumber: 1
    });
    const [fileData, setfileData] = React.useReducer(fileReducer, []);
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const [loader, setloader] = React.useState(false)
    const rootref = React.useRef<any>(null);
    const [searchValue, setSearchValue] = React.useState('');

    const onScroll = () => {
        if (rootref.current) {
            const { scrollTop, scrollHeight, clientHeight } = rootref.current;
            if ((scrollTop) + clientHeight === scrollHeight) {
                setPagination({ ...pagination, pageSize: pagination.pageSize, pageNumber: pagination.pageNumber + 1 })
            }
        }
    };

    React.useEffect(() => {
        async function initiate() {
            loaderSetting(true)
            getFiles()
            loaderSetting(false)
        }
        initiate();
    }, [pagination.pageNumber,]);

    const getFiles = async() => {
        const bodyParams = {
            appointmentId: appointmentId,
            providerId: providerId,
            subjectId: subjectData,
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageNumber,
            fileType: "file",
            count: "false",

        };
        const response = await fileCount(bodyParams) as { details: any };        
        if (response?.details?.length) {
            setfileData({ type: "add", payload: response.details });
            setloader(true)
        } 
    }
    const handleFileSearch = async (e: any) => {

        setSearchValue(e.target.value);
        const bodyParams = {
          appointmentId: appointmentId,
          providerId: providerId,
          subjectId: subjectData,
          fileType: 'file',
          searchValue: e.target.value
        };
        if (searchValue !== '') {
          const response = await searchFiles(bodyParams) as { payload: any };         
          if (response?.payload?.length > 0) {
            const arrY: any = [];
            response?.payload[0]?.attachments?.map((item: any) =>{
              const data = {
                attachments: item,
                createdAt: response?.payload[0].createdAt,
                id: response?.payload[0].id
              };
              arrY.push(data)
            } )
            setloader(true)
            setfileData({ type: 'addNew', payload: arrY});
          } else {
            setloader(false)
          }
        } else {
          getFiles()
        }
      }
    return { searchValue, handleFileSearch, onScroll, rootref, fileData, loader }
}

