import { FC } from 'react';
import styles from './AudioPlayer.module.css';
import { ReactComponent as CrossIcon } from '../../Assests/Icons/Cross icon (4).svg';
import { ReactComponent as PauseIcon } from '../../Assests/Icons/Pause icon.svg';
import { ReactComponent as ForwardIcon } from '../../Assests/Icons/Fast forward.svg';
import { ReactComponent as BackwardIcon } from '../../Assests/Icons/Fast forward (1).svg';
import { ReactComponent as PlayIcon } from '../../Assests/Icons/Icons svg/Play icon.svg';
import { CircularProgress, IconButton, Slider, Tooltip } from '@mui/material';
import typoStyles from '../../Styles/component/Typography.module.css';
import { useAudioPlayer } from '../../Services/audioPlayerService/audioPlayer';
import { ReactComponent as FastForwardIcon } from '../../Assests/Icons/Forward icon.svg';
import { ReactComponent as FastBackwardIcon } from '../../Assests/Icons/Backward icon.svg';
import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel';
import { ReactComponent as Audiobroken } from '../../Assests/Icons/Audio broken.svg';

interface AudioPlayerProps {
  item?: any,
  toogleAudio: any
}

const AudioPlayer: FC<AudioPlayerProps> = ({ item, toogleAudio }) => {
  const { audioErr, onError, onEnded, farward, fastbackward, onloadimage, audioLoader, getTime, audioref, progress, fastFarward, backward, handleProgress, tooglePlay, onProgress, onTimeUpdate, audioUrl,isPlaying, loaderProgress } = useAudioPlayer({item});
  const loderComponent = (value: any, audioErr: any) => {
    if(value > 0 && !audioErr){
        return  <CircularProgressWithLabel value={value} />;
    }
    return audioErr ? <AudioErr /> : <CircularProgress />;
}
  return (
    <div className={styles.AudioPlayer}>
       <Tooltip title={"Close"}>
      <IconButton onClick={toogleAudio} className={styles.cross_Btn}>
        <CrossIcon className={styles.crossIcon}/>
      </IconButton>
      </Tooltip>
      {(!audioLoader) ? <div className={styles.audio_root}>
        <div className={styles.name_root}>
          <span className={typoStyles.typoRobotoNormalprimary121621}>{item.attachments.fileName}</span>
        </div>
        <div>
          <Slider
            min={0}
            max={100}
            value={progress}
            onChange={handleProgress}
            aria-label="Song"
            sx={{
              width: "100%",
              color: '#D3D3D3',
              height: "8px",
              paddingTop:"10px",
              paddingBottom:"0px",
              '& .MuiSlider-track': {
                border: 'none',
                color: "#427BE0",
              },
              '& .MuiSlider-thumb': {
                display: "none"
              },
            }}
          />
           <div className={styles.sliderTimer}>
              <span className={[`${styles.curTime} ${typoStyles.typoRobotoRegularprimary121417}`].join(" ")}>
                  {getTime(audioref?.current?.currentTime || 0.0)}
              </span>
              <span className={[`${styles.durTime} ${typoStyles.typoRobotoRegularprimary121417}`].join(" ")}>
                  {getTime(audioref?.current?.duration || 0.0)}
              </span>
          </div>
        </div>
        <div className={styles.control_btn_root}>
        <Tooltip title={"Fast Backward"}>
          <IconButton onClick={fastbackward} disableRipple className={styles.control_btn}>
          <FastBackwardIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title={"Backward"}>
          <IconButton onClick={backward} disableRipple className={styles.control_btn}>
            <ForwardIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title={!isPlaying? "Play": "Pause"}>
          <IconButton onClick={tooglePlay} disableRipple className={styles.control_btn}>
            {isPlaying ? <PauseIcon className={styles.PlayIcon} /> : <PlayIcon className={styles.PlayIcon} /> }
          </IconButton>
          </Tooltip>
          <Tooltip title={"Forward"}>
          <IconButton onClick={farward} disableRipple className={styles.control_btn}>
            <BackwardIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title={"Fast Forward"}>
          <IconButton onClick={fastFarward} disableRipple className={styles.control_btn}>
            <FastForwardIcon />
          </IconButton>
          </Tooltip>
        </div>
      </div> : <div className={styles.loder_rootDiv}>
      {loderComponent(loaderProgress, audioErr)}
        </div>
      }
      {audioUrl && <audio crossOrigin='anonymous' onError={() => onError()} onEnded={() => onEnded()} onCanPlay={() => onloadimage()} onTimeUpdate={onTimeUpdate} onProgress={onProgress} controls={false} src={audioUrl} ref={audioref} />}
    </div>
  )
};

export default AudioPlayer;

const AudioErr = () => {
  return (<div className={styles.brokenPlaceholder}>
    <Audiobroken />
    <span className={typoStyles.typoRobotoMediumprimary742042}>{"Oops! Something went wrong"}</span>
  </div>)
}