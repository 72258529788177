import React, { FC } from 'react';
import styles from './AttachmentMessage.module.css';
import { ReactComponent as PlayIcon } from '../../../../Assests/Icons/Icons svg/Play icon.svg';
import { IconButton, Button, CircularProgress, ButtonBase } from '@mui/material';
import { ReactComponent as Playicon } from '../../../../Assests/Icons/Icons svg/Playicon.svg';
import typoStyles from '../../../../Styles/component/Typography.module.css';
import { fileNametag, renderImg, typeCheck, typeimageCheck } from '../../../../Services/utils/ImageUtil';
import { readFileFromURL } from '../../../../Services/FileService/fileread';
import imagePlaceHolder from '../../../../Assests/Icons/Group 17535.svg';
import videoPlaceHolder from '../../../../Assests/Icons/Group 17536.svg';
import { useSelector } from 'react-redux';

interface AttachmentMessageProps {
	Filename: string,
	isMine: boolean,
	type: any,
	item: any,
	tooglePreview: any,
}

const AttachmentMessage: FC<AttachmentMessageProps> = ({ Filename, isMine, type, item, tooglePreview }) => {
	const [loader, setLoader] = React.useState(false);
	const [imgErr, setImgErr] = React.useState(false);
	const [imgurl, setImgurl] = React.useState<any>('');
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);

	const onloadImage = () => {
		setLoader(true);
		URL.revokeObjectURL(imgurl)
	}
	React.useEffect(() => {
		async function initiate() {
			if(userToken && item.attachments?.thumbnailObj){							
			const filepath = item.attachments.thumbnailObj.relativePath;
			const option = {
				method: 'GET',
                mode:'cors' as RequestMode,
				headers: new Headers({
					'Authorization': 'Bearer ' + userToken
				})
			}
			const result = await readFileFromURL(filepath, option);
			if (result) {
				setImgurl(result)
			}
		}
		}
		initiate();
	}, []);
	const errorImage = (e: any, type: string) => {
		if(type !== 'mp4' ){
			e.target.src = imagePlaceHolder;
		}else{
			e.target.src = videoPlaceHolder;
		}
		setImgErr(true);
	}
	const thumbnailStyle = isMine ? `${styles.startAttachment}` : `${styles.endAttachment}`;
	const fileStyle = isMine ? `${styles.startAttachmentMessage}` : `${styles.endAttachmentMessage}`;
	if (typeimageCheck(type.toLowerCase())) {
		return (
			<div className={[`${thumbnailStyle}`, `${styles.curPoi}`].join(" ")}>
				<Button disabled={imgErr} onClick={() => {
					if(!imgErr){					
					if(type === 'mp4'){						
						tooglePreview(item, 2)
					}else{
						tooglePreview(item, 1)
					}
					}
				}} className={styles.mediaDiv}>
					{(imgurl !== '' && imgurl) && <img
						onError={(e) => errorImage(e, type.toLowerCase())}
						key={item.id}
						onLoad={() => onloadImage()}
						crossOrigin='anonymous'
						draggable={false}
						src={imgurl}
						alt=''
						className={styles.imagessx}
						height={176}
					/>}
					{!loader && <div className={styles.imageContainerOverlay}><CircularProgress /></div>}
				</Button>
				{(type === 'mp4' && loader && imgurl !== '' && imgurl && !imgErr) && <Button onClick={() => {					
					if(type === 'mp4'){						
						tooglePreview(item, 2)
					}else{
						tooglePreview(item, 1)
					}
				}} className={styles.playIcon}>
					<IconButton onClick={() => tooglePreview(item, 2)}>
						<Playicon />
					</IconButton>
				</Button>}
			</div>)
	} else {
		return (
			<ButtonBase onClick={() => {								
				if(typeCheck(type)){
					tooglePreview(item, 0);
				}else if(type === 'pdf'){					
					tooglePreview(item, 3);
				}else if(type === 'csv'){
					tooglePreview(item, 4);
				}else{
					tooglePreview(item, 5);
				}
			}} className={[`${fileStyle}`, `${styles.curPoi}`].join(" ")}>
				<div className={styles.root}>
					<div className={styles.primaryRoot}>
						<img draggable={false} src={renderImg(type)} alt='attachment' width={28} height={28} />
						<span className={`${typoStyles.typoRobotoRegoularprimary71621} ${styles.textWrap}`}>{fileNametag(Filename, type)}</span>
					</div>
					{typeCheck(type) && <div>
						<IconButton onClick={() => tooglePreview(item, 0)} size='small' sx={{ padding: 0, marginRight: '10px' }}>
							<PlayIcon />
						</IconButton>
					</div>}
				</div>
			</ButtonBase>
		)
	}
};

export default AttachmentMessage;
