import React from 'react';
import Drawer from '@mui/material/Drawer'
import { Divider } from '@mui/material'

const drawerWidth = 462
type RightSideDrawerprops = {
    openDrawer: boolean
    children: React.ReactNode
    showShodow?:boolean,
    zIndex?:any,
    onClose?:any
}
export default function RightSideDrawer({
    openDrawer,
    children,
    showShodow,
    zIndex,
    onClose
}: RightSideDrawerprops) {

    return (
        <Drawer
            open={openDrawer}
            onClose={onClose}
            SlideProps={{
                unmountOnExit: true
              }}
            sx={{
                width: drawerWidth,
                overflow:"hidden",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    zIndex: zIndex,
                    width: drawerWidth,
                    overflow:"hidden",
                    boxSizing: 'border-box',
                    boxShadow: showShodow ? '-4px 1px 8px #0000000F':'none',
                },
                '& .MuiBackdrop-root': {
                    backgroundColor:"transparent"
                }
            }}
            //variant="persistent"
            anchor="right"
        >
            <Divider />
            {children}
        </Drawer>
    )
}
