import React from "react";
import { readFromURL } from "../FileService/fileread";
import { useSelector } from "react-redux";

interface AudioProps {
    item: any
}

export function useAudioPlayer({ item }: AudioProps) {
    const audioref = React.useRef<any>(null);
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);
    const [progress, setProgress] = React.useState<number>(0);
    const [loaderProgress, setLoaderProgress] = React.useState<number>(0);
    const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
    const [audioUrl, setAudioUrl] = React.useState<any>(null);
    const [audioLoader, setAudioLoader] = React.useState<boolean>(false);
    const [audioErr, setAudioErr] = React.useState<boolean>(false);

    React.useEffect(() => {
        let isMounted = true; 
        async function initiateAPI() {
          setAudioLoader(true);
          const option = {
            method: 'GET',
            mode: 'cors' as RequestMode,
            headers: new Headers({
              'Authorization': 'Bearer ' + userToken
            })
          };
    
          const result = await readFromURL(item.attachments.relativePath, option, setLoaderProgress);
          if (!result) return;
    
          if (isMounted) {
            setAudioUrl(result);
            setIsPlaying(true);
          }
        }
    
        initiateAPI();
    
        return () => {
          isMounted = false;
    
          if (audioref.current) {
            audioref.current.pause();
            audioref.current.src = ''; 
          }
        };
      }, [item.attachments.relativePath, userToken]);
    
      React.useEffect(() => {
        if (isPlaying && audioUrl && audioref.current) {
          audioref.current.play();
        }
      }, [isPlaying, audioUrl]);

    const tooglePlay = () => {
        if (!isPlaying) {
            if (audioref.current) {
                audioref.current.play();
            }
            setIsPlaying(true);
        } else {
            if (audioref.current) {
                audioref.current.pause();
            }
            setIsPlaying(false);
        }
    };





    const farward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime += 15;
    }

    const backward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime -= 15;
    }
    const fastFarward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime += 30;
    }

    const fastbackward = () => {
        const audio = getAudio();
        if (!audio) return;
        audio.currentTime -= 30;
    }
    const handleProgress = (e: any) => {
        const audio = getAudio();
        if (!audio) return;
        const manualChange = Number(e.target.value);
        audio.currentTime = (audio.duration / 100) * manualChange;
        setProgress(manualChange);
    }

    const getAudio = () => { return audioref?.current };

    const onProgress = (e: any) => {
        setProgress((e.target.currentTime / e.target.duration) * 100);
    }




    const onTimeUpdate = () => {
        const progress = (audioref?.current.currentTime / audioref?.current.duration) * 100;
        setProgress(progress)
    }
    const getTime = (seconds: any) => {
        const val = Math.round(seconds);
        return [3600, 60].reduceRight((p: any, b: any) => (r: any) => [Math.floor(r / b)].concat(p(r % b)), r => [r])(val).map((a: any) => a.toString().padStart(2, '0')).join(':');
    }

    const onloadimage = () => {
        setAudioLoader(false);
    }
    const onEnded = () => {
        setIsPlaying(false);
    }
    const onError = () => {
        setAudioErr(true);
    }
    return { audioErr, onError, onEnded, loaderProgress, farward, fastbackward, isPlaying, onloadimage, audioLoader, getTime, audioref, progress, fastFarward, backward, handleProgress, tooglePlay, onProgress, onTimeUpdate, audioUrl };
}
