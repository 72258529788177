import axios from '../../axios'

const handleApiRequest = async (apiCall: () => Promise<any>) => {
    try {
        const response = await apiCall()
        return response
    } catch (error) {
        console.error(error)
        return error
    }
}

const post = (uri: string, data: any) => axios.post(uri, data)
const put = (uri: string, data: any) => axios.put(uri, data)
const del = (uri: string, data: any) => axios.delete(uri, data)
const get = (uri: string) => axios.get(uri)
const formPost = (uri: string, data: any) => axios.post(uri, data)
const postSignal = (uri: string, data: any, Signal: any) =>
    axios.post(uri, data, { cancelToken: Signal })
const formPostAttachment = (
    uri: string,
    data: any,
    setProgress: any,
    Signal: any,
) => {
    return axios.post(uri, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
            if (progressEvent && progressEvent.total) {
                const progress =
                    (progressEvent.loaded / progressEvent.total) * 100
                setProgress(progress)
            }
        },
        cancelToken: Signal,
    })
}

export default {
    handleApiRequest,
    post,
    put,
    get,
    del,
    postSignal,
    formPost,
    formPostAttachment,
}
