import React from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist-min'
import styles from './JobAuditorGraph.module.css'

function JobAuditorGraph() {
    const Plot = createPlotlyComponent(Plotly)
    const plotdata = [
        {
            x: [
                'Auditor 1',
                'Auditor 2',
                'Auditor 3',
                'Auditor 4',
                'Auditor 5',
                'Auditor 6',
            ],
            y: [21, 12, 23, 34, 15, 26],
            // ylabel: 'score',
            type: 'bar',

            marker: {
                color: [
                    'rgb(0,132,206)',
                    'rgb(251,206,20)',
                    'rgb(206,74,0)',
                    'rgb(206,110,10)',
                    'rgb(206,174,20)',
                    'rgb(206,74,130)',
                ],
            },
        },
    ]
    return (
        <div>
            <Plot
                className={styles.JobAuditorGraphContainer}
                data={plotdata}
                layout={{
                    font: { size: 16 },
                    title: 'Number of Jobs by Auditor',
                    yaxis: { title: 'Number of Jobs' },
                }}
                config={{ responsive: true }}
            />
        </div>
    )
}

export default JobAuditorGraph
