import { Grid } from '@mui/material';
import { FC } from 'react';
import styles from './ViewField.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';

interface ViewFieldProps {
  name: string,
  value: any,
}

const ViewField: FC<ViewFieldProps> = ({ name, value }) => {
  if (!value) {
    return null;
  }
  return (<>
    <Grid item xs={4} >
      <div className={styles.labField}>
        <span className={typoStyles.typoRobotoRegoular14}>{name}</span>
      </div>
    </Grid>
    <Grid item xs={8} >
      <div className={styles.valField}>
        <span className={typoStyles.typoRobotoRegoularprimary7160}>{value}</span>
      </div>
    </Grid>
  </>)
};

export default ViewField;
