import _ from 'lodash';

export const chatListReduce = (state: any, action: any) => {
    switch (action.type) {
        case 'add':

            return _.unionBy([...action.payload, ...state], 'id');
        case 'addnew':
            return _.unionBy([...state, action.payload], 'id');

        case 'update': {
            const updatedState = state.map((stateItem: any) => {
                const payloadItem = action.payload.find((payloadItem: any) => payloadItem.id === stateItem.id);

                if (payloadItem) {
                    return payloadItem;
                }

                return stateItem;
            });
            const newItems = action.payload.filter((payloadItem: any) => {
                return !state.some((stateItem: any) => stateItem.id === payloadItem.id);
            });
            const combinedState = [...updatedState, ...newItems];
            return _.unionBy(combinedState, 'id');
        }

        case 'removeall':
            return action.payload;
        default:
            return state;
    }
}
