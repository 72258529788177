import React from "react";
import { readFileFromURL } from "../FileService/fileread";
import imagePlaceHolder from '../../Assests/Icons/Image thumbnail.png';
import { useSelector } from "react-redux";

interface ImagePreviewProps {
    item: any
}
export function useImagePreview({ item }: ImagePreviewProps) {
    const userToken = useSelector((state: any) => state.persistReducers?.userReducer?.userToken);

    const [ imageUrl, setImageUrl ] = React.useState<any>(null);
    const [ imageLoader, setImageLoader ] = React.useState<boolean>(false);

    React.useEffect(() => {
        async function initiateAPI() {
            setImageLoader(true);
            const option = {
                method: 'GET',
                mode:'cors' as RequestMode,
                headers: new Headers({
                    'Authorization': 'Bearer ' + userToken
                })
            }
            const result = await readFileFromURL(item.attachments.relativePath, option);
            if(!result) return;
            setImageUrl(result);
         }
        initiateAPI();
    },[]);

    const onloadimage = () => {
        URL.revokeObjectURL(imageUrl);
        setImageLoader(false);
    }
    const onErrolLoad = (e: any) => {
        e.target.src = imagePlaceHolder;
    }
    return { onErrolLoad,  imageUrl, imageLoader, onloadimage };
}