import React, { FC } from 'react';
import styles from './ConfirmAlertFillablePdf.module.css';
import buttonStyles from '../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../Styles/component/Typography.module.css'
import { ReactComponent as Alert } from '../../Assests/Icons/Alert.svg'
import { Typography, Button } from '@mui/material';
import customDialoagStyles from '../../Styles/component/CustomDialog.module.css';

interface ConfirmAlertFillablePdfProps {
  msg:string,
  yes: any,
  no: any
}

const ConfirmAlertFillablePdf: FC<ConfirmAlertFillablePdfProps> = ({ msg, yes, no }) => (
  <div className={customDialoagStyles.root}>
  <div className={styles.rootDiv}>
      <div className={styles.alertDiv}>
          <div>
              <Alert />
          </div>
          <div className={styles.claimColumnDiv}>
              <div className={styles.claimColumnGap}>
                  <Typography className={typoStyles.typoRobotoMediumprimary6014}>
                      {msg}
                  </Typography>
              </div>
              <div className={styles.claimAlignItem}>
                  <Button
                      className={buttonStyles.cancelbutton}
                      onClick={() => no()}
                  >
                      <Typography className={typoStyles.typoRobotoRegoularprimary5914}>
                          {'Cancel'}
                      </Typography>
                  </Button>
                  <Button
                      className={buttonStyles.yesbutton}
                     onClick={() => yes()}
                  >
                      <Typography className={typoStyles.typoRobotoMediumprimary214}>
                          {'Yes'}
                      </Typography>
                  </Button>
              </div>
          </div>
      </div>
  </div>
</div>
);

export default ConfirmAlertFillablePdf;
