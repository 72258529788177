import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { stringToborColor, stringToColor } from '../../../Services/utils/constant'

export function stringAvatar(name: string, bgshow?:any) {
    return {
        sx: {
            bgcolor: bgshow ? 'white': stringToColor(name),
            width: '28px',
            height: '28px',
            border: `1px solid ${stringToborColor(name)} !important`,
        },
        children:
            name.indexOf(' ') >= 1
                ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
                : name[0][0],
    }
}
type Props = {
    name: string
    children: React.ReactNode
}
export default function UserAvatar({ name, children }: Props) {
    if(!name){
        return null;
    }
    return (
        <Stack direction="row" spacing={2}>
            <Avatar {...stringAvatar(name?.split(' ')[0])}>
                {children}
            </Avatar>
        </Stack>
    )
}
