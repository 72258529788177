import React from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import AdbIcon from '@mui/icons-material/Adb'
import typoStyles from '../../Styles/component/Typography.module.css'
import Notification from '../../Components/Notification/Notification'
import NotificationDrawer from '../../Components/NotificationDrawer'
import Logo from '../../Assests/Icons/MicrosoftTeams-image (4).png'
import Badge from '@mui/material/Badge'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { roleName } from '../../Services/utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { DatePickerToolbar } from '@mui/x-date-pickers'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { unstable_useForkRef as useForkRef } from '@mui/utils'
import dayjs, { Dayjs } from 'dayjs'
import { Button } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { productionDateSetting } from '../../Services/actions/productionDateAction'
import { useLocation } from 'react-router-dom'

interface HeaderProps {
    notificationCount?: any
}
const Header = ({ notificationCount }: HeaderProps) => {
    const dispatch = useDispatch()

    const userRole = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.userRole,
    )
    const userName = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )
    const productionDate = useSelector(
        (state: any) => state.nonPersistReducers?.productionDateReducer.day,
    )
    const location = useLocation()
    // console.log('prodDate', productionDate.toISOString())
    // const dispatch = useDispatch()
    const addDays = (date: Dayjs | any, days: number) => {
        const result = date.add(days, 'd')
        // console.log('result', result)
        setProductionDate(result)
        const newDate = new Date(result)
        // dispatch(productionDateSetting(newDate.toISOString()))
        dispatch(productionDateSetting(newDate))
        // result.add(days, 'day')
        // console.log('date', result)
        // result.setDate(result.getDate() + days)
        // dispatch(productionDateSetting(result))
        // return result
    }

    const [, setAnchorElUser] = React.useState<null | HTMLElement>(null)
    const [openDrawer, setopenDrawer] = React.useState(false)
    const [dayJSProductionDate, setProductionDate] = React.useState<
        Dayjs | any
    >(dayjs(productionDate))
    const [hideButtons, setHideButtons] = React.useState(
        location.pathname == '/coding' ? true : false,
    )

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setopenDrawer(true)
        setAnchorElUser(event.currentTarget)
    }

    const closedrawer = () => {
        setopenDrawer(false)
        setAnchorElUser(null)
    }
    // console.log('date', productionDate)
    // console.log('path', location.pathname, hideButtons)
    React.useEffect(() => {
        if (location.pathname == '/coding') {
            setHideButtons(true)
        } else {
            setHideButtons(false)
        }
    }, [location.pathname])
    interface BrowserFieldProps
        extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
        label?: React.ReactNode
        inputRef?: React.Ref<any>
        InputProps?: {
            ref?: React.Ref<any>
            endAdornment?: React.ReactNode
            startAdornment?: React.ReactNode
        }
        error?: boolean
        focused?: boolean
        ownerState?: any
        sx?: any
        enableAccessibleFieldDOMStructure: boolean
    }

    type BrowserFieldComponent = ((
        props: BrowserFieldProps & React.RefAttributes<HTMLDivElement>,
    ) => React.JSX.Element) & { propTypes?: any }

    const BrowserField = React.forwardRef(
        (props: BrowserFieldProps, ref: React.Ref<HTMLDivElement>) => {
            const {
                // Should be ignored
                // enableAccessibleFieldDOMStructure,

                disabled,
                id,
                label,
                inputRef,
                InputProps: {
                    ref: containerRef,
                    startAdornment,
                    endAdornment,
                } = {},
                // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
                error,
                focused,
                ownerState,
                sx,
                ...other
            } = props

            const handleRef = useForkRef(containerRef, ref)
            console.log('label', label)
            return (
                <Box
                    sx={{
                        ...(sx || {}),
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    id={id}
                    ref={handleRef}
                >
                    {startAdornment}
                    {/* <input disabled={disabled} ref={inputRef} {...other} /> */}
                    {endAdornment}
                </Box>
            )
        },
    )

    //  as BrowserFieldComponent

    const BrowserDatePicker = React.forwardRef(
        (props: DatePickerProps<Dayjs>, ref: React.Ref<HTMLDivElement>) => {
            return (
                <DatePicker
                    ref={ref}
                    {...props}
                    slots={{ ...props.slots, field: BrowserField }}
                />
            )
        },
    )
    BrowserDatePicker.displayName = 'BrowserDatePicker'
    BrowserField.displayName = 'BrowserField'

    console.log('user', userRole)
    return (
        <Container
            style={{ minHeight: '40px' }}
            maxWidth={false}
            sx={{
                backgroundColor:
                    userRole == 'auditor'
                        ? '#a6d2ff'
                        : userRole == 'auditoradmin'
                        ? '#fc8e88'
                        : '#fcc995',
                paddingLeft: 0,
                marginLeft: 0,
                padding: '2px',
                height: '40px',
                alignItems: 'center',
                // minHeight: '30px',
            }}
        >
            <div
                style={{
                    minHeight: '40px',
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        margin: 0,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {/* <img src={Logo} alt="Logo" width={'42px'} height={'29px'} /> */}
                    <div style={{ width: '42px' }}></div>

                    {/* <AdbIcon
                        sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
                    /> */}
                </div>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: 0,
                            color: 'black',
                        }}
                    >
                        <Typography
                            variant="h6"
                            className={typoStyles.typoRobotoBoldprimary1214}
                            noWrap
                        >
                            Production Date:
                        </Typography>
                        <Button
                            hidden={hideButtons}
                            onClick={() => {
                                addDays(dayJSProductionDate, -1)
                                // dispatch(notificationCountSetting(1))
                            }}
                        >
                            <ArrowBackIosNewIcon />
                        </Button>
                        <Typography
                            variant="h6"
                            noWrap
                            className={typoStyles.typoRobotoBoldprimary1214}
                        >
                            {dayJSProductionDate?.format('MM/DD/YYYY')}
                            {/* {dayjs(productionDate)} */}
                            {/* {productionDate?.format('MM/DD/YYYY')} */}
                        </Typography>
                        <Button
                            hidden={hideButtons}
                            onClick={() => {
                                addDays(dayJSProductionDate, 1)
                                // dispatch(notificationCountSetting(1))
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </Button>
                        <div className="Picker" hidden={hideButtons}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <BrowserDatePicker
                                    // label={value == null ? null : value.format('MM/DD/YYYY')}
                                    // value={productionDate}
                                    value={dayJSProductionDate}
                                    onChange={(newValue: Dayjs | any) => {
                                        // setProductionDate(newValue)
                                        addDays(newValue, 0)
                                    }}
                                    slotProps={{
                                        field: { clearable: true },
                                    }}
                                />
                                {/* {MuiDatePicker} */}
                            </LocalizationProvider>
                        </div>
                    </Box>
                </Box>

                <Box>
                    <Box sx={{ flexGrow: 0, color: 'black' }}>
                        <Typography
                            variant="h6"
                            noWrap
                            className={typoStyles.typoRobotoBoldprimary1214}
                        >
                            {userName?.firstName} {userName?.lastName}
                        </Typography>
                    </Box>
                </Box>
                {/* <Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open Notification">
                            <Badge
                                badgeContent={notificationCount}
                                color="error"
                            >
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}
                                >
                                    <NotificationsNoneIcon />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Box>
                </Box> */}
            </div>
            {openDrawer && (
                <NotificationDrawer openDrawer={true}>
                    <Notification closedrawer={closedrawer} />
                </NotificationDrawer>
            )}
        </Container>
    )
}
export default Header
