import React from 'react'
import TATMeterPlot from '../../../Components/TATMeter/TATMeterPlot'
import JobStatusGraph from '../../../Components/JobStatusGraph/JobStatusGraph'
import JobAuditorGraph from '../../../Components/JobsAuditorGraph/JobAuditorGraph'
import AttentionJobsTable from '../../../Components/AttentionJobsTable/AttentionJobsTable'
import styles from './AuditorManagerDashboard.module.css'
import { useNavigate } from 'react-router-dom'

const AuditorManagerDashboard = () => {
    const navigate = useNavigate()
    return (
        <div style={{ margin: '5px 5px 20px 5px', width: '100dvw' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                <div className={styles.DashboardBoxContainer}>
                    <TATMeterPlot />
                </div>
                <div className={styles.DashboardBoxContainer}>
                    <JobStatusGraph />
                </div>
                <div
                    className={styles.DashboardBoxContainer}
                    onClick={() => {
                        navigate('./TATMeterList')
                    }}
                >
                    <JobAuditorGraph />
                </div>
                <div className={styles.DashboardBoxContainer}>
                    <AttentionJobsTable />
                </div>
            </div>
        </div>
    )
}

export default AuditorManagerDashboard
