import { TextField, TextFieldProps } from "@mui/material"
import React from "react";

const TextNumericField = (props: React.JSX.IntrinsicAttributes & TextFieldProps) => {
    return <TextField type={'text'} inputProps={{ maxLength: 50}} autoComplete="off" {...props} variant='outlined'  sx={{
        height: '40px',
        width: '195px',
        input:{
              "&::-webkit-contacts-auto-fill-button": {
                visibility: "hidden",
                display: "none !important",
                pointerEvents: "none",
                position: "absolute",
                right: 0
              },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
        '& input::placeholder': {
            fontSize: '14px',
            fontStyle: 'italic',
        },
        '& .MuiInputBase-root': {
            height: '40px !important',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            minHeight: '40px',
        },
        
    }} />
}
export default TextNumericField;