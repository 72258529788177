import {
    Button,
    MenuItem,
    Typography,
    Box,
    Tabs,
    Tab,
    Divider,
} from '@mui/material'
import buttonStyles from '../../../Styles/component/ButtonStyle.module.css'
import typoStyles from '../../../Styles/component/Typography.module.css'
import CustomeSelect from '../../../Components/CustomeSelect'
import CustomDatePicker from '../../../Components/CustomDatePicker'
import { statusTagMenuItem } from '../../../Services/utils/constant'
import styles from '../TaskManager.module.css'
import TextFormField from '../../../Components/TextField'
import { useEditTask } from '../../../Services/taskManagerService/editTaskService'
import { ViewTaskProps } from '../../../Services/taskManagerService/viewTaskService'
import UserComponent from '../../UserComponent/UserComponent'
import ViewTask from './ViewTask'
import ViewAudios from './ViewAudios/ViewAudios'
import ViewTaskStatusUpdate from './ViewTaskStatusUpdate/ViewTaskStatusUpdate'
import TaskdrawerHeader from '../../../Components/TaskdrawerHeader/TaskdrawerHeader'
import { TabPanel, a11yProps } from '../../../Components/DrawerTab/DrawerTab'
import ReadOnlyField from '../../../Components/ReadOnlyField/ReadOnlyField'
import TextNumericField from '../../../Components/TextNumericField'
import DocumentComponent from '../../../Components/DocumentComponent/DocumentComponent'

export default function EditTask(props: ViewTaskProps) {
    const {
        handleorgChange,
        toggleEdit,
        handleTabChange,
        tableValue,
        showView,
        error,
        checkAllField,
        auditorList,
        handleChange,
        handleSubmit,
        handleDropChange,
        handleEndChange,
        handleStartChange,
        states,
    } = useEditTask(props)
    if (!states) {
        return null
    }

    return (
        <div>
            {!showView ? (
                <>
                    <TaskdrawerHeader
                        onclick={() => {
                            if (props.menuHandleCloseClick) {
                                props.menuHandleCloseClick()
                            }
                        }}
                    />
                    <Divider />
                    <div className={styles.HeaderDiv}>
                        <ViewTaskStatusUpdate
                            statusMenuItem={statusTagMenuItem}
                            data={states}
                            updatedTaskList={props?.updatedTaskList}
                            aIndex={props.aIndex}
                        />
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                variant="fullWidth"
                                value={tableValue}
                                centered
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    sx={{ textTransform: 'initial' }}
                                    className={typoStyles.typoRobotoMedium1633}
                                    label="Edit Details"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    sx={{ textTransform: 'initial' }}
                                    className={typoStyles.typoRobotoMedium1633}
                                    label="Audios"
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    sx={{ textTransform: 'initial' }}
                                    className={typoStyles.typoRobotoMedium1633}
                                    label="Transcripts"
                                    {...a11yProps(2)}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={tableValue} index={0}>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    height: 'calc(100vh - 201px)',
                                    position: 'relative',
                                }}
                            >
                                <div className={styles.AddTaskRootDiv}>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Task Name'}
                                        </Typography>
                                        <TextFormField
                                            name="taskName"
                                            value={states.taskName}
                                            onChange={handleChange}
                                            error={error.taskNameError}
                                            placeholder="Type here..."
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Auditor'}
                                        </Typography>
                                        <CustomeSelect
                                            value={states?.auditorId}
                                            name={'auditorId'}
                                            handleChange={handleDropChange}
                                        >
                                            {auditorList?.map(
                                                ({
                                                    id,
                                                    firstName,
                                                    lastName,
                                                }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        <UserComponent
                                                            detail={true}
                                                            auditorName={
                                                                firstName +
                                                                ' ' +
                                                                lastName
                                                            }
                                                            auditorId={id}
                                                        />
                                                    </MenuItem>
                                                ),
                                            )}
                                        </CustomeSelect>
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Organization TIN'}
                                        </Typography>
                                        <TextNumericField
                                            name="orgTid"
                                            value={states.orgTid}
                                            onChange={handleorgChange}
                                            error={error.orgTidError}
                                            placeholder="Type here..."
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Provider Name'}
                                        </Typography>
                                        <CustomeSelect
                                            value={states?.providerId}
                                            name={'providerId'}
                                        >
                                            <MenuItem
                                                key={states?.providerId}
                                                value={states?.providerId}
                                            >
                                                <UserComponent
                                                    detail={true}
                                                    auditorName={
                                                        states?.providerName
                                                    }
                                                    auditorId={
                                                        states?.providerId
                                                    }
                                                />
                                            </MenuItem>
                                        </CustomeSelect>
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Speciality'}
                                        </Typography>
                                        <TextFormField
                                            name="speciality"
                                            placeholder="Type here..."
                                            error={error.specialityError}
                                            value={states.speciality}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Patient Name'}
                                        </Typography>
                                        <CustomeSelect
                                            value={states?.appointmentId}
                                            name={'appointmentId'}
                                        >
                                            <MenuItem
                                                key={states?.appointmentId}
                                                value={states?.appointmentId}
                                            >
                                                <UserComponent
                                                    detail={true}
                                                    auditorName={
                                                        states?.patientName
                                                    }
                                                    auditorId={
                                                        states?.appointmentId
                                                    }
                                                />
                                            </MenuItem>
                                        </CustomeSelect>
                                    </div>
                                    <ReadOnlyField
                                        name="Location"
                                        value={states?.location}
                                    />
                                    <ReadOnlyField
                                        name="MRN"
                                        value={states?.mrn}
                                    />
                                    <ReadOnlyField
                                        name="Patient Account"
                                        value={states?.patientAccount}
                                    />
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Patient DOB'}
                                        </Typography>
                                        <CustomDatePicker
                                            value={states?.patientdob}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Patient Gender'}
                                        </Typography>
                                        <CustomeSelect
                                            name={'patientGender'}
                                            value={states?.patientGender}
                                        >
                                            <MenuItem key={'M'} value={'M'}>
                                                <Typography
                                                    className={
                                                        typoStyles.typoRobotoRegoularprimary5714
                                                    }
                                                >
                                                    {'Male'}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem key={'F'} value={'F'}>
                                                <Typography
                                                    className={
                                                        typoStyles.typoRobotoRegoularprimary5714
                                                    }
                                                >
                                                    {'Female'}
                                                </Typography>
                                            </MenuItem>
                                        </CustomeSelect>
                                    </div>
                                    <ReadOnlyField
                                        name="Encounter Collection"
                                        value={states?.encounter?.length}
                                    />
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'Start Date'}
                                        </Typography>
                                        <CustomDatePicker
                                            value={states?.startDate}
                                            onChange={handleStartChange}
                                        />
                                    </div>
                                    <div className={styles.AddTaskFiledDiv}>
                                        <Typography
                                            className={
                                                typoStyles.typoRobotoBoldprimary314
                                            }
                                        >
                                            {'End Date'}
                                        </Typography>
                                        <CustomDatePicker
                                            startedDate={states?.startDate}
                                            value={states?.endDate}
                                            onChange={handleEndChange}
                                        />
                                    </div>
                                    <ReadOnlyField
                                        name="Appointment Type"
                                        value={states?.type}
                                    />
                                    <div className={styles.footerDialogDiv}>
                                        <Button
                                            className={
                                                buttonStyles.cancelbutton
                                            }
                                            onClick={props.handleClosed}
                                        >
                                            <Typography
                                                className={
                                                    typoStyles.typoRobotoRegoularprimary5914
                                                }
                                            >
                                                {'Cancel'}
                                            </Typography>
                                        </Button>
                                        <Button
                                            disabled={checkAllField()}
                                            className={buttonStyles.yesbutton}
                                            onClick={() => {
                                                handleSubmit()
                                            }}
                                        >
                                            <Typography
                                                className={
                                                    typoStyles.typoRobotoMediumprimary214
                                                }
                                            >
                                                {'Save'}
                                            </Typography>
                                        </Button>
                                    </div>
                                    <div style={{ height: '50px' }}></div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={tableValue} index={1}>
                            <ViewAudios
                                appointmentId={states?.appointmentId}
                                scheduleId={states?.scheduleId}
                            />
                        </TabPanel>
                        <TabPanel value={tableValue} index={2}>
                            <DocumentComponent
                                appointmentId={states?.appointmentId}
                                scheduleId={states?.scheduleId}
                            />
                        </TabPanel>
                    </Box>
                </>
            ) : (
                <ViewTask
                    openEdit={toggleEdit}
                    task={states}
                    showEditbtn={true}
                    menuHandleCloseClick={props.menuHandleCloseClick}
                    updatedTaskList={props.updatedTaskList}
                />
            )}
        </div>
    )
}
