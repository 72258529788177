import { FC } from 'react';
import styles from './PatientHolder.module.css';
import typoStyles from '../../Styles/component/Typography.module.css';

interface PatientHolderProps {
  patientName: string,
  mrn: string
}

const PatientHolder: FC<PatientHolderProps> = ({ patientName, mrn }) => (
  <div className={styles.PatientHolder}>
    <div className={styles.patientNameDiv}>
      <span className={typoStyles.typoRobotoRegoularprimary5714190} >{"Patient Name:"}<span style={{wordBreak:"break-word", whiteSpace:"normal"}} className={[`${typoStyles.typoRobotoBoldprimary6614} ${styles.namePad}`].join(' ')}>{patientName}</span></span>
    </div>
    <div className={styles.patientNameDiv}><span className={typoStyles.typoRobotoRegoularprimary5714190}>{"MRN:"}<span className={[`${typoStyles.typoRobotoBoldprimary6614} ${styles.mrnPad}`].join(' ')}>{"#" + mrn}</span></span></div>
  </div>
);

export default PatientHolder;
